import { Tooltip } from '@fluentui/react'
import React, { FC } from 'react'
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { chartPrimaryColorMap } from './ChartColors'

export interface IBarChartDataEntry {
  name: string
  value: number
  backgroundColor?: string
  description?: string
}

interface ICustomBarChartProps {
  data: IBarChartDataEntry[]
}

export const CustomBarChart: FC<ICustomBarChartProps> = ({ data }) => (
  <ResponsiveContainer className="custom-bar-chart-container" width="100%" height={Math.max(300, data.length * 32)}>
    <BarChart layout="vertical" barSize={24} data={data} margin={{ top: 0, bottom: 0, left: -32, right: 16 }}>
      <XAxis hide type="number" />
      <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} domain={[0, 10]} allowDataOverflow={false} />
      <Tooltip />
      <Bar dataKey="value" radius={5}>
        {data.map((entry, index) => (
          <Cell
            key={entry.name}
            fill={entry.backgroundColor || chartPrimaryColorMap[Number(index) % chartPrimaryColorMap.length].backgroundColor}
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
)
