import React, { useState } from 'react'

import CollapseButton from './CollapseButton'
import { RichTextReader } from './RichTextReader'

type ISurveyImportantTrayProps = {
  infoTitle: string | null
  info: string | null
}

const SurveyImportantTray: React.FunctionComponent<ISurveyImportantTrayProps> = props => {
  const { infoTitle, info } = props
  const [showTray, setShowTray] = useState(true)

  const showHideMoreInfoTray = () => {
    setShowTray(!showTray)
  }

  return (
    <>
      <div
        onClick={showHideMoreInfoTray}
        className={showTray ? 'question-respondentview-moreinfo-container tray-open' : 'question-respondentview-moreinfo-container'}>
        <div className="question-respondentview-moreinfo-inner">
          <div className="question-respondentview-moreinfo-inner-title">{infoTitle || 'What do I need to know about this?'}</div>
          <div className="question-respondentview-collapse-button">
            <CollapseButton isCollapsed={showTray} />
          </div>
        </div>
      </div>

      {showTray && (
        <div className="question-respondentview-info-content">
          <div className="question-respondentview-info-content-inner">
            <RichTextReader value={info || ''} onChange={() => {}} />
          </div>
        </div>
      )}
    </>
  )
}

export default SurveyImportantTray
