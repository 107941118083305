import React, { useEffect, useState } from 'react'

import { createUserWithEmail } from '../contexts/authContext'
import { ButtonForm, EmailSignupForm } from '.'

export type ISignupFormProps = {
  redirectTo?: string
}

const strings = {
  facebookButtonText: 'Sign up  with Facebook',
  microsoftButtonText: 'Sign up with Microsoft',
  googleButtonText: 'Sign up with Google',
  createAccountButtonText: 'create account',
  loginQuestionText: 'Already have an account? ',
  loginLinkText: 'Log in',
  createAccountText: 'Create Account'
}

const buttonText = {
  facebookButtonText: 'Sign up  with Facebook',
  microsoftButtonText: 'Sign up with Microsoft',
  googleButtonText: 'Sign up with Google',
  emailButtonText: 'Sign up with Email'
}

const SignupForm: React.FC<ISignupFormProps> = props => {
  const [signupEmailForm, setSignupEmailForm] = useState(false)
  const [showEmailSignup, setShowEmailSignup] = useState(false)
  const [signupError, setSignupError] = useState('')
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')

  useEffect(() => {
    setShowEmailSignup(!showEmailSignup)
  }, [signupEmailForm])

  const emailFormClick = () => {
    if (signupEmailForm) setSignupEmailForm(false)
    else setSignupEmailForm(true)
  }

  const onClickSignup = async (email: string, name: string, password: string) => {
    await createUserWithEmail(email, password, name)
    return true
  }

  const onSignupFormChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    if (signupError) setSignupError('')
    const target = e.target as HTMLInputElement
    switch (target.id) {
      case 'name':
        setNameValue(value!)
        break
      case 'email':
        setEmailValue(value!)
        break
      case 'password':
        setPasswordValue(value!)
        break
      case 'confirm-password':
        setConfirmPasswordValue(value!)
        break
      default:
        break
    }
  }

  return (
    <>
      {showEmailSignup ? (
        <ButtonForm
          pageTitle={strings.createAccountText}
          emailFormClick={emailFormClick}
          facebookButtonText={buttonText.facebookButtonText}
          microsoftButtonText={buttonText.microsoftButtonText}
          googleButtonText={buttonText.googleButtonText}
          emailButtonText={buttonText.emailButtonText}
          signup={true}
        />
      ) : (
        <EmailSignupForm
          error={signupError}
          nameValue={nameValue}
          emailValue={emailValue}
          passwordValue={passwordValue}
          confirmPasswordValue={confirmPasswordValue}
          emailFormClick={emailFormClick}
          onSignupFormChange={onSignupFormChange}
          onClickSignup={onClickSignup}
        />
      )}
    </>
  )
}

export default SignupForm
