import './css/registration.css'

import { Link, Modal, PrimaryButton } from '@fluentui/react'
import { Button } from 'components/Button'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParam } from 'utils/reactUtils'

import { facebookSignIn, googleSignIn, microsoftSignIn } from '../contexts/authContext'

export interface IButtonFormProps {
  emailFormClick: () => void
  pageTitle: string
  facebookButtonText: string
  microsoftButtonText: string
  googleButtonText: string
  emailButtonText: string
  signup: boolean
}

export const ButtonForm: React.FunctionComponent<IButtonFormProps> = (props: IButtonFormProps) => {
  const { emailFormClick, facebookButtonText, microsoftButtonText, googleButtonText, emailButtonText, signup } = props
  const history = useHistory()
  const redirect = useParam('redirect')
  const [loginError, setLoginError] = useState('')

  const title = history.location.pathname === '/login' ? 'Login' : 'Create Account'

  return (
    <div className="registration-outer-container">
      <h3 className="registration-form-title">{title}</h3>
      <div className="registration-form-container">
        <div className="registration-form-buttons">
          <PrimaryButton
            text={facebookButtonText}
            iconProps={{ iconName: 'Facebook' }}
            styles={{
              root: {
                background: '#1877f2',
                color: 'var(--white)',
                boxShadow: 'var(--buttonShadowRegistration)'
              },
              rootHovered: {
                background: '#898F9C',
                boxShadow: 'var(--buttonShadowRegistration)',
                outline: 'none'
              },
              rootPressed: {
                background: '#135EBF',
                color: 'var(--white)',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: 'none',
                outline: 'none'
              },
              icon: { height: 20 }
            }}
            onClick={() => {
              facebookSignIn().catch(err => setLoginError(err))
            }}
          />
          <PrimaryButton
            text={microsoftButtonText}
            iconProps={{ iconName: 'Microsoft' }}
            styles={{
              root: {
                background: '#2f2f2f',
                color: 'var(--white)',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid transparent'
              },
              rootHovered: {
                background: '#FFFFFF',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid #8C8C8C',
                color: '#5E5E5E',
                outline: 'none'
              },
              rootPressed: {
                background: '#121212',
                color: 'var(--white)',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid transparent',
                outline: 'none'
              },
              icon: { height: 20 }
            }}
            onClick={() => {
              microsoftSignIn().catch(err => setLoginError(err))
            }}
          />
          <PrimaryButton
            text={googleButtonText}
            iconProps={{ iconName: 'Google' }}
            styles={{
              root: {
                background: '#fefefe',
                color: '#00000080',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid #0000001A'
              },
              rootHovered: {
                background: '#eeeeee',
                color: '#171717',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid #0000001A',
                outline: 'none'
              },
              rootPressed: {
                background: '#bfbfbf',
                color: 'var(--white)',
                boxShadow: 'var(--buttonShadowRegistration)',
                border: '1px solid transparent',
                outline: 'none'
              },
              icon: { height: 20 }
            }}
            onClick={googleSignIn}
          />
          <PrimaryButton
            text={emailButtonText}
            styles={{
              root: { background: 'var(--themeSecondary)', fontSize: '18px', padding: '25px 12px' },
              rootHovered: { background: 'var(--themePrimary)' }
            }}
            onClick={emailFormClick}
          />
          {signup ? (
            <div className="form-right">
              Already have an account? <Link href={redirect ? `/#/login?redirect=${redirect}` : '/#/login'}>Login</Link>
            </div>
          ) : (
            <div className="form-right">
              Not Registered? <Link href={redirect ? `/#/signup?redirect=${redirect}` : '/#/signup'}>Click here</Link> to create an account
            </div>
          )}
        </div>
      </div>
      <Modal
        styles={{
          root: {
            padding: 20,
            fontSize: '1.3em'
          },
          scrollableContent: {
            width: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20
          }
        }}
        isOpen={!!loginError}
        onDismiss={() => setLoginError('')}>
        <div style={{ marginBottom: 10 }}>Error logging in: {loginError}</div>
        <Button ghost onClick={() => setLoginError('')}>
          Cancel
        </Button>
      </Modal>
    </div>
  )
}
