import { firebaseConfig } from 'contexts/firebaseContext'
import firebase from 'firebase'

export enum AnalyticEvents {
  SubmitSurvey = 'submit_survey'
}

export function logEvent(evt: AnalyticEvents, data?: any, options?: firebase.analytics.AnalyticsCallOptions) {
  if (!firebaseConfig.measurementId) return
  firebase.analytics().logEvent(evt, data, options)
}
