import './ContactsPicker.css'

import {
  Autofill,
  IBasePicker,
  IBasePickerSuggestionsProps,
  IInputProps,
  IPersona,
  IPersonaProps,
  IPickerItemProps,
  KeyCodes,
  NormalPeoplePicker,
  PeoplePickerItem,
  ValidationState
} from '@fluentui/react'
import React, { ClipboardEvent, FC, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

import { IContacts, IPersonaPropsWithValidation } from '../types'
import { handleValidateInput, NAME_WITH_EMAIL_REGEX } from '../utils'

interface IContactsPickerProps {
  label: string
  suggestionData: IContacts
  contacts: IPersonaPropsWithValidation[]
  setContacts: (contacts: IPersonaPropsWithValidation[]) => void
  hasError?: boolean
}

export const ContactsPicker: FC<IContactsPickerProps> = ({ hasError, label, suggestionData, contacts, setContacts }) => {
  const [suggestions, setSuggestions] = useState<IPersonaProps[]>([])
  const picker = useRef<IBasePicker<IPersonaProps>>(null)

  useEffect(() => {
    const suggestions = Object.values(suggestionData || {}).map(suggestion => ({
      text: suggestion.email,
      secondaryText: suggestion.name,
      validationState: ValidationState.valid
    }))

    setSuggestions(suggestions)
  }, [suggestionData])

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts'
  }

  const inputProps: IInputProps = {
    id: 'contacts-picker',
    placeholder: 'Type in email addresses here or upload a document',
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      // so it doesn't submit the form or switch to a different input...
      if (event.keyCode === KeyCodes.tab || event.keyCode === KeyCodes.enter) {
        event.preventDefault()
      } else if ((event.keyCode === KeyCodes.comma && !event.shiftKey) || event.keyCode === KeyCodes.semicolon) {
        event.preventDefault()

        picker.current && picker.current.completeSuggestion(true)
      }
    },
    onPaste: (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault()

      const pasteData = event.clipboardData.getData('Text').replace(/\s/g, '')
      const splitPasteData = pasteData.split(/[;,]+/g)
      const splitPasteDataWithoutDuplicates = splitPasteData.filter((s, index) => splitPasteData.indexOf(s) === index)
      const contactsTextOnly = contacts.map(contact => contact.primaryText || contact.text)
      const newContacts: IPersonaPropsWithValidation[] = splitPasteDataWithoutDuplicates
        .map(item => createGenericItem(item, handleValidateInput(item, contacts)))
        .filter(item => !contactsTextOnly.includes(item.primaryText || item.text))

      setContacts([...contacts, ...newContacts])
    }
  }

  const handleFilterChanged = (filterText: string, currentContacts?: IPersonaProps[]) => {
    if (!filterText) return []

    return suggestions
      .filter(item => item.text && item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      .filter(item => !currentContacts?.some(val => val.text === item.text))
  }

  const handleChange = (items: IPersona[] | undefined) => {
    const itemsWithValidation: IPersonaPropsWithValidation[] | undefined = (items as IPersonaProps[] | undefined)?.map(item => ({
      ...item,
      validationState: handleValidateInput(
        item.secondaryText ? `${item.secondaryText} <${item.primaryText || item.text}>` : item.primaryText || item.text || 'Unknown user'
      )
    }))

    setContacts(itemsWithValidation || [])
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement | Autofill>) => {
    if (event.target.value && event.target.value !== '') {
      picker.current && picker.current.completeSuggestion(true)

      const input = (event.target.value && event.target.value !== '' && event.target.value.replace(/\s/g, '')) || ''
      const splitInput = input.split(/[;,]+/g)
      const newContacts: IPersonaPropsWithValidation[] = splitInput
        .map(item => createGenericItem(item, handleValidateInput(item, contacts)))
        .filter(item => item.validationState !== ValidationState.invalid)

      setContacts([...contacts, ...newContacts])
    }
  }

  const createGenericItem = (input: string, validationState: ValidationState): IPersonaPropsWithValidation => {
    if (validationState === ValidationState.valid) {
      if (NAME_WITH_EMAIL_REGEX.test(input)) {
        const [, name, email] = input.match(NAME_WITH_EMAIL_REGEX)!

        return { validationState, text: email.toLowerCase(), secondaryText: name }
      }

      return { validationState, text: input.toLowerCase() }
    }

    return {
      validationState,
      text: input
    }
  }

  const getTextFromItem = (item: IPersonaProps, _?: string) => {
    if (item.secondaryText) return `${item.secondaryText || 'Unknown user'} <${item.text || item.primaryText}>`

    return item.text || 'Unknown user'
  }

  const handleRenderItem = (props: IPickerItemProps<IPersonaProps>) => (
    <PeoplePickerItem
      key={props.index}
      index={props.index}
      item={{ ...{ ValidationState: (props.item as IPersonaPropsWithValidation).validationState }, ...props.item }}
      onRemoveItem={() => setContacts([...contacts.filter((_, index) => index !== props.index)])}
    />
  )

  return (
    <div className={['contacts-picker', hasError ? 'contacts-picker-error' : null].join(' ')}>
      <label className="ms-Label" htmlFor="contacts-picker">
        {label}
      </label>
      <NormalPeoplePicker
        onValidateInput={(input: string) => handleValidateInput(input, contacts)}
        pickerSuggestionsProps={suggestionProps}
        inputProps={inputProps}
        onResolveSuggestions={handleFilterChanged}
        selectedItems={contacts}
        onChange={handleChange}
        onBlur={handleBlur}
        componentRef={picker}
        createGenericItem={createGenericItem}
        getTextFromItem={getTextFromItem}
        onRenderItem={handleRenderItem}
      />
    </div>
  )
}
