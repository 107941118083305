import './css/DottedSlider.css'

import { Slider } from '@fluentui/react'
import React from 'react'

export interface IDottedSliderProps {
  min: number
  max: number
  numSections: number
  value?: number
  isDisabled?: boolean
  onChange: (val: number) => void
}

export function DottedSlider(props: IDottedSliderProps) {
  const { min, max, numSections, value, onChange, isDisabled } = props
  const sliderRef = React.createRef<any>()
  const hideHover = React.useRef<boolean>(!!value)
  const numDots = numSections + 1
  const onMouseEnter = (e: React.MouseEvent) => {
    if (hideHover.current) return
    sliderRef.current._thumb.current.style.visibility = 'visible'
    sliderRef.current?._onMouseDownOrTouchStart(e)
  }
  const onMouseMove = (e: React.MouseEvent) => {
    if (hideHover.current) return
    sliderRef.current?._onMouseMoveOrTouchMove(e)
  }
  const onMouseLeave = (e: React.MouseEvent) => {
    if (hideHover.current) return
    sliderRef.current._thumb.current.style.visibility = 'hidden'
    sliderRef.current._onMouseUpOrTouchEnd(e)
  }
  const onTouchEnd = (e: React.TouchEvent) => {
    hideHover.current = true
    onChange(sliderRef.current?.state.value)
  }
  const onMouseUp = (e: React.MouseEvent) => {
    hideHover.current = true
    onChange(sliderRef.current?.state.value)
  }
  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchEndCapture={onTouchEnd}>
      <Slider
        componentRef={sliderRef}
        onChanged={evt => {
          if (evt.type === 'mouseup') {
            hideHover.current = true
            onChange(sliderRef.current?.state.value)
          }
        }}
        className="slider"
        disabled={isDisabled}
        min={min}
        max={max}
        step={(max - min) / numSections}
        showValue={false}
        defaultValue={value}
        styles={{
          slideBox: {
            padding: 0,
            selectors: {
              ':hover .ms-Slider-active': {
                backgroundColor: hideHover.current ? 'var(--themeSecondary)' : 'rgb(222, 236, 249)'
              }
            },
            height: 14
          },
          activeSection: {
            backgroundColor: hideHover.current ? 'var(--themeSecondary)' : 'rgb(200, 198, 196)'
          },
          thumb: {
            visibility: value !== undefined || sliderRef.current?.state.value ? 'visible' : 'hidden',
            backgroundColor: 'var(--themeSecondary)',
            border: 'none !important'
          }
        }}
      />
      <div className="slider-steps-dots">
        {Array.from({ length: numDots }).map((_, idx) => {
          return (
            <div
              key={idx}
              data-testid={`slider-step-${idx}`}
              className="slider-step"
              style={{ backgroundColor: idx === value ? 'var(--themeSecondary)' : 'black' }}></div>
          )
        })}
      </div>
      <div className="slider-steps-numbers" onMouseMove={onMouseMove}>
        {Array.from({ length: numDots }).map((_, idx) => {
          return (
            <div key={idx}>
              <div style={{ marginLeft: idx === 10 ? -4 : 0 }}>{idx}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
