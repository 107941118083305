import { IPersonaProps, ValidationState } from '@fluentui/react'
import shortid from 'shortid'
import XLSX from 'xlsx'

import packageJson from '../../package.json'
import { IChoice, IProfile, IQuestion, ISurvey, ISurveySubmission, IUserSurveyCopy, QuestionTypes } from '../types'

export function compareArrays<T>(arr1: Array<T>, arr2: Array<T>, compareFunc?: (item1: T, item2: T) => number): boolean {
  if (arr1.length !== arr2.length) return false
  arr1.sort(compareFunc)
  arr2.sort(compareFunc)
  for (let i = 0; i < arr2.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export function shallowCompare(obj1?: any, obj2?: any): boolean {
  if (obj1 === obj2) return true
  if (!obj1 || !obj2) return true
  if (typeof obj1 !== typeof obj2) return false
  if (typeof obj1 !== 'object') return false

  // make sure all keys are the same
  if (!compareArrays(Object.keys(obj1), Object.keys(obj2))) return false

  // make sure all values are the same
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) return false
  }

  return true
}

export const formatFirebaseResponses = (responses: any) => {
  if (!responses) return []
  return Object.keys(responses).reduce<(string | boolean)[][]>((acc, key) => {
    const arr = [responses[key]] as (string | boolean)[]
    if (key !== 'userId') {
      return acc.concat(arr)
    }
    return acc
  }, [])
}

export const timeSinceEpoch = () => new Date().getTime()
export const timeToLocaleDate = (time: number) => new Date(time).toLocaleDateString()

export function matchSubroute(currentPathSplit: string[], subrouteSplit: string[]) {
  let matchHeader = true
  for (let idx = 0; idx < subrouteSplit.length; idx++) {
    if (idx >= currentPathSplit.length) {
      matchHeader = false
      break
    }
    if (currentPathSplit[idx] !== subrouteSplit[idx]) {
      matchHeader = false
      break
    }
  }
  return matchHeader
}

export function userChooseFile(): Promise<FileList | null> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xls,.xlsx,.csv'
    input.onchange = function (e) {
      resolve(input.files)
    }

    input.click()
  })
}

export type Subtract<P, S> = Omit<P, keyof S>

// NOTE: this assumes that we have a scollable-body element; other elements can't scroll
export function scrollToBottom() {
  const scrollBody = document.getElementsByClassName('scrollable-body').item(0)
  if (scrollBody) {
    scrollBody.scrollTo({ top: scrollBody.scrollHeight, behavior: 'smooth' })
  }
}

export const parseSheetFile = (file: File): Promise<{ [email: string]: { name?: string } }> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.onload = function (e: any) {
      const wb = XLSX.read(e.target.result, { type: 'array' })

      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }) as string[][]

      const emailNameInvitations: { [email: string]: { name?: string } } = {}
      data.forEach((row, index) => {
        if (!row.length) return
        let email, name
        if (row.length > 2) {
          return reject(`Invalid upload, more than two columns for row ${index + 1}`)
        }
        if (row[0]) {
          email = row[0]
        } else return reject(`Invalid upload, no email address for row ${index + 1}`)
        if (row[1]) {
          name = row[1]
        }
        emailNameInvitations[email] = { name }
      })
      resolve(emailNameInvitations)
    }
    reader.readAsArrayBuffer(file)
  })
}

export const createEmptyResponses = (questionType: QuestionTypes): IChoice[] => {
  let emptyResponses: IChoice[] = []

  switch (questionType) {
    case QuestionTypes.multipleChoice:
      emptyResponses = [
        { id: shortid.generate(), value: '' },
        { id: shortid.generate(), value: '' }
      ]
      break
    case QuestionTypes.checkboxes:
      emptyResponses = [
        { id: shortid.generate(), value: '' },
        { id: shortid.generate(), value: '' }
      ]
      break
    case QuestionTypes.trueFalse:
      emptyResponses = [
        { id: shortid.generate(), value: 'Yes' },
        { id: shortid.generate(), value: 'No' }
      ]
      break
    case QuestionTypes.openEnded:
      emptyResponses = [{ id: shortid.generate(), value: '' }]
      break
  }

  return emptyResponses
}

// converts 0 to A, 1 to B ... AA, AB, AC, ... , BA, BB, ..., ZZ
export const indexToChar = (index: number) => {
  const ordA = 'A'.charCodeAt(0)
  const ordZ = 'Z'.charCodeAt(0)
  const len = ordZ - ordA + 1

  let str = ''
  while (index >= 0) {
    str = String.fromCharCode((index % len) + ordA) + str
    index = Math.floor(index / len) - 1
  }
  return str
}

export const determineQuestionsAnswered = (submission: ISurveySubmission) => {
  const { survey, userResponse } = submission
  let count = 0
  if (Object.keys(userResponse).length) {
    survey.questions.forEach((q, idx) => {
      const { id: questionId } = q
      if (userResponse[questionId] && userResponse[questionId].hasAnswer) {
        count++
      }
    })
  }
  return count
}

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const NAME_WITH_EMAIL_REGEX = /(?:"?([^"]*)"?\s)?(?:<([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})>)/i

export const handleValidateInput = (input: string, contacts?: IPersonaProps[]) => {
  if (input === '') {
    return ValidationState.invalid
  }

  if (
    contacts &&
    contacts.map(contact => contact.primaryText?.toLowerCase() || contact.text?.toLowerCase()).includes(input.toLowerCase())
  ) {
    return ValidationState.warning
  }

  if (EMAIL_REGEX.test(input)) {
    return ValidationState.valid
  }

  if (NAME_WITH_EMAIL_REGEX.test(input)) {
    const [, name, email] = input.match(NAME_WITH_EMAIL_REGEX)!

    if (!name || !email) {
      return ValidationState.warning
    }

    if (NAME_WITH_EMAIL_REGEX.test(email)) {
      return ValidationState.warning
    }

    if (/^[a-z ,.'-]+$/i.test(name) && EMAIL_REGEX.test(email)) {
      return ValidationState.valid
    }

    return ValidationState.warning
  }

  return ValidationState.warning
}

export function parseVersion(version: string) {
  const tokens = version.split('.')
  const v = tokens.map(t => Number(t)).filter(n => !isNaN(n))
  if (v.length < 2) throw Error('Failed to parse version numbers: ' + version)
  return {
    major: v[0],
    minor: v[1],
    build: v.length > 2 ? v[2] : undefined
  }
}

export function isSurveyOutdated(survey: ISurvey | IUserSurveyCopy) {
  const surveyVersion = survey.version ? parseVersion(survey.version) : null
  const appVersion = parseVersion(packageJson.version)

  return (!surveyVersion && appVersion.major > 0) || (surveyVersion && appVersion.major > surveyVersion.major)
}

export const filterQuestionsByProfile = (profile?: IProfile) => (question: IQuestion) => {
  if (!profile) return true
  if (!question.topic) return true
  if (question.topic === 'blank') return true
  const topicIds = new Set(profile.topics.map(t => t.id))
  if (topicIds.has(question.topic)) return true
  return false
}

export const writeToClipboard = (data: string) => {
  return new Promise<void>((resolve, reject) => {
    navigator.permissions
      // @ts-ignore
      .query({ name: 'clipboard-write' })
      .then((result: PermissionStatus) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.writeText(data).then(
            function () {
              /* clipboard successfully set */
              resolve()
            },
            function () {
              /* clipboard write failed */
              reject(new Error('Failed to write to clipboard'))
            }
          )
        } else reject(new Error('No permission to write to clipboard'))
      })
      .catch((reason: any) => {
        reject(new Error(reason))
      })
  })
}
