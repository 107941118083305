import './SurveyContactsTable.css'

import { CheckboxVisibility, DetailsList, DetailsRow, IColumn, IDetailsRowProps, SelectionMode } from '@fluentui/react'
import { AlertType, AppStateContext } from 'contexts/appState'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { writeToClipboard } from 'utils'

import { ISurveyContact, UserTypes } from '../types'
import { Button } from './Button'

interface ISurveyContactsTableProps {
  loading: boolean
  contacts: ISurveyContact[]
  page: number
}

interface IGetColumnDependencies {
  addAlert: (message: string, alertType: AlertType, timeout?: number) => void
  userType: UserTypes
  onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void
}

const renderCell = (item?: any, index?: number, column?: IColumn) => {
  if (!column) return null

  const { key } = column
  const value = item[key]

  return (
    <div
      title={key === 'email' ? item.value : ''}
      style={
        key === 'email' || key === 'name'
          ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
              width: '100%'
            }
          : {
              textAlign: 'center',
              width: '100%'
            }
      }>
      {value}
    </div>
  )
}

const getColumns = (deps: IGetColumnDependencies): IColumn[] => {
  const { addAlert, userType } = deps

  const ret: IColumn[] = [
    { name: 'Name', key: 'name' },
    {
      name: 'Email',
      key: 'email',
      headerClassName: 'header-email'
    },
    { name: 'Date Sent', key: 'dateReceived' },
    { name: 'Date Started', key: 'dateStarted' },
    { name: 'Date Completed', key: 'dateResponded' },
    { name: 'Answered', key: 'questionsAnswered' }
    // { label: 'Response Status', key: 'responseStatus' }
  ].map(column => ({
    ...column,
    minWidth: getColumnMinWidth(column.key),
    fieldName: column.key,
    maxWidth: getColumnMaxWidth(column.key),
    onColumnClick: deps.onColumnClick,
    onRender: renderCell
  }))

  if (userType === UserTypes.admin) {
    ret.push({
      name: '',
      key: 'surveyUrl',
      minWidth: getColumnMinWidth('surveyUrl'),
      fieldName: 'surveyUrl',
      maxWidth: getColumnMaxWidth('surveyUrl'),
      onColumnClick: deps.onColumnClick,
      onRender: props => {
        const { surveyUrl } = props
        return (
          <Button
            xs
            primary
            onClick={() => {
              writeToClipboard(surveyUrl)
                .then(() => addAlert('Id is copied to clipboard', AlertType.notification))
                .catch(err => addAlert(err.message, AlertType.error, 0))
            }}>
            Copy Id
          </Button>
        )
      }
    })
  }
  return ret
}

function getColumnMaxWidth(key?: string) {
  if (key === 'email') return 300
  if (key === 'name') return 300
  if (key === 'questionsAnswered') return 100
  if (key === 'surveyUrl') return 100
  if (key && key.startsWith('date')) return 125
  return 200
}

function getColumnMinWidth(key?: string) {
  if (key === 'responseStatus') return 150
  return 100
}

export const SurveyContactsTable: FC<ISurveyContactsTableProps> = ({ contacts, page }) => {
  const [columns, setColumns] = useState<IColumn[]>([])
  const [items, setItems] = useState<any[]>([])
  const refItems = useRef(items)
  const refColumns = useRef(columns)

  const renderRow = (props: IDetailsRowProps | undefined) =>
    props ? <DetailsRow {...props} selectionMode={SelectionMode.none} className="survey-contacts-table-row" /> : null

  const { addAlert } = useContext(AppStateContext)
  const { userType } = useContext(DataCacheContext)

  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }

  const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    const columns = refColumns.current
    const items = refItems.current

    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    setItems(newItems)
    refItems.current = newItems
    setColumns(newColumns)
    refColumns.current = newColumns
  }

  useEffect(() => {
    const newItems = contacts
    setItems(newItems)
    refItems.current = newItems

    const newColumns = getColumns({ addAlert, userType, onColumnClick })
    setColumns(newColumns)
    refColumns.current = newColumns
  }, [contacts])

  return (
    <DetailsList
      checkboxVisibility={CheckboxVisibility.hidden}
      items={items}
      onShouldVirtualize={() => false}
      selectionMode={SelectionMode.none}
      columns={columns}
      className="survey-contacts-table"
      onRenderRow={renderRow}
      styles={{
        headerWrapper: {
          background: '#c5d9ce',
          padding: '10px 0',
          borderRadius: 5,
          boxShadow: '0 3px 6px 0 rgba(141, 179, 184, 0.2)',
          '& .header-email .ms-DetailsHeader-cellTitle': {
            justifyContent: 'flex-start'
          }
        }
      }}
    />
  )
}
