import { Modal } from '@fluentui/react'
import { AppStateContext } from 'contexts/appState'
import React, { useContext, useEffect, useState } from 'react'
import { createNewAnswerSurvey, ISurvey, ISurveyInvitation } from 'types'

import { SurveyRespondentView } from './SurveyRespondentView'

export type IOwnerSurveyDashboardModalsProps = {
  survey: ISurvey
  submit: (to: ISurveyInvitation[], subject: string, message: string) => void
}

export const OwnerSurveyDashboardModals: React.FunctionComponent<IOwnerSurveyDashboardModalsProps> = props => {
  const { survey } = props

  const appState = useContext(AppStateContext)
  const [isPreviewing, setIsPreviewing] = useState(false)

  // event subscriptions
  useEffect(() => {
    function onPreview() {
      setIsPreviewing(true)
    }

    appState.dashboardEvents.on('preview', onPreview)

    return () => {
      appState.dashboardEvents.off('preview', onPreview)
    }
  }, [survey.id])

  return (
    <>
      <Modal
        onDismiss={() => setIsPreviewing(false)}
        isOpen={isPreviewing}
        styles={{
          main: {
            width: '80%',
            borderRadius: 10
          },
          root: {
            top: 0
          }
        }}>
        <SurveyRespondentView
          submitResponses={() => setIsPreviewing(false)}
          survey={createNewAnswerSurvey(survey)}
          customSubmitText={'Done preview'}
        />
      </Modal>
    </>
  )
}
