import './Variables.css'

import { initializeIcons } from '@fluentui/react'
import { IFontStyles, IPalette, ISemanticColors, loadTheme, registerIcons } from '@fluentui/react'
import * as React from 'react'

export { Customizations } from './Customizations'

export const semanticColors: Partial<ISemanticColors> = {
  bodyDivider: 'var(--bodyText)',

  bodyFrameBackground: 'var(--bodyFrameBackground)',
  bodyStandoutBackground: 'var(--bodyStandoutBackground)',

  bodyText: 'var(--bodyText)',

  buttonText: 'var(--buttonText)',
  buttonTextHovered: 'var(--buttonText)',
  buttonTextPressed: 'var(--buttonText)',

  focusBorder: 'var(--darkGrey)',

  inputBackgroundChecked: 'var(--themePrimary)',
  inputBackgroundCheckedHovered: 'var(--themePrimary)',
  inputFocusBorderAlt: 'var(--themePrimary)',

  inputPlaceholderText: 'var(--midGrey)',
  // inputText: 'var(--bodyText)',

  linkHovered: 'var(--themeSecondary)',

  listHeaderBackgroundHovered: 'rgba(0,0,0,.3)',
  listHeaderBackgroundPressed: 'rgba(0,0,0,.5)',

  menuHeader: 'var(--themePrimary)',
  menuItemText: 'var(--menuItemColor)',
  menuItemBackgroundHovered: 'var(--menuItemHover)',
  menuItemBackgroundPressed: 'var(--menuItemPress)',

  primaryButtonBackground: 'var(--button)',
  primaryButtonBackgroundHovered: 'var(--buttonHover)',
  primaryButtonBackgroundPressed: 'var(--buttonActive)',
  primaryButtonText: 'var(--buttonText)',
  primaryButtonTextHovered: 'var(--buttonText)',
  primaryButtonTextPressed: 'var(--buttonText)'
}

export const palette: Partial<IPalette> = {
  /* Foreground */
  black: 'var(--bodyText)',
  white: 'var(--white)',
  /* Accent Colors */
  themePrimary: 'var(--themePrimary)',
  themeSecondary: 'var(--themeSecondary)',
  themeTertiary: 'var(--themeTertiary)',

  themeDarker: 'var(--themeSecondary)',
  themeDark: 'var(--themeTertiary)',
  themeDarkAlt: 'var(--themePrimary)',

  themeLighterAlt: 'var(--themeLighterAlt)'
}

export const effects = {
  roundedCorner2: '0px'
}

export const fonts: IFontStyles = {
  tiny: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: '.5em',
    fontWeight: 300
  },
  xSmall: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: '.75em',
    fontWeight: 300
  },
  small: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 300
  },
  smallPlus: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 700
  },
  medium: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 14,
    fontWeight: 300
  },
  mediumPlus: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 14,
    fontWeight: 400
  },
  large: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 14,
    fontWeight: 700
  },
  xLarge: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 16,
    fontWeight: 300
  },
  xLargePlus: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 16,
    fontWeight: 400
  },
  xxLarge: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 16,
    fontWeight: 700
  },
  xxLargePlus: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 18,
    fontWeight: 300
  },
  superLarge: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 18,
    fontWeight: 400
  },
  mega: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 72,
    fontWeight: 700
  }
}

registerIcons({
  icons: {
    Facebook: <img src="/icon_fb.svg" style={{ maxWidth: 20, maxHeight: 20 }} alt="Facebook Logo" />,
    Google: <img src="/icon_google.svg" style={{ maxWidth: 20, maxHeight: 20 }} alt="Google Logo" />,
    Microsoft: <img src="/ms-symbollockup_mssymbol_19.svg" style={{ maxWidth: 20, maxHeight: 20 }} alt="Microsoft Logo" />,
    Reddit: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="86 86 170 170" preserveAspectRatio="xMinYMin meet">
        <g id="Artwork">
          <circle cx="170.9" cy="170.9" r="85.5" fill="#ff4500" />
          <path
            d="M227.9 170.9c0-6.9-5.6-12.5-12.5-12.5-3.4 0-6.4 1.3-8.6 3.5-8.5-6.1-20.3-10.1-33.3-10.6l5.7-26.7 18.5 3.9c.2 4.7 4.1 8.5 8.9 8.5 4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9c-3.5 0-6.5 2-7.9 5l-20.7-4.4c-.6-.1-1.2 0-1.7.3s-.8.8-1 1.4l-6.3 29.8c-13.3.4-25.2 4.3-33.8 10.6-2.2-2.1-5.3-3.5-8.6-3.5-6.9 0-12.5 5.6-12.5 12.5 0 5.1 3 9.4 7.4 11.4-.2 1.2-.3 2.5-.3 3.8 0 19.2 22.3 34.7 49.9 34.7s49.9-15.5 49.9-34.7c0-1.3-.1-2.5-.3-3.7 4.1-2 7.2-6.4 7.2-11.5zm-85.5 8.9c0-4.9 4-8.9 8.9-8.9s8.9 4 8.9 8.9-4 8.9-8.9 8.9-8.9-4-8.9-8.9zm49.7 23.5c-6.1 6.1-17.7 6.5-21.1 6.5-3.4 0-15.1-.5-21.1-6.5-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0 3.8 3.8 12 5.2 17.9 5.2s14-1.4 17.9-5.2c.9-.9 2.4-.9 3.3 0 .7 1 .7 2.4-.2 3.3zm-1.6-14.6c-4.9 0-8.9-4-8.9-8.9s4-8.9 8.9-8.9 8.9 4 8.9 8.9-4 8.9-8.9 8.9z"
            fill="#fff"
          />
        </g>
      </svg>
    ),
    Twitter: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <circle cx="200" cy="200" r="200" fill="#1da1f2" />
        <path
          d="M163.4 305.5c88.7 0 137.2-73.5 137.2-137.2 0-2.1 0-4.2-.1-6.2 9.4-6.8 17.6-15.3 24.1-25-8.6 3.8-17.9 6.4-27.7 7.6 10-6 17.6-15.4 21.2-26.7-9.3 5.5-19.6 9.5-30.6 11.7-8.8-9.4-21.3-15.2-35.2-15.2-26.6 0-48.2 21.6-48.2 48.2 0 3.8.4 7.5 1.3 11-40.1-2-75.6-21.2-99.4-50.4-4.1 7.1-6.5 15.4-6.5 24.2 0 16.7 8.5 31.5 21.5 40.1-7.9-.2-15.3-2.4-21.8-6v.6c0 23.4 16.6 42.8 38.7 47.3-4 1.1-8.3 1.7-12.7 1.7-3.1 0-6.1-.3-9.1-.9 6.1 19.2 23.9 33.1 45 33.5-16.5 12.9-37.3 20.6-59.9 20.6-3.9 0-7.7-.2-11.5-.7 21.1 13.8 46.5 21.8 73.7 21.8"
          fill="#fff"
        />
      </svg>
    ),
    FacebookShare: (
      <svg viewBox="0 0 1365.333 1365.333">
        <path
          d="M1365.333 682.667C1365.333 305.64 1059.693 0 682.667 0 305.64 0 0 305.64 0 682.667c0 340.738 249.641 623.16 576 674.373V880H402.667V682.667H576v-150.4c0-171.094 101.917-265.6 257.853-265.6 74.69 0 152.814 13.333 152.814 13.333v168h-86.083c-84.804 0-111.25 52.623-111.25 106.61v128.057h189.333L948.4 880H789.333v477.04c326.359-51.213 576-333.635 576-674.373"
          fill="#1877f2"
        />
        <path
          d="M948.4 880l30.267-197.333H789.333V554.609C789.333 500.623 815.78 448 900.584 448h86.083V280s-78.124-13.333-152.814-13.333c-155.936 0-257.853 94.506-257.853 265.6v150.4H402.667V880H576v477.04a687.805 687.805 0 00106.667 8.293c36.288 0 71.91-2.84 106.666-8.293V880H948.4"
          fill="#fff"
        />
      </svg>
    ),
    Email: <img src="/icon_email.png" style={{ maxWidth: 20, maxHeight: 20 }} alt="Email Logo" />,
    Drag: <img src="/button_drag.svg" style={{ width: 10 }} alt="Drag Icon" />,
    Spinner: <img src="/loading-spinner.svg" alt="Page Loading" />,
    Publish: (
      <svg width={28.442} height={31.248} viewBox="0 0 28.442 31.248">
        <defs>
          <style>{'.prefix__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}'}</style>
        </defs>
        <path
          className="prefix__a"
          d="M4.924 10.641L14.06 1.5l9.211 9.067M14.197 29.218V4.641M1.514 29.748h25.357M1.5 29.364v-3.442M26.942 29.364v-3.442"
          fill="currentColor"
        />
      </svg>
    ),
    Surveys: (
      <svg width={31.407} height={31.407} viewBox="0 0 31.407 31.407">
        <path
          d="M3.141 6.281H0v21.985a3.15 3.15 0 003.141 3.141h21.985v-3.141H3.141zM28.266 0H9.422a3.15 3.15 0 00-3.141 3.141v18.844a3.15 3.15 0 003.141 3.141h18.844a3.15 3.15 0 003.141-3.141V3.141A3.15 3.15 0 0028.266 0zM26.7 14.133H11v-3.141h15.7zm-6.281 6.281h-9.427v-3.14h9.422zM26.7 7.852H11V4.711h15.7z"
          fill="currentColor"
        />
      </svg>
    ),
    Comment: (
      <svg xmlns="http://www.w3.org/2000/svg" width="36.817" height="36.817" viewBox="0 0 36.817 36.817">
        <path
          fill="#fff"
          d="M36.136 3H6.682a3.677 3.677 0 0 0-3.664 3.682L3 39.817l7.363-7.363h25.773a3.693 3.693 0 0 0 3.682-3.682V6.682A3.693 3.693 0 0 0 36.136 3zM23.25 25.09h-3.682v-3.681h3.682zm0-7.363h-3.682v-7.364h3.682z"
          transform="translate(-3 -3)"
        />
      </svg>
    ),
    Contacts: (
      <svg width={31.111} height={31.111} viewBox="0 0 31.111 31.111">
        <path
          d="M15.556 0c-4.278 0-7.778 4.356-7.778 9.722s3.5 9.722 7.778 9.722 7.778-4.356 7.778-9.722S19.833 0 15.556 0zM7.428 19.444A7.786 7.786 0 000 27.222v3.889h31.111v-3.889a7.758 7.758 0 00-7.428-7.778 10.922 10.922 0 01-8.128 3.889 10.922 10.922 0 01-8.127-3.889z"
          fill="currentColor"
        />
      </svg>
    ),
    Send: (
      <svg width={30.746} height={30.742} viewBox="0 0 30.746 30.742">
        <path
          d="M29.776.069L.381 12.881a.674.674 0 00.024 1.225l7.951 4.491a1.282 1.282 0 001.465-.144L25.497 4.937c.1-.088.352-.256.448-.16s-.056.344-.144.448L12.24 20.504a1.278 1.278 0 00-.128 1.529l5.2 8.336a.676.676 0 001.217-.016L30.684.953a.674.674 0 00-.908-.884z"
          fill="currentColor"
        />
      </svg>
    ),
    Analyze: (
      <svg width={38.25} height={36} viewBox="0 0 38.25 36">
        <path
          d="M37.11 20.25H20.426l11.111 11.111a1.128 1.128 0 001.56.048 16.84 16.84 0 005.142-9.9 1.127 1.127 0 00-1.129-1.259zM36 15.694A16.915 16.915 0 0020.306 0a1.131 1.131 0 00-1.181 1.139v15.736h15.734A1.131 1.131 0 0036 15.694zM15.75 20.25V3.566a1.127 1.127 0 00-1.25-1.13A16.863 16.863 0 00.01 19.714 17.083 17.083 0 0017.088 36a16.744 16.744 0 009.51-3.1 1.117 1.117 0 00.11-1.693z"
          fill="currentColor"
        />
      </svg>
    ),
    Preview: (
      <svg width={28.854} height={19.673} viewBox="0 0 28.854 19.673">
        <path
          d="M14.427 0A15.511 15.511 0 000 9.837a15.5 15.5 0 0028.854 0A15.511 15.511 0 0014.427 0zm0 16.394a6.558 6.558 0 116.558-6.558 6.56 6.56 0 01-6.558 6.558zm0-10.492a3.935 3.935 0 103.935 3.935 3.929 3.929 0 00-3.935-3.935z"
          fill="currentColor"
        />
      </svg>
    ),
    Topics: (
      <svg xmlns="http://www.w3.org/2000/svg" width={35} height={21} viewBox="0 0 35 21">
        <defs>
          <style>{'.prefix__cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-width:4px}'}</style>
        </defs>
        <g id="prefix__icon_topics" transform="translate(2 2)">
          <path id="prefix__Line_28" d="M0 0h31" className="prefix__cls-1" />
          <path id="prefix__Line_29" d="M0 0h21" className="prefix__cls-1" transform="translate(5 9)" />
          <path id="prefix__Line_30" d="M0 0h9" className="prefix__cls-1" transform="translate(11 17)" />
        </g>
      </svg>
    ),
    Search: (
      <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} viewBox="0 0 23 23">
        <g>
          <path
            fill="transparent"
            stroke="#b7b8b8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3px"
            d="M0 11.111a8.889 8.889 0 0 1 15.174-6.284L20 0l-4.826 4.827A8.889 8.889 0 1 1 0 11.111z"
            transform="translate(-902.964 -131.5) rotate(180 462.232 76.5)"
          />
        </g>
      </svg>
    ),
    List: (
      <svg width={20} height={20} viewBox="0 0 23.999 21">
        <path
          fill="currentColor"
          d="M8.285 21a1 1 0 01-1-1v-2a1 1 0 011-1H23a1 1 0 011 1v2a1 1 0 01-1 1zM1 21a1 1 0 01-1-1v-2a1 1 0 011-1h1.428a1 1 0 011 1v2a1 1 0 01-1 1zm7.286-8a1 1 0 01-1-1v-2a1 1 0 011-1H23a1 1 0 011 1v2a1 1 0 01-1 1zM1 13a1 1 0 01-1-1v-2a1 1 0 011-1h1.428a1 1 0 011 1v2a1 1 0 01-1 1zm7.285-9a1 1 0 01-1-1V1a1 1 0 011-1H23a1 1 0 011 1v2a1 1 0 01-1 1zM1 4a1 1 0 01-1-1V1a1 1 0 011-1h1.428a1 1 0 011 1v2a1 1 0 01-1 1z"
        />
      </svg>
    ),
    Thumb: (
      <svg width={20} height={20} viewBox="0 0 19 21">
        <path
          fill="currentColor"
          d="M16.381 21a1 1 0 01-1-1v-2a1 1 0 011-1H18a1 1 0 011 1v2a1 1 0 01-1 1zM8.69 21a1 1 0 01-1-1v-2a1 1 0 011-1h1.62a1 1 0 011 1v2a1 1 0 01-1 1zM1 21a1 1 0 01-1-1v-2a1 1 0 011-1h1.619a1 1 0 011 1v2a1 1 0 01-1 1zm15.381-8a1 1 0 01-1-1v-2a1 1 0 011-1H18a1 1 0 011 1v2a1 1 0 01-1 1zM8.69 13a1 1 0 01-1-1v-2a1 1 0 011-1h1.62a1 1 0 011 1v2a1 1 0 01-1 1zM1 13a1 1 0 01-1-1v-2a1 1 0 011-1h1.619a1 1 0 011 1v2a1 1 0 01-1 1zm15.381-9a1 1 0 01-1-1V1a1 1 0 011-1H18a1 1 0 011 1v2a1 1 0 01-1 1zM8.69 4a1 1 0 01-1-1V1a1 1 0 011-1h1.62a1 1 0 011 1v2a1 1 0 01-1 1zM1 4a1 1 0 01-1-1V1a1 1 0 011-1h1.619a1 1 0 011 1v2a1 1 0 01-1 1z"
        />
      </svg>
    ),
    Back: (
      <svg width={12.857} height={22.434} viewBox="0 0 12.857 22.434">
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={2} d="M11.442 1.414l-10.013 9.8 10.013 9.8" />
      </svg>
    )
  }
})

loadTheme({ palette, semanticColors, fonts, effects })
initializeIcons()
