import { BaseButton, Button, IconButton } from '@fluentui/react'
import { DraftBlockType, DraftInlineStyleType, EditorState, RichUtils } from 'draft-js'
import React, { FC } from 'react'

type IMouseClickEvent = React.MouseEvent<
  HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
  MouseEvent
>

const inlineStyles: { icon: string; command: DraftInlineStyleType }[] = [
  { icon: 'bold', command: 'BOLD' },
  { icon: 'italic', command: 'ITALIC' }
]

const blockTypes: { icon: string; command: DraftBlockType }[] = [
  { icon: 'BulletedList', command: 'unordered-list-item' },
  { icon: 'NumberedList', command: 'ordered-list-item' },
  { icon: 'RightDoubleQuote', command: 'blockquote' }
]
const justifiers: {
  icon: string
  justification: 'left' | 'center' | 'right'
}[] = [
  { icon: 'AlignLeft', justification: 'left' },
  { icon: 'AlignCenter', justification: 'center' },
  { icon: 'AlignRight', justification: 'right' }
]

interface IToolbarProps {
  editorState: EditorState
  handleChange: (editorState: EditorState) => void
  linkSelected: boolean
  setLinkModalOpen: (value: boolean, create: boolean, text: string, url: string) => void
  maxLength: number
  isDisabled?: boolean
}

export const Toolbar: FC<IToolbarProps> = ({ editorState, handleChange, linkSelected, setLinkModalOpen, maxLength, isDisabled }) => {
  const handleStyleClick = (event: IMouseClickEvent, style: DraftInlineStyleType) => {
    event.preventDefault()

    handleChange(RichUtils.toggleInlineStyle(editorState, style))
  }

  const handleBlockTypeClick = (event: IMouseClickEvent, blockType: DraftBlockType) => {
    event.preventDefault()

    handleChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const handleJustificationClick = (event: IMouseClickEvent, justification: 'left' | 'center' | 'right') => {
    event.preventDefault()

    handleChange(RichUtils.toggleBlockType(editorState, `text-align-${justification}`))
  }

  const handleLinkClick = () => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const startKey = selection.getStartKey()

    if (linkSelected) {
      const block = editorState.getCurrentContent().getBlockForKey(startKey)
      const entityKey = block.getEntityAt(selection.getStartOffset())
      const entity = contentState.getEntity(entityKey)
      const { text, url } = entity.getData()

      if (selection.isCollapsed()) {
        setLinkModalOpen(true, false, text, url)
      } else {
        setLinkModalOpen(true, false, text, url)
      }
    } else {
      if (selection.isCollapsed()) {
        setLinkModalOpen(true, true, '', '')
      } else {
        const start = selection.getStartOffset()
        const end = selection.getEndOffset()
        const block = contentState.getBlockForKey(startKey)
        const selectionText = block.getText().slice(start, end)

        setLinkModalOpen(true, true, selectionText, '')
      }
    }
  }

  return (
    <div className="rich-text-editor-toolbar">
      {inlineStyles.map(inlineStyle => (
        <IconButton
          data-testid={inlineStyle.command}
          key={inlineStyle.command}
          className={`rich-text-editor-toolbar-button ${
            editorState.getCurrentInlineStyle().includes(inlineStyle.command) ? 'active' : null
          }`}
          iconProps={{ iconName: inlineStyle.icon }}
          onMouseDown={(event: IMouseClickEvent) => handleStyleClick(event, inlineStyle.command)}
          disabled={isDisabled}
        />
      ))}
      {blockTypes.map(blockType => (
        <IconButton
          data-testid={blockType.command}
          key={blockType.command}
          className={`rich-text-editor-toolbar-button ${
            editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === blockType.command
              ? 'active'
              : null
          }`}
          iconProps={{ iconName: blockType.icon }}
          onMouseDown={(event: IMouseClickEvent) => handleBlockTypeClick(event, blockType.command)}
          disabled={isDisabled}
        />
      ))}
      {justifiers.map(justifier => (
        <IconButton
          data-testid={justifier.justification}
          key={justifier.justification}
          className="rich-text-editor-toolbar-button"
          iconProps={{ iconName: justifier.icon }}
          onMouseDown={(event: IMouseClickEvent) => handleJustificationClick(event, justifier.justification)}
          disabled={isDisabled}
        />
      ))}
      <IconButton
        data-testid="show-link-callout"
        key="link"
        className={`rich-text-editor-toolbar-button ${linkSelected ? 'active' : null} `}
        iconProps={{ iconName: 'link' }}
        onClick={handleLinkClick}
        disabled={isDisabled}
      />
      <div className="rich-text-editor-toolbar-right">
        <span style={{ color: editorState.getCurrentContent().getPlainText('').length === maxLength ? 'var(--errorMessage)' : undefined }}>
          {editorState.getCurrentContent().getPlainText('').length}/{maxLength}
        </span>
      </div>
    </div>
  )
}
