import './css/ProfileEditor.css'

import { AnimatedSubmitButton } from 'components/AnimatedSubmitButton'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { RectangleToggle } from 'components/RectangleToggle'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { useContext, useEffect, useState } from 'react'
import { IProfile, ITopic } from 'types'

interface IProfileEditorProps {
  initialProfile: IProfile
  onSaveProfile: (topicIds: string[]) => void
  hideTitle?: boolean
}

const ProfileEditor: React.FC<IProfileEditorProps> = ({ hideTitle, initialProfile, onSaveProfile }) => {
  const [allTopics, setAllTopics] = useState<ITopic[]>([])
  const [loading, setLoading] = useState(true)
  const [pickedTopics, setPickedTopics] = useState<string[]>(initialProfile.topics ? initialProfile.topics.map(t => t.id) : [])
  const { getTopics } = useContext(DataCacheContext)
  const [hasError, setHasError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    const getAllTopics = async () => {
      setLoading(true)
      const allTopics = await getTopics()
      setAllTopics(allTopics)
      setLoading(false)
    }

    getAllTopics()
  }, [])

  const pickedTopicsSet = new Set(pickedTopics)

  const onToggleTopic = (topic: ITopic) => () => {
    if (!pickedTopicsSet.has(topic.id)) {
      pickedTopics.push(topic.id)
      setPickedTopics([...pickedTopics])
    } else {
      pickedTopics.splice(
        pickedTopics.findIndex(t => t === topic.id),
        1
      )
      setPickedTopics([...pickedTopics])
    }
  }

  const onClickSave = () => {
    try {
      onSaveProfile(pickedTopics)
    } catch (e) {
      console.error(e)
      setHasError(true)
    } finally {
      setIsSubmitted(true)
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="container user-container">
      {!hideTitle && (
        <div className="profile-editor-title">Before we take any more of your time, let us know which values you want us to address.</div>
      )}
      <div className="profile-editor-topics">
        {allTopics.map(topic => (
          <RectangleToggle checked={pickedTopicsSet.has(topic.id)} onClick={onToggleTopic(topic)} key={topic.id}>
            {topic.name}
          </RectangleToggle>
        ))}
      </div>
      <div className="profile-editor-save">
        <AnimatedSubmitButton
          renderContentUnsubmitted={() => <div className="submit-button-content">Save Issues</div>}
          renderContentSubmitted={() => <div className="submit-button-content">Successful</div>}
          renderContentError={() => <div className="submit-button-content">Save failed</div>}
          submitted={isSubmitted}
          hasError={hasError}
          onClick={onClickSave}
        />
      </div>
    </div>
  )
}

export { ProfileEditor }
