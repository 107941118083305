import './index.css'
import '../CreateSurvey/index.css'

import { chartNaColor, chartPrimaryColorMap } from 'components/charts/ChartColors'
import { CustomBarChart, IBarChartDataEntry } from 'components/charts/CustomBarChart'
import { CustomPieChart, IVisChartDataEntry } from 'components/charts/CustomPieChart'
import { HorizontalStackedScale, IScaleDataEntry } from 'components/charts/HorizontalStackedScale'
import React from 'react'
import { IQuestion, QuestionTypes } from 'types'
import { indexToChar } from 'utils'

import { ChartLegends } from './AnalyzeChartLegends'

export interface IAnalyzeChartProps {
  unstyled?: boolean
  index: number
  question: IQuestion
  header: string
  countUnanswered: number
  countAnswered: number
  countTruthyByChoice: { [choiceId: string]: number }
  countImportanceByQuestion: { [questionId: string]: { [importance: string]: number } }
}

export const AnalyzeChart = React.memo((props: IAnalyzeChartProps) => {
  const shouldUseBarChart = props.question.type === QuestionTypes.checkboxes

  if (shouldUseBarChart) return <AnalyzeBarChart {...props} />
  return <AnalyzePieChart {...props} />
})

export const AnalyzeBarChart = React.memo((props: IAnalyzeChartProps) => {
  const { unstyled, question, header, countImportanceByQuestion, countTruthyByChoice } = props

  const barData: IBarChartDataEntry[] = question.choices.map((choice, index) => ({
    name: indexToChar(index),
    value: countTruthyByChoice[choice.id]
  }))

  const importanceData: IScaleDataEntry[] = [
    {
      value: countImportanceByQuestion[question.id]['N/A'],
      name: 'N/A',
      backgroundColor: chartNaColor.backgroundColor
    }
  ]

  for (let i = 0; i <= 10; i++) {
    importanceData.push({
      value: countImportanceByQuestion[question.id][String(i)],
      name: String(i)
    })
  }

  return (
    <div className="analyze-question-thumb-outer">
      <div className={`analyze-question-thumb ${unstyled ? 'unstyled' : undefined}`}>
        <div className="analyze-question-thumb-body">
          <div className="analyze-question-header">{header}</div>

          <div className="analyze-question-chart">
            <CustomBarChart data={barData} />
          </div>

          <ChartLegends
            labels={barData.map((data, index) => {
              const colorIndex = index % chartPrimaryColorMap.length
              const backgroundColor = data.backgroundColor || chartPrimaryColorMap[colorIndex].backgroundColor
              const labelColor = 'var(--white)'
              return {
                backgroundColor,
                labelColor,
                name: data.name,
                description: index === question.choices.length ? 'Unanswered' : question.choices[index].value
              }
            })}
          />

          {question.hasImportantScale && (
            <>
              <div className="analyze-question-scale-tip">From a scale of 0-10, how important is this to you?</div>
              <div className="analyze-question-scale-bar">
                <HorizontalStackedScale data={importanceData} scaleLeft={'Not At All'} scaleRight={'Extremely'} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

export const AnalyzePieChart = React.memo((props: IAnalyzeChartProps) => {
  const { unstyled, question, header, countImportanceByQuestion, countTruthyByChoice } = props

  const pieData: IVisChartDataEntry[] = question.choices.map((choice, index) => {
    return {
      name: indexToChar(index),
      value: countTruthyByChoice[choice.id]
    }
  })

  // if (countUnanswered) {
  //   pieData.push({
  //     name: indexToChar(pieData.length),
  //     value: countUnanswered,
  //     ...chartNaColor
  //   })
  // }

  // const outerEndAngle = (countUnanswered / (totalAnsweredCount + countUnanswered)) * 360

  const renderLegendDescription = (choiceIndex: number) => {
    if (question.choices[choiceIndex] === undefined) {
      return 'Unanswered'
    } else if (question.type === QuestionTypes.openEnded) {
      return 'Answered'
    }
    return question.choices[choiceIndex].value
  }

  return (
    <div className="analyze-question-thumb-outer">
      <div className={`analyze-question-thumb ${unstyled ? 'unstyled' : undefined}`}>
        <div className="analyze-question-thumb-body">
          <div className="analyze-question-header">{header}</div>

          <div className="analyze-question-chart">
            <CustomPieChart
              radius={150}
              data={pieData}
              countImportance={question.hasImportantScale ? countImportanceByQuestion[question.id] : undefined}
              // outerEndAngle={outerEndAngle}
            />
          </div>

          <ChartLegends
            labels={pieData.map((data, index) => {
              const colorIndex = index % chartPrimaryColorMap.length
              const backgroundColor = data.backgroundColor || chartPrimaryColorMap[colorIndex].backgroundColor
              const labelColor = data.labelColor || chartPrimaryColorMap[colorIndex].labelColor
              return {
                backgroundColor,
                labelColor,
                name: data.name,
                description: renderLegendDescription(index)
              }
            })}
          />
        </div>
      </div>
    </div>
  )
})

export interface IAnalyzeBarChartProps {
  unstyled?: boolean
  header: string
  countTopics: { [topicName: string]: number }
}

export const AnalyzeBarChartTopics = React.memo((props: IAnalyzeBarChartProps) => {
  const { unstyled, header, countTopics } = props

  const barData: IBarChartDataEntry[] = Object.entries(countTopics).map(([topicName, topicCount], index) => ({
    name: indexToChar(index),
    value: topicCount,
    description: topicName
  }))

  return (
    <div className="analyze-question-thumb-outer">
      <div className={`analyze-question-thumb ${unstyled ? 'unstyled' : undefined}`}>
        <div className="analyze-question-thumb-body">
          <div className="analyze-question-header">{header}</div>

          <div className="analyze-question-chart">
            <CustomBarChart data={barData} />
          </div>

          <ChartLegends
            style={{
              gridTemplateColumns: 'repeat(auto-fit, minmax(128px, 1fr))'
            }}
            labels={barData.map((data, index) => {
              const colorIndex = index % chartPrimaryColorMap.length
              const backgroundColor = data.backgroundColor || chartPrimaryColorMap[colorIndex].backgroundColor
              const labelColor = 'var(--white)'
              return {
                backgroundColor,
                labelColor,
                name: data.name,
                description: data.description
              }
            })}
          />
        </div>
      </div>
    </div>
  )
})
