import { LoadingSpinner } from 'components/LoadingSpinner'
import { AuthContext, OnlineStatus } from 'contexts/authContext'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ISurveySubmission } from 'shared-types'

export const PublicSurveyRedirect = () => {
  const { online, userInfo } = useContext(AuthContext)
  const { surveyId } = useParams<{ surveyId: string }>()
  const { onSurveySubmission } = useContext(DataCacheContext)
  const [submissions, setSubmissions] = useState<ISurveySubmission[]>([])
  const [unsubscribe, setUnsubscribe] = useState<Promise<() => void>>()
  const [timedOut, setTimedOut] = useState(false)

  const handleSurveySubmission = (surveySubmissions: ISurveySubmission[]) => {
    setSubmissions(surveySubmissions)
  }

  useEffect(() => {
    const fetchSurveySubmissions = () => {
      const unsubscribe = onSurveySubmission(surveyId, handleSurveySubmission)

      setUnsubscribe(unsubscribe)
    }

    if (userInfo && online === OnlineStatus.SignedIn) {
      fetchSurveySubmissions()
    }
  }, [userInfo, online])

  useEffect(() => {
    setTimeout(() => {
      setTimedOut(true)
    }, 10000)
  }, [])

  if (online === OnlineStatus.SignedOut) {
    return <Redirect to={`/signup?redirect=/survey/${surveyId}`} />
  }

  if (online === OnlineStatus.SignedIn) {
    const submissionId = submissions.filter(submission => submission.survey.id === surveyId)

    if (!submissionId.length) {
      return timedOut ? (
        <div style={{ color: 'red' }}>
          Waiting for the response timed out. Please try refreshing the page, and with further issues please contact the survey owner.
        </div>
      ) : (
        <LoadingSpinner />
      )
    }

    unsubscribe && unsubscribe.then(unsubscribe => unsubscribe())

    return <Redirect to={`/survey/${surveyId}/${submissionId[0].id!}`} />
  }

  return timedOut ? (
    <div style={{ color: 'red' }}>
      Waiting for the response timed out. This may not be the correct URL, if you're sure it is: please try refreshing the page, and with
      further issues please contact the survey owner.
    </div>
  ) : (
    <LoadingSpinner />
  )
}
