import 'draft-js/dist/Draft.css'

import { CompositeDecorator, ContentBlock, ContentState, convertFromRaw, Editor, EditorState } from 'draft-js'
import React, { FC } from 'react'

import { Link } from './RichTextEditor/Link'

interface IRichTextReaderProps {
  value: string
  onChange: (editorState: EditorState) => void
}

export const RichTextReader: FC<IRichTextReaderProps> = ({ value, onChange }) => {
  const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()

      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
  }
  const decorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link }])
  const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(value)), decorator)

  const handleBlockStyle = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType()

    if (type === 'blockquote') {
      return 'rich-text-editor-blockquote'
    }

    if (type === 'text-align-left') {
      return 'rich-text-editor-left'
    }
    if (type === 'text-align-center') {
      return 'rich-text-editor-center'
    }
    if (type === 'text-align-right') {
      return 'rich-text-editor-right'
    }

    return ''
  }

  return <Editor readOnly editorState={editorState} onChange={onChange} blockStyleFn={handleBlockStyle} />
}
