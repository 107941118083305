import './DashboardHeader.css'

import { IStyle, ITextFieldStyles, mergeStyles, TextField } from '@fluentui/react'
import React, { FC, FormEvent } from 'react'
import shortid from 'shortid'

import { SearchInput } from './SearchInput'

interface IDashboardHeaderProps {
  editable?: boolean
  toggleable?: boolean
  title: string
  description?: string
  stats?: { key: string; value: string | number }[]
  toggleValue?: boolean
  toggleLabel?: string
  onSearch?: (event?: React.ChangeEvent<HTMLInputElement | undefined>, value?: string) => void
  search?: string
  titleMaxLength?: number
  onChangeTitle?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void
  onChangeDescription?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void
  onToggle?: (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => void
}

const editableStyles = (extraFieldStyles?: IStyle): Partial<ITextFieldStyles> => {
  return {
    field: mergeStyles(
      {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
        outline: 'none',
        boxSizing: 'content-box',
        paddingTop: '6px',
        paddingBottom: '6px',
        selectors: {
          '&:hover': {
            outline: '1px solid var(--lightGrey)'
          },
          '&:focus': {
            outline: '2px solid var(--midGrey)'
          },
          '&:focus:hover': {
            outline: '2px solid var(--midGrey)'
          }
        }
      },
      extraFieldStyles
    )
  }
}

export const DashboardHeader: FC<IDashboardHeaderProps> = ({
  children,
  editable,
  toggleable,
  title,
  titleMaxLength,
  description,
  stats,
  toggleValue,
  toggleLabel,
  onSearch,
  search,
  onChangeTitle,
  onChangeDescription,
  onToggle
}) => {
  const _onChangeTitle = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    if (titleMaxLength && value && value.length > titleMaxLength && onChangeTitle) {
      return onChangeTitle(_, value.substring(0, titleMaxLength))
    } else if (onChangeTitle) {
      onChangeTitle(_, value)
    }
  }

  return (
    <header className="dashboard-header">
      <div className="dashboard-header-inner">
        {onSearch && (
          <div className="dashboard-search">
            <SearchInput value={search} onChange={onSearch} />
          </div>
        )}
        <div className="dashboard-content">
          {editable ? (
            <div className="dashboard-header-editable">
              <div style={{ position: 'relative' }}>
                <TextField
                  name="title"
                  defaultValue={title}
                  placeholder="Add title"
                  onChange={_onChangeTitle}
                  disabled={!onChangeTitle}
                  maxLength={titleMaxLength}
                  styles={editableStyles({
                    fontSize: '32px',
                    fontWeight: 400,
                    height: '32px'
                  })}
                />

                {titleMaxLength && (
                  <div style={{ position: 'absolute', right: -57, bottom: -5, color: title.length === titleMaxLength ? 'red' : undefined }}>
                    {title.length}/{titleMaxLength}
                  </div>
                )}
              </div>
              <div style={{ marginBottom: '14px' }}></div>
              <TextField
                name="description"
                defaultValue={description}
                placeholder="Add description"
                onChange={onChangeDescription}
                disabled={!onChangeDescription}
                styles={editableStyles({
                  fontSize: '1rem',
                  fontWeight: 400,
                  height: '1rem'
                })}
              />
              {toggleable && toggleLabel && toggleValue !== undefined && onToggle && (
                <div className="dashboard-header-toggleable">
                  <span>
                    This survey is <strong>{toggleValue ? 'Private' : 'Public'}.</strong>
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="dashboard-header-editable">
              <h2 className="dashboard-header-title">{title}</h2>
              <div className="dashboard-header-description">{description}</div>
            </div>
          )}
          {stats && (
            <ul className="dashboard-header-stats">
              {stats.map(stat => (
                <li key={shortid()} className="dashboard-header-stat">
                  <span className="dashboard-header-stat-title">{stat.value}</span>
                  <span className="dashboard-header-stat-descriptor">{stat.key}</span>
                </li>
              ))}
            </ul>
          )}
          <div className="dashboard-header-misc">{children}</div>
        </div>
      </div>
    </header>
  )
}
