import './css/AnimatedSubmitButton.css'

import React, { useEffect, useState } from 'react'

interface IAnimatedSubmitButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  renderContentUnsubmitted: () => React.ReactNode
  renderContentSubmitted: () => React.ReactNode
  renderContentError?: () => React.ReactNode
  hasError?: boolean
  submitted: boolean
  disabled?: boolean
}

const AnimatedSubmitButton: React.FC<IAnimatedSubmitButtonProps> = props => {
  const {
    onClick,
    className,
    submitted,
    hasError,
    disabled,
    renderContentUnsubmitted,
    renderContentSubmitted,
    renderContentError,
    children,
    ...otherProps
  } = props

  const [showSubmitted, setShowSubmitted] = useState(submitted)

  useEffect(() => {
    setTimeout(() => {
      setShowSubmitted(submitted)
    }, 250)
  }, [submitted])

  return (
    <button
      type="submit"
      className={[
        className,
        'animated-submit-button',
        submitted ? 'animated-submit-button-submitted' : null,
        hasError ? 'animated-submit-button-error' : null
      ].join(' ')}
      disabled={disabled || submitted}
      onClick={onClick || undefined}
      {...otherProps}>
      <div className="animated-submit-content" style={{ opacity: showSubmitted === submitted ? 1 : 0 }}>
        {showSubmitted && !hasError && renderContentSubmitted()}
        {showSubmitted && hasError && renderContentError && renderContentError()}
        {!showSubmitted && renderContentUnsubmitted()}
      </div>
    </button>
  )
}

export { AnimatedSubmitButton }
