import './css/CheckboxAnimated.css'

import React, { useRef } from 'react'
import shortid from 'shortid'

export interface ICheckboxAnimatedProps {
  checked: boolean
  onChange: () => void
  disabled?: boolean
  label?: string
}

const CheckboxAnimated: React.FC<ICheckboxAnimatedProps> = props => {
  const { checked, onChange, disabled, label } = props

  const idRef = useRef(shortid())

  return (
    <div className="cbx-container">
      <input disabled={disabled} id={idRef.current} type="checkbox" className="inp-cbx" checked={checked} onChange={onChange} />
      <label htmlFor={idRef.current} className="cbx">
        <span>
          <svg viewBox="0 0 12 10" className="inline-svg">
            <polyline points="1.5 6 4.5 9 10.5 1" />
          </svg>
        </span>
        {label && <span>{label}</span>}
      </label>
    </div>
  )
}

export { CheckboxAnimated }
