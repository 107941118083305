import { styles } from './styles'

interface IPvmTemplateProps {
  title: string
  bodyHtmlText: string
  bottomHtmlText?: string
  showLinkButton?: boolean
  link?: string
  linkText?: string
}

export const pvmTemplate = ({ title, bodyHtmlText, bottomHtmlText, showLinkButton = true, link, linkText }: IPvmTemplateProps) => `
<html>
<head>
  <title>
    ${title}
  </title>
  <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet">
  <style>
    ${styles}
  </style>
</head>
<body style="margin:0; padding:10px 0 0 0;" bgcolor="#e9efeb">
  <div bgcolor="#e9efeb">
    <table align="center" width="600px" cellpadding="0" style="padding:40px 0; width:600px;" bgcolor="#e9efeb">
      <tr>
        <td align="center" bgcolor="#e9efeb">
          <a href="${window.location.origin}/#/">
            <img style="width:250px; max-width:250px;" src='https://proxymatch.io/PM_fullcolor@2x.png' alt='logo horizontal'/>
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <table align="center" cellpadding="0" cellspacing="0" width="400px" class="content">
            ${bodyHtmlText}
            ${
              showLinkButton
                ? `<!--[if mso]>
                <div style="height: 28px"></div>
                <tr align="center">
                  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${link}" style="height:40px;v-text-anchor:middle;min-width:100px;" arcsize="25%" stroke="f" fillcolor="#22c960">
                      <w:anchorlock/>
                      <center style="color:#15c;font-family:sans-serif;font-size:16px;">
                        ${linkText || 'Visit link'}
                      </center>
                  </v:roundrect>
                </tr>
                <![endif]-->

                <!--[if !mso]> <!-->
                <tr align="center">
                  <td class="button-large" style="margin-bottom:3em;">
                    <a href="${link}">
                      ${linkText || 'Visit link'}
                    </a>
                  </td>
                </tr>
                <!-- <![endif]-->`
                : ''
            }
            ${bottomHtmlText}
          </table>
        </td>
      </tr>
    </table>
  </div>
</body>
</html>
`
