import './css/Notification.css'

import { Icon } from '@fluentui/react'
import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { AlertType, AppStateContext } from '../contexts/appState'

const AllNotifications = () => {
  const appState = useContext(AppStateContext)
  if (appState.alerts.length === 0) {
    return null
  }

  return (
    <div className="notification-container">
      <div className="notification-inner">
        {appState.alerts.map(alert => (
          <Notification
            key={alert.id}
            id={alert.id}
            type={alert.type}
            onDismiss={() => appState.fadeOutAlert(alert.id)}
            message={alert.message}
            shouldFade={alert.shouldFade}
            displayDismissal={alert.type === AlertType.error}
            onDoneFade={() => appState.removeAlert(alert.id)}
          />
        ))}
      </div>
    </div>
  )
}

export interface INotificationProps {
  id: string
  type: AlertType
  onDismiss: () => void
  message: string
  shouldFade: boolean
  onDoneFade?: () => void
  displayDismissal?: boolean
}

const Notification = (props: INotificationProps) => {
  const { id, type, onDismiss, message, shouldFade, onDoneFade, displayDismissal } = props

  const messageRef = useRef<HTMLElement | null>(null)
  const setMessageRef = useCallback((node: HTMLElement | null) => {
    messageRef.current = node
    if (node) {
      node.classList.add('notification-single-start')
      setTimeout(() => node.classList.remove('notification-single-start'), 1)
    }
  }, [])

  useEffect(() => {
    if (shouldFade && messageRef.current) {
      messageRef.current.classList.add('notification-single-end')
      setTimeout(() => {
        onDoneFade && onDoneFade()
      }, 300)
    }
  }, [shouldFade])

  return (
    <div key={id} ref={setMessageRef} className="notification-single">
      {/* <MessageBar
        messageBarType={type === AlertType.error ? MessageBarType.error : MessageBarType.info}
        isMultiline={false}
        onDismiss={onDismiss}
        dismissButtonAriaLabel="Close"
        styles={{
          dismissal: {
            background: 'transparent',
            boxShadow: 'none',
            selectors: {
              '&:hover, :active, :focus': {
                background: 'transparent',
                boxShadow: 'none'
              }
            }
          }
        }}>
        {message}
      </MessageBar> */}
      <div className={['notification-message-bar', type === AlertType.error ? 'notification-error' : 'notification-message'].join(' ')}>
        <div className="notification-start"></div>
        <div className="notification-message-text">{message}</div>
        <div className="notification-dismissal">
          {displayDismissal && (
            <button className={'notification-dismiss-button'} onClick={onDismiss}>
              <Icon iconName={'Cancel'} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllNotifications
