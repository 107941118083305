export const styles = `
.content {
  color: black;
  font-family: 'Source Sans Pro', sans-serif;
  width: 400px;
  max-width: 400px;
  font-size: 14px;
  margin-top: 24px;
}

.inner {
  margin: auto;
}

.text-small {
  font-size: 12px;
}

.text-medium {
  font-size: 14px;
}

.text-large {
  font-size: 18px;
}

.text-bold {
  font-weight: 600;
}

.text-extra-bold {
  font-weight: 800;
}

.button-large {
  text-align: center; 
  vertical-align: middle;
  color: white;
  background-color: #22c960;
  border-radius: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  height: 40px;
  min-width: 100px;
  width: 200px;
}

.button-large > a {
  text-decoration: none;  
  padding: 8px 56px;
  height: 32px;
  width: 100%;
  text-align: center; 
  vertical-align: middle;
}
`
