import 'containers/css/RespondentAnalyze.css'

import { Icon, IconButton, Modal } from '@fluentui/react'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { SignupForm } from 'containers'
import { AnalyzeChart } from 'containers/AnalyzeSurvey/AnalyzeChart'
import { AuthContext, OnlineStatus } from 'contexts/authContext'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IAnalytics, IProfile, IQuestion, ISurveySubmission, IUserSurveyCopy, QuestionTypes, SurveyTypes } from 'types'
import { filterQuestionsByProfile } from 'utils'
import { useParam } from 'utils/reactUtils'

interface IQuestionWithIndex extends IQuestion {
  index: number
}

export const RespondentAnalyze = () => {
  const questionsPerPage = 1
  const page = useParam('page')
  const { getAnswer, getProfile, getSurveyAnalytics } = useContext(FirebaseContext)
  const { surveyId, answerId } = useParams<{ surveyId: string; answerId?: string }>()
  const [surveySubmission, setSurveySubmission] = useState<ISurveySubmission | null>(null)
  const [survey, setSurvey] = useState<IUserSurveyCopy | null>(null)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(Number(page) || 0)
  const [questions, setQuestions] = useState<IQuestionWithIndex[]>([])
  const [paginatedQuestions, setPaginatedQuestions] = useState<IQuestionWithIndex[]>([])
  const [pageTimedOut, setPageTimedOut] = useState(false)
  const [isSharing, setIsSharing] = useState(false)
  const { online } = useContext(AuthContext)
  const [analytics, setAnalytics] = useState<IAnalytics>()

  const shareButtons = [
    {
      name: 'FacebookShare',
      link: `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${window.location.host}%2F%23%2Fsurvey%2F${surveyId}&title=Take%20this%20survey%20at%20Proxy%20Match%21`
    },
    {
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?text=Take%20this%20survey%20at%20Proxy%20Match%21%20https%3A%2F%2F${window.location.host}%2F%23%2Fsurvey%2F${surveyId}`
    },
    {
      name: 'Reddit',
      link: `https://reddit.com/submit?url=https%3A%2F%2F${window.location.host}%2F%23%2Fsurvey%2F${surveyId}&title=Take%20this%20survey%20at%20Proxy%20Match%21`
    }
  ]

  useEffect(() => {
    const fetchUserSurveySubmission = async () => {
      if (surveyId && answerId) {
        const surveySubmission = await getAnswer(surveyId, answerId)
        setSurveySubmission(surveySubmission)
      }
    }

    if (surveyId) {
      fetchUserSurveySubmission()
    }
  }, [surveyId])

  useEffect(() => {
    const questionsByPage = questions
      .map((question, index) => ({ ...question, index }))
      .splice(questionsPerPage * currentPage, questionsPerPage)

    setTotalPages(Math.ceil(questions.length / questionsPerPage))
    setPaginatedQuestions(questionsByPage)
  }, [questions, currentPage, questionsPerPage])

  useEffect(() => {
    if (surveyId) {
      // wait for analytics collection to be update, todo listen for changes with onSnapshot
      setTimeout(() => {
        getSurveyAnalytics(surveyId).then(setAnalytics)
      }, 3000)
    }
  }, [surveyId])

  useEffect(() => {
    const findProfileAndSetQuestions = async () => {
      if (!surveySubmission) return

      let profile: IProfile | undefined
      if (surveySubmission.profileId) {
        profile = await getProfile(surveySubmission.profileId)
      }

      setQuestions(
        surveySubmission.survey.questions
          .map((question, index) => ({ ...question, index }))
          .filter(q => q.type !== QuestionTypes.openEnded)
          .filter(filterQuestionsByProfile(profile))
      )
      setSurvey(surveySubmission.survey)
    }
    findProfileAndSetQuestions()
  }, [surveySubmission])

  useEffect(() => {
    setTimeout(() => {
      setPageTimedOut(true)
    }, 10000)
  }, [])

  if (!surveySubmission) {
    if (!pageTimedOut) {
      return <LoadingSpinner />
    } else {
      return (
        <p>
          <strong>Error!</strong> We had an issue pulling the survey you requested. Please try again.
        </p>
      )
    }
  }

  const handleCurrentPage = (currentPage: number) => {
    setCurrentPage(currentPage)

    document.getElementsByClassName('app')[0].scrollTo(0, 0)
  }

  if (!analytics) {
    return <LoadingSpinner />
  }

  return (
    <Container user>
      <div className="respondent-surveys-container">
        {currentPage === 0 && (
          <header className="respondent-analyze-header">
            {survey && survey.type === SurveyTypes.public && (
              <>
                <div className="respondent-analyze-share-thumb">
                  <p className="respondent-analyze-share-info">
                    We promise to share your responses with decision-makers. Will you <strong>share</strong> our survey with your friends?
                  </p>
                  <div className="respondent-analyze-share-button-container">
                    <Button xl secondary onClick={() => setIsSharing(true)}>
                      Please share with friends
                    </Button>
                  </div>
                </div>
                <Modal
                  onDismiss={() => setIsSharing(false)}
                  isOpen={isSharing}
                  className="respondent-analyze-share-modal"
                  styles={{
                    main: {
                      width: '60%',
                      maxWidth: '725px',
                      borderRadius: 10
                    },
                    root: {
                      top: 0
                    }
                  }}>
                  <div className="respondent-analyze-share-modal-header">
                    <IconButton
                      iconProps={{ iconName: 'CalculatorMultiply' }}
                      onClick={() => setIsSharing(false)}
                      target="_blank"
                      rel="noopener"
                      styles={{
                        root: {
                          backgroundColor: 'transparent',
                          boxShadow: 'none'
                        },
                        rootHovered: {
                          backgroundColor: 'transparent',
                          boxShadow: 'none'
                        },
                        rootPressed: {
                          backgroundColor: 'var(--lightGrey)',
                          boxShadow: 'none'
                        }
                      }}
                    />
                  </div>
                  <div className="respondent-analyze-share-modal-content">Help us reach 100,000 voices.</div>
                  <div className="respondent-analyze-share-modal-icons">
                    {shareButtons.map(shareButton => (
                      <a key={shareButton.name} href={shareButton.link} target="_blank" rel="noopener noreferrer">
                        <Icon styles={{ root: { width: 50, height: 50 } }} iconName={shareButton.name} />
                      </a>
                    ))}
                  </div>
                </Modal>
              </>
            )}
            <h1 className="respondent-analyze-title">Here's what other people have said.</h1>
          </header>
        )}
        <section className="respondent-analyze-body">
          {analytics &&
            paginatedQuestions.map(question => (
              <AnalyzeChart
                unstyled
                index={question.index}
                key={question.id}
                header={question.value}
                question={question}
                countAnswered={analytics.countAnsweredByQuestion[question.id]}
                countUnanswered={analytics.countUnansweredByQuestion[question.id]}
                countImportanceByQuestion={analytics.countImportanceByQuestion}
                countTruthyByChoice={analytics.countTruthyByChoice}
              />
            ))}
          {totalPages > 0 && currentPage + 1 <= totalPages && (
            <div className="respondent-analyze-pagination-group">
              {currentPage !== 0 && (
                <Button primary responsive onClick={() => handleCurrentPage(currentPage - 1)}>
                  {currentPage + 1 === totalPages ? '< Previous Question' : 'Previous'}
                </Button>
              )}
              {currentPage + 1 !== totalPages && (
                <Button primary responsive onClick={() => handleCurrentPage(currentPage + 1)}>
                  {currentPage === 0 ? 'Next Question >' : 'Next'}
                </Button>
              )}
            </div>
          )}
          {currentPage + 1 === totalPages && (
            <div className="respondent-analyze-pagination-group">
              <Button xl link href="/#/surveys">
                {'<'} Back to my surveys
              </Button>
            </div>
          )}
        </section>
        {online === OnlineStatus.SignedOut && (
          <section style={{ paddingTop: '36px' }}>
            <SignupForm />
          </section>
        )}
      </div>
    </Container>
  )
}
