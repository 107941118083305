import React from 'react'

type ISurveyProgressBarProps = {
  numberOfQuestions: number
  numberOfQuestionsCompleted: number
}

const SurveyProgressBar: React.FunctionComponent<ISurveyProgressBarProps> = props => {
  const { numberOfQuestions, numberOfQuestionsCompleted } = props
  const segmentWidth = 100 / numberOfQuestions
  const renderSegments = () => {
    let segments = []
    if (numberOfQuestionsCompleted === 0) return
    if (numberOfQuestionsCompleted === 1) {
      segments.push(<div key={0} className="survey-respondentview-progressbar-segment-single" style={{ width: `${segmentWidth}%` }}></div>)
      return segments
    }
    if (numberOfQuestionsCompleted <= numberOfQuestions) {
      segments.push(
        <div key={0} className="survey-respondentview-progressbar-segment-left-end" style={{ width: `${segmentWidth}%` }}></div>
      )
      for (let i = 1; i < numberOfQuestionsCompleted - 1; i++) {
        segments.push(
          <div key={i} className="survey-respondentview-progressbar-segment-middle" style={{ width: `${segmentWidth}%` }}></div>
        )
      }
      segments.push(
        <div
          key={numberOfQuestions}
          className="survey-respondentview-progressbar-segment-right-end"
          style={{ width: `${segmentWidth}%` }}></div>
      )
      return segments
    }
  }

  return <div className="survey-respondentview-progressbar">{renderSegments()}</div>
}

export default SurveyProgressBar
