import './index.css'
import './index.css'

import React, { useContext, useEffect, useState } from 'react'
import { match, Redirect, Switch, useHistory } from 'react-router-dom'
import { Route } from 'react-router-dom'

import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Contacts } from '../../containers/Contacts'
import { Topics } from '../../containers/Topics'
import { AppStateContext } from '../../contexts/appState'
import { DataCacheContext } from '../../contexts/dataCacheContext'
import { createEmptySurvey, UserTypes } from '../../types'
import DashboardMenu from './DashboardMenu'
import OwnerDashboard from './OwnerDashboard'

export interface IDashboardBodyProps {
  match: match
  userType: UserTypes
}

// memo so it only re-renders on match change
export const DashboardBody = React.memo((props: IDashboardBodyProps) => {
  return (
    <Switch>
      {props.userType === 'admin' ? <Route path={props.match.path + '/topics'} component={Topics} /> : null}
      <Route path={props.match.path + '/owner'} component={OwnerDashboard} />
      <Route path={props.match.path + '/contacts'} component={Contacts} />
      <Redirect from="*" to="/dashboard/owner" />
    </Switch>
  )
})

export enum sections {
  yourSurveys,
  createdSurveys,
  users
}

export type IDashboardProps = {
  match: match
}

export const Dashboard = (props: IDashboardProps) => {
  const appState = useContext(AppStateContext)
  const dataCache = useContext(DataCacheContext)
  const history = useHistory()
  const [isCreatingNewSurvey, setIsCreatingNewSurvey] = useState(false)
  // const [showCreateSurvey, setShowCreateSurvey] = useState(false)

  useEffect(() => {
    async function onCreateSurvey() {
      // setShowCreateSurvey(true)
      setIsCreatingNewSurvey(true)
      let newSurvey = createEmptySurvey()
      newSurvey = await dataCache.saveManagedSurvey(newSurvey)
      setIsCreatingNewSurvey(false)
      history.push(`/dashboard/owner/survey-view/${newSurvey.id}`)
    }
    appState.dashboardEvents.on('createSurvey', onCreateSurvey)
    return () => {
      appState.dashboardEvents.off('createSurvey', onCreateSurvey)
    }
  }, [])

  if (dataCache.isFetching || isCreatingNewSurvey) {
    return <LoadingSpinner />
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-menu-container">
        <DashboardMenu match={props.match} />
      </div>

      <div className="dashboard-body">
        <div className="scrollable-body">
          <DashboardBody match={props.match} userType={dataCache.userType} />
        </div>
      </div>

      {/* <Modal isOpen={showCreateSurvey} isDarkOverlay={false} isBlocking={false} onDismiss={() => setShowCreateSurvey(false)}>
        <CreateNewSurveyModal onClose={() => setShowCreateSurvey(false)} />
      </Modal> */}
    </div>
  )
}
