import './css/CreatedSurveysList.css'

import {
  ContextualMenu,
  DetailsList,
  DetailsRow,
  IColumn,
  Icon,
  IconButton,
  IDetailsRowProps,
  IDragDropContext,
  SelectionMode
} from '@fluentui/react'
import { Button } from 'components/Button'
import _ from 'lodash'
import React, { FC, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { Container } from '../components/Container'
import { DashboardHeader } from '../components/DashboardHeader'
import { AlertType, AppStateContext } from '../contexts/appState'
import { DataCacheContext } from '../contexts/dataCacheContext'
import { ISurvey, SurveyTypes, UserTypes } from '../types'
import { timeToLocaleDate } from '../utils'
import { Paginator } from './Paginator'
import { OwnerThumb, OwnerThumbType } from './Thumb/OwnerThumb'

interface ISurveySubmissionCounts {
  [surveyId: string]: { sent: number; responded: number }
}

const listViewColumns = (
  getActions: (isSent: boolean) => string[],
  onDropdownPress: (surveyId: string, action: string) => void,
  surveySubmissionCounts: ISurveySubmissionCounts,
  userType: UserTypes
): IColumn[] => {
  const columns: IColumn[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Name',
      minWidth: 100,
      maxWidth: 300,
      data: 'string',
      isResizable: true,
      isPadded: true,
      isRowHeader: true,
      headerClassName: 'list-header-string',
      onRender: (item: ISurvey) => {
        const name = item.name || 'Untitled Survey'
        return (
          <div title={name} className="list-cell-string">
            {name}
          </div>
        )
      }
    },
    {
      key: 'Status',
      name: 'Status',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      isPadded: true,
      headerClassName: 'list-header-string',
      onRender: (item: ISurvey) => {
        if (!item.dateSent) return <div className="list-cell-string">Draft</div>
        else return <div className="list-cell-string">Sent {timeToLocaleDate(item.dateSent)}</div>
      }
    },
    {
      key: 'Questions',
      name: 'Questions',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      isPadded: true,
      onRender: (item: ISurvey) => {
        return <div>{item.questions.length}</div>
      }
    },
    {
      key: 'Responded/Sent',
      name: 'Responded/Sent',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isPadded: true,
      onRender: (item: ISurvey) => {
        if (!surveySubmissionCounts[item.id]) {
          return <div></div>
        }
        return <div>{`${surveySubmissionCounts[item.id].responded}/${surveySubmissionCounts[item.id].sent}`}</div>
      }
    }
  ]

  if (userType === UserTypes.admin) {
    const adminColumns = [
      ...columns.slice(0, 1),
      {
        key: 'createdBy',
        fieldName: 'createdBy',
        name: 'Created By',
        minWidth: 100,
        maxWidth: 200,
        data: 'string',
        isResizable: true,
        isPadded: true,
        headerClassName: 'list-header-string',
        onRender: (item: ISurvey) => {
          return <div className="list-cell-string">{item.createdBy}</div>
        }
      },
      ...columns.slice(1, columns.length)
    ]

    return adminColumns
  }

  return columns
}

const getOwnerActions = (isSent: boolean) => {
  const ret: string[] = ['Publish', 'Duplicate', 'Delete']

  if (isSent) {
    ret.unshift('View')
  } else {
    ret.unshift('View/Update')
  }

  return ret
}

interface IRowMenuButtonProps {
  actions: string[]
  menuButtonClicked: MutableRefObject<boolean>
  item: ISurvey
  onSurveyActionClick: (surveyid: string, action: string) => void
}

const RowMenuButton: FC<IRowMenuButtonProps> = ({ actions, menuButtonClicked, item, onSurveyActionClick }) => {
  const menuButton = useRef(null)
  const [isHidden, setIsHidden] = useState(true)

  return (
    <>
      <div ref={menuButton}>
        <IconButton
          iconProps={{ iconName: 'More' }}
          onRenderMenuIcon={() => null}
          onMouseUp={() => {
            menuButtonClicked.current = true

            setIsHidden(!isHidden)
          }}
          styles={{
            root: {
              background: 'transparent !important',
              boxShadow: 'none'
            },
            icon: {
              fontWeight: 600,
              fontSize: '24px'
            }
          }}
        />
      </div>
      <ContextualMenu
        target={menuButton}
        hidden={isHidden}
        onDismiss={() => setIsHidden(true)}
        items={actions.map((action: string) => ({
          key: action,
          text: action,
          onClick: () => {
            onSurveyActionClick(item.id, action)
          }
        }))}
      />
    </>
  )
}

interface ICreatedSurveysList extends RouteComponentProps<any> {}

const sizeThumb = 260
const gridGap = 32
const marginTop = 60

const amtThumbsHorizontal = (width: number) => Math.floor((width + gridGap) / (sizeThumb + gridGap))
const amtThumbsVertical = (height: number) => Math.floor((height + gridGap - marginTop) / (sizeThumb + gridGap))

const heightListItem = 80
const heightListHeader = 30
const listMarginTop = 25
const heightPaginator = 64
const amtListVertical = (height: number) => Math.floor((height - heightListHeader - listMarginTop - heightPaginator) / heightListItem)

const filters = ['', 'surveys', 'sent', 'drafts', 'private', 'public'] as const
type FilterTypes = typeof filters[number]

const CreatedSurveysList = (props: ICreatedSurveysList) => {
  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const history = useHistory()
  const thumbContainerRef = React.useRef<HTMLDivElement>(null)
  const listContainerRef = React.useRef<HTMLDivElement>(null)

  const [searchValue, setSearchValue] = useState('')
  const [filteredSurveys, setFilteredSurveys] = useState(Object.values(dataCache.managedSurveys))

  const menuButtonClicked = useRef<boolean>(false)

  const [pageThumb, setPageThumb] = useState(0)
  const [pageLimitThumb, setPageLimitThumb] = useState(0)

  const [pageList, setPageList] = useState(0)
  const [pageLimitList, setPageLimitList] = useState(0)

  const [filterMode, setFilterMode] = useState<FilterTypes>('')
  const [filterSentOutCount, setFilterSentOutCount] = useState(0)
  const [filterDraftsCount, setFilterDraftsCount] = useState(0)
  const [filterPrivateCount, setFilterPrivateCount] = useState(0)
  const [filterPublicCount, setFilterPublicCount] = useState(0)
  const [filterSurveysCount, setFilterSurveysCount] = useState(0)

  const onSearch = (_?: React.ChangeEvent<HTMLInputElement | undefined>, value?: string) => {
    if (value?.length === 0 || value === '') {
      setSearchValue('')
      return
    }
    if (value) {
      setSearchValue(value)
    }
  }

  const onSurveyActionClick = (surveyId: string, action: string) => {
    const survey = dataCache.managedSurveys[surveyId]
    if (action === 'View/Update') {
      history.push(`${props.match.path}/survey-view/${surveyId}`)
    } else if (action === 'View') {
      history.push(`${props.match.path}/survey-sent-view/${surveyId}`)
    } else if (action === 'Publish') {
      history.push(`${props.match.path}/publish/${surveyId}`)
    } else if (action === 'Duplicate') {
      dataCache.duplicateManagedSurvey(survey).then(duplicate => {
        appState.addAlert(`Duplicated survey ${duplicate.name}`, AlertType.notification)
        history.push(`/dashboard/owner/survey-view/${duplicate.id}`)
      })
    } else if (action === 'Delete') {
      dataCache.deleteManagedSurvey(survey.id).then(() => appState.addAlert(`Deleted survey ${survey.name}`, AlertType.notification))
    }
  }

  const openSurvey = (surveyId: string) => {
    const survey = dataCache.managedSurveys[surveyId]
    if (!!survey.dateSent) history.push(`${props.match.path}/survey-sent-view/${surveyId}`)
    else history.push(`${props.match.path}/survey-view/${surveyId}`)
  }

  // const determineBannerData = () => {
  //   let numSentOut = 0
  //   let numInProgress = 0
  //   filteredSurveys.forEach(survey => {
  //     if (survey.dateSent) numSentOut++
  //     else numInProgress++
  //   })
  //   return { numSentOut, numInProgress }
  // }

  const getSubmissionCounts = async (surveyId: string) => {
    const submissions = await dataCache.getSurveySubmissions(surveyId)
    const sent = submissions.length
    const responded = submissions.filter(s => s.survey.isComplete).length
    return {
      sent,
      responded
    }
  }

  const renderRow = (props: IDetailsRowProps | undefined) => (props ? <DetailsRow {...props} selectionMode={SelectionMode.none} /> : null)

  const handleRowClick = (context: { data: ISurvey; index: number }, e?: any) => {
    if (menuButtonClicked.current) return (menuButtonClicked.current = false)
    openSurvey(context.data.id)
  }

  const getDragDropEvents = () => ({
    canDrop: (_dropContext?: IDragDropContext, _dragContext?: IDragDropContext) => true
  })

  const [surveySubmissionCounts, setSurveySubmissionCounts] = useState<ISurveySubmissionCounts>({})

  useEffect(() => {
    const exec = async () => {
      const counts: ISurveySubmissionCounts = {}
      await Promise.all(
        Object.keys(dataCache.managedSurveys).map(async surveyId => {
          counts[surveyId] = await getSubmissionCounts(surveyId)
        })
      )
      setSurveySubmissionCounts(counts)
    }
    exec()
  }, Object.keys(dataCache.managedSurveys))

  useEffect(() => {
    const surveyList = Object.values(dataCache.managedSurveys)
    let countSentOut = 0
    let countDrafts = 0
    let countPrivate = 0
    let countPublic = 0

    surveyList.forEach(survey => {
      if (survey.dateSent) {
        countSentOut++
      } else {
        countDrafts++
      }

      if (survey.type === SurveyTypes.private) {
        countPrivate++
      } else {
        countPublic++
      }
    })

    setFilterSentOutCount(countSentOut)
    setFilterDraftsCount(countDrafts)
    setFilterPrivateCount(countPrivate)
    setFilterPublicCount(countPublic)

    setFilterSurveysCount(surveyList.length)
  }, [dataCache.managedSurveys])

  useEffect(() => {
    const newFilteredSurveys = Object.values(dataCache.managedSurveys).filter(survey => {
      if (searchValue && !survey.name.toLowerCase().includes(searchValue.toLowerCase())) return false
      if (filterMode === 'sent' && !survey.dateSent) return false
      if (filterMode === 'drafts' && survey.dateSent) return false
      if (filterMode === 'private' && survey.type !== SurveyTypes.private) return false
      if (filterMode === 'public' && survey.type !== SurveyTypes.public) return false
      return true
    })

    setPageList(0)
    setPageThumb(0)
    setFilteredSurveys(newFilteredSurveys)
  }, [searchValue, filterMode])

  const onResize = useCallback(
    _.throttle(() => {
      // calculate size based on empty container
      setPageLimitThumb(0)

      setTimeout(() => {
        if (!thumbContainerRef.current?.clientWidth || !thumbContainerRef.current.clientHeight) return
        const height = thumbContainerRef.current.clientHeight
        const width = thumbContainerRef.current.clientWidth
        setPageLimitThumb(amtThumbsHorizontal(width) * amtThumbsVertical(height))
      })

      setPageLimitList(0)

      setTimeout(() => {
        if (!listContainerRef.current?.clientWidth || !listContainerRef.current.clientHeight) return
        const height = listContainerRef.current.clientHeight
        setPageLimitList(amtListVertical(height))
      })
    }, 1000),
    []
  )

  useEffect(() => {
    if (thumbContainerRef.current?.clientWidth && thumbContainerRef.current.clientHeight) {
      const heightThumb = thumbContainerRef.current.clientHeight
      const width = thumbContainerRef.current.clientWidth

      setPageLimitThumb(amtThumbsHorizontal(width) * amtThumbsVertical(heightThumb))
    }

    if (listContainerRef.current?.clientWidth && listContainerRef.current.clientHeight) {
      const height = listContainerRef.current.clientHeight

      setPageLimitList(amtListVertical(height))
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [appState.viewMode])

  // const bannerData = determineBannerData()

  const columns: IColumn[] = [
    {
      key: 'type',
      name: '',
      minWidth: 30,
      maxWidth: 80,
      isResizable: false,
      isPadded: false,
      onRender: (item: ISurvey) => {
        return <div>{item.type === SurveyTypes.public ? 'Public' : ''}</div>
      }
    } as IColumn
  ]
    .concat(listViewColumns(getOwnerActions, onSurveyActionClick, surveySubmissionCounts, dataCache.userType))
    .concat({
      key: 'options',
      name: '',
      minWidth: 40,
      isResizable: false,
      isPadded: true,
      onRender: (item: ISurvey) => {
        const actions = getOwnerActions(!!item.dateSent)

        return (
          <RowMenuButton actions={actions} menuButtonClicked={menuButtonClicked} item={item} onSurveyActionClick={onSurveyActionClick} />
        )
      }
    })

  return (
    <div className="dashboard-body-container">
      <DashboardHeader
        title="Created Surveys"
        // stats={[
        //   { key: 'Surveys', value: filteredSurveys.length },
        //   { key: 'Sent out', value: bannerData.numSentOut },
        //   {
        //     key: 'Drafts',
        //     value: bannerData.numInProgress
        //   }
        // ]}
        onSearch={onSearch}>
        <div className="dashboard-header-created-surveys">
          <div className="survey-contacts-filters" style={{ margin: 10 }}>
            <Button
              secondary={filterMode === ''}
              ghost={filterMode !== ''}
              onClick={() => setFilterMode('')}
              text={`${filterSurveysCount} Surveys`}
              sm
            />
            <Button
              secondary={filterMode === 'sent'}
              ghost={filterMode !== 'sent'}
              onClick={() => setFilterMode('sent')}
              text={`${filterSentOutCount} Sent`}
              sm
            />
            <Button
              secondary={filterMode === 'drafts'}
              ghost={filterMode !== 'drafts'}
              onClick={() => setFilterMode('drafts')}
              text={`${filterDraftsCount} Drafts`}
              sm
            />
            <Button
              secondary={filterMode === 'private'}
              ghost={filterMode !== 'private'}
              onClick={() => setFilterMode('private')}
              text={`${filterPrivateCount} Private`}
              sm
            />
            <Button
              secondary={filterMode === 'public'}
              ghost={filterMode !== 'public'}
              onClick={() => setFilterMode('public')}
              text={`${filterPublicCount} Public`}
              sm
            />
          </div>

          <div className="dashboard-view-options">
            <div className="icon-container">
              <Icon
                iconName="Thumb"
                styles={{
                  root: {
                    backgroundColor: appState.viewMode === 'thumb' ? 'var(--themeSecondary)' : 'var(--themeDark)',
                    borderRadius: '20px 0 0 20px',
                    // width: '100%',
                    textAlign: 'center',
                    selectors: {
                      '&:hover': {
                        backgroundColor: appState.viewMode === 'thumb' ? 'var(--themeSecondary)' : 'var(--themePrimary)'
                      }
                    }
                  }
                }}
                onClick={() => appState.setViewMode('thumb')}
              />
            </div>
            <div className="icon-container">
              <Icon
                iconName="List"
                styles={{
                  root: {
                    backgroundColor: appState.viewMode === 'list' ? 'var(--themeSecondary)' : 'var(--themeDark)',
                    borderRadius: '0 20px 20px 0',
                    // width: '100%',
                    width: 50,
                    textAlign: 'center',
                    selectors: {
                      '&:hover': {
                        backgroundColor: appState.viewMode === 'list' ? 'var(--themeSecondary)' : 'var(--themePrimary)'
                      }
                    }
                  }
                }}
                onClick={() => appState.setViewMode('list')}
              />
            </div>
          </div>
        </div>
      </DashboardHeader>

      <div
        className="dashboard-body-lower"
        style={
          appState.viewMode === 'list'
            ? {
                overflowY: 'auto'
              }
            : {}
        }>
        <Container
          style={appState.viewMode === 'thumb' ? rootContainerStyle : { ...rootContainerStyle, padding: 0, maxWidth: 1350, width: '90%' }}>
          {filteredSurveys.length === 0 ? (
            <div className="no-results-found">No Results Found</div>
          ) : (
            <>
              {appState.viewMode === 'thumb' && (
                <div ref={thumbContainerRef} style={containerStyle}>
                  <div className="dashboard-body-lower-inner">
                    {filteredSurveys.slice(pageLimitThumb * pageThumb, (pageThumb + 1) * pageLimitThumb).map(survey => (
                      <OwnerThumb
                        key={survey.id}
                        itemKey={survey.id}
                        actions={getOwnerActions(!!survey.dateSent)}
                        onSurveyClick={() => {
                          openSurvey(survey.id)
                        }}
                        onSurveyItemClick={onSurveyActionClick}
                        numOfQuestions={survey.questions.length}
                        {...survey}
                        thumbType={OwnerThumbType.createdSurvey}
                        responded={surveySubmissionCounts[survey.id]?.responded}
                        sent={surveySubmissionCounts[survey.id]?.sent}
                      />
                    ))}
                  </div>

                  <div className="paginator-contacts-container">
                    <Paginator
                      maxPaginatorDisplay={Math.ceil(filteredSurveys.length / pageLimitThumb)}
                      countPages={Math.ceil(filteredSurveys.length / pageLimitThumb)}
                      currentPage={pageThumb}
                      onPageChange={setPageThumb}
                    />
                  </div>
                </div>
              )}
              {appState.viewMode === 'list' && (
                <div ref={listContainerRef} style={{ ...containerStyle }}>
                  <DetailsList
                    onRenderRow={renderRow}
                    items={filteredSurveys.slice(pageLimitList * pageList, (pageList + 1) * pageLimitList)}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    dragDropEvents={getDragDropEvents()}
                    rowElementEventMap={[{ eventName: 'click', callback: handleRowClick }]}
                  />

                  <div className="paginator-contacts-container">
                    <Paginator
                      maxPaginatorDisplay={Math.ceil(filteredSurveys.length / pageLimitList)}
                      countPages={Math.ceil(filteredSurveys.length / pageLimitList)}
                      currentPage={pageList}
                      onPageChange={setPageList}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  )
}

export default CreatedSurveysList

const containerStyle: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box',

  alignItems: 'center'
}

const rootContainerStyle: React.CSSProperties = {
  padding: '0 32px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box'
}
