import './css/Contacts.css'

import { CheckboxVisibility, DetailsList, DetailsRow, IColumn, IDetailsRowProps, SelectionMode } from '@fluentui/react'
import { Container } from 'components/Container'
import { DashboardHeader } from 'components/DashboardHeader'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ISurveyUserSubmissions } from 'types'
import { determineQuestionsAnswered, timeToLocaleDate } from 'utils'

import { Paginator } from './Paginator'

const pageLimit = 50

export const Contacts = () => {
  const { surveySubmissions } = useContext(DataCacheContext)
  const { contacts } = useContext(DataCacheContext)
  const [search, setSearch] = useState<string | undefined>('')
  const [page, setPage] = useState(0)
  const [columns, setColumns] = useState<IColumn[]>([])
  const [items, setItems] = useState<any[]>([])
  const refItems = useRef(items)
  const refColumns = useRef(columns)

  const handleSearchChange = (_?: React.ChangeEvent<HTMLInputElement | undefined> | undefined, value?: string | undefined) => {
    setSearch(value)
    setPage(0)
  }

  useEffect(() => {
    const newItems = Object.entries(surveySubmissions)
      .filter(([key]) => key.indexOf(search || '') >= 0)
      .map(([_, value]) => value)

    setItems(newItems)
    refItems.current = newItems
  }, [surveySubmissions, search])

  const onColumnClick = (_: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const columns = refColumns.current
    const items = refItems.current

    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })
    const _items = items.slice(pageLimit * page, (page + 1) * pageLimit)
    const newItems = _copyAndSort(_items, currColumn.fieldName!, currColumn.isSortedDescending)
    setItems(newItems)
    refItems.current = newItems
    setColumns(newColumns)
    refColumns.current = newColumns
  }

  useEffect(() => {
    const listViewColumns: IColumn[] = [
      {
        key: 'name',
        fieldName: 'name',
        name: 'Name',
        minWidth: 100,
        maxWidth: 200,
        data: 'string',
        isResizable: true,
        onColumnClick,
        onRender: (item: ISurveyUserSubmissions) => {
          const email = Object.values(item)[0].submissionUserId
          const contact = contacts ? contacts[email] : null

          if (contact && contact.name) {
            return <div style={{ marginLeft: 18, overflow: 'hidden', textOverflow: 'ellipsis', width: '80%' }}>{contact.name}</div>
          }
          return null
        }
      },
      {
        key: 'email',
        fieldName: 'email',
        name: 'Email',
        minWidth: 100,
        maxWidth: 250,
        data: 'string',
        isResizable: true,
        isPadded: true,
        onColumnClick,
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div title={Object.values(item)[0].submissionUserId} style={{ width: '95%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {Object.values(item)[0].submissionUserId}
            </div>
          )
        }
      },
      {
        key: 'surveys',
        fieldName: 'surveys',
        name: 'Survey',
        minWidth: 200,
        maxWidth: 300,
        data: 'string[]',
        isResizable: true,
        isPadded: true,
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div className="contacts-row-inner">
              {Object.values(item).map(submission => (
                <div className="contacts-cell" key={submission.survey.id}>
                  {submission.survey.name || 'Untitled Survey'}
                </div>
              ))}
            </div>
          )
        }
      },
      {
        key: 'dateSent',
        name: 'Date Sent',
        minWidth: 100,
        maxWidth: 125,
        data: 'string[]',
        onColumnClick,
        isResizable: true,
        isPadded: true,
        headerClassName: 'list-header-number',
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div className="contacts-row-inner">
              {Object.values(item).map(submission => (
                <div className="list-cell-number contacts-cell" key={submission.survey.id}>
                  {timeToLocaleDate(submission.survey.dateReceived)}
                </div>
              ))}
            </div>
          )
        }
      },
      {
        key: 'dateStarted',
        name: 'Date Started',
        minWidth: 100,
        maxWidth: 125,
        data: 'string[]',
        isResizable: true,
        isPadded: true,
        headerClassName: 'list-header-number',
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div className="contacts-row-inner">
              {Object.values(item).map(submission => (
                <div className="list-cell-number contacts-cell" key={submission.survey.id}>
                  {submission.survey.dateStarted ? timeToLocaleDate(submission.survey.dateStarted) : '-'}
                </div>
              ))}
            </div>
          )
        }
      },
      {
        key: 'dateSubmitted',
        name: 'Date Completed',
        minWidth: 100,
        maxWidth: 125,
        data: 'string[]',
        isResizable: true,
        isPadded: true,
        headerClassName: 'list-header-number',
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div className="contacts-row-inner">
              {Object.values(item).map(submission => (
                <div className="list-cell-number contacts-cell" key={submission.survey.id}>
                  {submission.survey.dateSubmitted !== null ? timeToLocaleDate(submission.survey.dateSubmitted) : '-'}
                </div>
              ))}
            </div>
          )
        }
      },
      {
        key: 'questionsAnswered',
        name: 'Answered',
        minWidth: 120,
        maxWidth: 120,
        data: 'string[]',
        isResizable: true,
        isPadded: true,
        headerClassName: 'list-header-number',
        onRender: (item: ISurveyUserSubmissions) => {
          return (
            <div className="contacts-row-inner">
              {Object.values(item).map(submission => {
                const numQuestionsAnswered = determineQuestionsAnswered(submission)
                return (
                  <div className="list-cell-number contacts-cell" key={submission.survey.id}>
                    {numQuestionsAnswered}/{submission.survey.questions.length}
                  </div>
                )
              })}
            </div>
          )
        }
      }
    ]

    setColumns(listViewColumns)
    refColumns.current = listViewColumns
  }, [contacts])

  const renderRow = (props?: IDetailsRowProps) => {
    if (!props) return null

    const item = items[props?.itemIndex]

    let justifyContent: 'center' | 'flex-start' = 'center'

    if (Object.keys(item).length > 1) {
      justifyContent = 'flex-start'
    }

    const styles = {
      root: {
        selectors: {
          '& .ms-DetailsRow-cell': {
            justifyContent
          }
        }
      }
    }

    return <DetailsRow {...props} selectionMode={SelectionMode.none} className="contacts-row" styles={styles} />
  }

  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
  }

  const pagination = () => (
    <div className="paginator-contacts-container">
      <Paginator
        maxPaginatorDisplay={Math.ceil(items.length / pageLimit)}
        countPages={Math.ceil(items.length / pageLimit)}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  )

  const pageItems = items.slice(pageLimit * page, (page + 1) * pageLimit)

  return (
    <div className="contacts-container">
      <DashboardHeader title="Contacts" stats={[{ key: 'Contacts', value: items.length }]} onSearch={handleSearchChange} />
      <div className="contacts-body">
        <Container>
          {pageItems.length === 0 ? (
            <div>No Results Found</div>
          ) : (
            <>
              {pagination()}
              <DetailsList
                items={pageItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                checkboxVisibility={CheckboxVisibility.hidden}
                onRenderRow={renderRow}
              />
              {pagination()}
            </>
          )}
        </Container>
      </div>
    </div>
  )
}
