import { Customizer } from '@fluentui/react'
import LogRocket from 'logrocket'
import * as React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend'
import TouchBackend from 'react-dnd-touch-backend'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'

import App from './App'
import { AppStateProvider } from './contexts/appState'
import AuthProvider from './contexts/authContext'
import { DataCacheProvider } from './contexts/dataCacheContext'
import FirebaseProvider from './contexts/firebaseContext'
import * as serviceWorker from './serviceWorker'
import { Customizations } from './styles/Theme'
import { initializeFullStory } from './utils/FullStory'

const DragOptions = {
  backends: [
    {
      backend: HTML5Backend
    },
    {
      backend: TouchBackend,
      preview: true,
      transition: TouchTransition
    }
  ]
}

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('k0sy6v/proxymatch')
  initializeFullStory()
}

const Main = () => (
  <Customizer {...Customizations}>
    <AuthProvider>
      <FirebaseProvider>
        <DataCacheProvider>
          <AppStateProvider>
            <DndProvider backend={MultiBackend} options={DragOptions}>
              <Router>
                <App />
              </Router>
            </DndProvider>
          </AppStateProvider>
        </DataCacheProvider>
      </FirebaseProvider>
    </AuthProvider>
  </Customizer>
)

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
