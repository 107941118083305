import './css/FirebaseErrorDisplay.css'

import { Modal } from '@fluentui/react'
import { Button } from 'components/Button'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext } from 'react'

interface IFirebaseErrorDisplayProps {}

const FirebaseErrorDisplay: React.FC<IFirebaseErrorDisplayProps> = () => {
  const { showError, setShowError } = useContext(FirebaseContext)

  return (
    <Modal isOpen={showError} onDismiss={() => setShowError(false)} styles={{ main: { borderRadius: 10 } }}>
      <div className="firebase-error-display-container">
        <div className="firebase-error-message">Sorry, something went wrong.</div>
        <div className="firebase-error-advice">If you continue seeing this error, please try refreshing the page</div>
        <div className="firebase-error-buttons">
          <Button ghost onClick={() => setShowError(false)}>
            Ignore
          </Button>
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </div>
      </div>
    </Modal>
  )
}

export { FirebaseErrorDisplay }
