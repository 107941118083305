import { surveyInvitationEmail } from 'emailTemplates/surveyInvitationEmail'
import firebase from 'firebase'
import LogRocket from 'logrocket'
import path from 'path'
import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { AuthContext } from '../contexts/authContext'
import {
  createEmptySurvey,
  createNewAnswerSurvey,
  IAnalytics,
  IContacts,
  IInvitedSurveys,
  IProfile,
  ISurvey,
  ISurveyComments,
  ISurveyInvitation,
  ISurveySubmission,
  ITopic,
  IUserResponse,
  IUserTopic,
  SurveyTypes,
  UserTypes
} from '../types'
import { Subtract, timeSinceEpoch } from '../utils'

export interface IFirebaseContext {
  isInitialized: boolean

  lastError: Error | null
  showError: boolean
  setShowError: (showError: boolean) => void

  add(collection: string, payload: {}): void
  get(collection: string): void
  saveSurvey: (survey: ISurvey) => Promise<ISurvey>
  duplicateSurvey: (survey: ISurvey, userId: string) => Promise<ISurvey>
  getSurveys: (userId: string) => Promise<ISurvey[]>
  getSurvey: (surveyId: string) => Promise<ISurvey | undefined>
  submitResponse: (surveyId: string, answerId: string, response: IUserResponse) => Promise<void>
  inviteEmails: (emails: ISurveyInvitation[], subject: string, message: string, survey: ISurvey, file?: File) => Promise<ISurvey>
  getAnswer: (surveyId: string, answerId: string) => Promise<ISurveySubmission | null>
  getInvitedSurveys: () => Promise<IInvitedSurveys>
  deleteSurvey: (surveyId: string) => Promise<void>
  uploadFile: (
    path: string,
    data: Blob | File,
    progressUpdateCb?: (progress: number) => void,
    metadata?: firebase.storage.UploadMetadata
  ) => Promise<string>
  uploadSurveyLogo: (data: File, progressUpdateCb?: (progress: number) => void) => Promise<string>
  saveAnswer: (surveyId: string, answerId: string, response: IUserResponse) => void
  getSurveyAnalytics: (surveyId: string) => Promise<IAnalytics | undefined>
  getSurveyComments: (surveyId: string) => Promise<ISurveyComments | undefined>
  getSurveySubmissions: (surveyId: string) => Promise<ISurveySubmission[]>
  getSurveySubmissionsDict: (surveyId: string) => Promise<{ [answerId: string]: ISurveySubmission }>
  onSurveySubmission: (surveyId: string, callback: (submissions: ISurveySubmission[]) => void) => Promise<() => void>
  getUserType: (userId: string) => Promise<UserTypes>
  onUpdateManagedSurveys: (userId: string, callback: (data: ISurvey[]) => void) => Promise<() => void>
  onUpdateInvitedSurveys: (callback: (data: IInvitedSurveys) => void) => () => void
  getTopics: () => Promise<ITopic[]>
  addTopic: () => Promise<ITopic | null>
  deleteTopic: (topicId: string) => Promise<void>
  updateTopic: (topicId: string, name: string) => Promise<void>
  reconnect: () => Promise<void>
  checkPublicSurveys: () => void
  getUserEmail: (uid: string) => Promise<string | null>
  getUserEmailFromPermissions: (uid: string) => Promise<string | null>
  getUserIdFromPermissions: (email: string) => Promise<string | null>
  getAllOwners: () => Promise<string[]>
  updateOwner: (surveyId: string, uid: string) => void
  getContacts: () => Promise<IContacts>
  getAllContacts: () => Promise<IContacts>
  getProfile: (profileId: string) => Promise<IProfile>
  initializeUserProfile: (email: string) => Promise<string>
  updateProfile: (profileId: string, topicIds: string[]) => Promise<void>
  updateProfileName: (profileId: string, name: string) => Promise<void>
  updateUserEmail: (newEmail: string, password: string) => Promise<void>
  reauthenticate: (password: string) => Promise<void>
  updatePassword: (currentPassword: string, newPassword: string) => Promise<void>
}

// empty state, all the functions do nothing
const defaultState: IFirebaseContext = {
  isInitialized: false,
  lastError: null,
  showError: false,
  setShowError() {},
  add() {},
  get() {},
  saveSurvey() {
    return Promise.resolve(createEmptySurvey())
  },
  initializeUserProfile() {
    return Promise.resolve('')
  },
  duplicateSurvey() {
    return Promise.resolve(createEmptySurvey())
  },
  getSurveys() {
    return Promise.resolve([])
  },
  getSurvey() {
    return Promise.resolve(undefined)
  },
  submitResponse() {
    return Promise.resolve()
  },
  inviteEmails() {
    return Promise.resolve(createEmptySurvey())
  },
  getAnswer() {
    return Promise.resolve(null)
  },
  getInvitedSurveys() {
    return Promise.resolve([])
  },
  deleteSurvey() {
    return Promise.resolve()
  },
  saveAnswer() {},
  uploadFile() {
    return Promise.resolve('')
  },
  uploadSurveyLogo() {
    return Promise.resolve('')
  },
  getSurveySubmissions() {
    return Promise.resolve([])
  },
  getSurveySubmissionsDict() {
    return Promise.resolve({})
  },
  onSurveySubmission() {
    return Promise.resolve(() => {})
  },
  onUpdateManagedSurveys() {
    return Promise.resolve(() => {})
  },
  onUpdateInvitedSurveys() {
    return () => {}
  },
  getUserType() {
    return Promise.resolve(UserTypes.initial)
  },
  getTopics() {
    return Promise.resolve([])
  },
  addTopic() {
    return Promise.resolve({ id: '', owner: '', name: '' })
  },
  deleteTopic() {
    return Promise.resolve()
  },
  updateTopic() {
    return Promise.resolve()
  },
  reconnect() {
    return Promise.resolve()
  },
  checkPublicSurveys() {
    return
  },
  getUserEmail() {
    return Promise.resolve(null)
  },
  getUserEmailFromPermissions() {
    return Promise.resolve(null)
  },
  getUserIdFromPermissions() {
    return Promise.resolve(null)
  },
  getAllOwners() {
    return Promise.resolve([])
  },
  updateOwner() {
    return Promise.resolve([])
  },
  getContacts() {
    return Promise.resolve({})
  },
  getAllContacts() {
    return Promise.resolve({})
  },
  getProfile(profileId: string) {
    return Promise.resolve({} as IProfile)
  },
  updateProfile(profileId: string, topicIds: string[]) {
    return Promise.resolve()
  },
  updateProfileName(name: string) {
    return Promise.resolve()
  },
  getSurveyAnalytics(surveyId: string) {
    return Promise.resolve(undefined)
  },
  getSurveyComments(surveyId: string) {
    return Promise.resolve(undefined)
  },
  updateUserEmail(newEmail: string, password: string) {
    return Promise.resolve()
  },
  reauthenticate() {
    return Promise.resolve()
  },
  updatePassword() {
    return Promise.resolve()
  }
}

export const FirebaseContext = React.createContext<IFirebaseContext>(defaultState)

export const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG
  ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
  : {
      apiKey: 'AIzaSyC2HJ0_60DNIUBrdQU12fX9L-sGMI90kC4',
      authDomain: 'proxyvaluematch-staging.firebaseapp.com',
      databaseURL: 'https://proxyvaluematch-staging.firebaseio.com',
      projectId: 'proxyvaluematch-staging',
      storageBucket: 'proxyvaluematch-staging.appspot.com',
      messagingSenderId: '541783894255',
      appId: '1:541783894255:web:66e5f5f2d708e99d51931a'
    }

export interface IFirebaseProviderProps {}
let hasInitialized = false

const FirebaseProvider: React.FunctionComponent<IFirebaseProviderProps> = props => {
  const { userInfo } = useContext(AuthContext)
  const [showError, setShowError] = useState(false)
  const [lastError, setLastError] = useState<Error | null>(null)

  // for errors thrown by firebase
  const errorHandler = (source: string) => (error: any) => {
    setShowError(true)
    setLastError(error)
    console.error(`Caught an error from ${source}`, error)

    if (typeof error === 'string') {
      LogRocket.captureMessage(error, { tags: { errorMessage: error } })
    } else {
      LogRocket.captureException(error, { tags: { errorMessage: error.message } })
    }

    throw error
  }

  if (!hasInitialized) {
    hasInitialized = true

    try {
      firebase.initializeApp(firebaseConfig)
      // only initialize analytics on prod builds
      if (firebaseConfig.measurementId) {
        firebase.analytics()
      }
    } catch (e) {
      errorHandler(e)
    }

    window.onerror = (evt, url, lineNumber) => console.error('window.onerror Uncaught error: ', evt, url, lineNumber)

    // For uncaught errors
    window.addEventListener('error', function (evt) {
      if (!evt || !evt.error) return console.log('Uncaught error non-existent: ', evt)

      console.error('--Uncaught error: ', evt.error)
      setShowError(true)
      setLastError(evt.error)
      LogRocket.captureException(evt.error)
    })
  }

  const db = firebase.firestore()
  const storage = firebase.storage()

  const add = (collection: string, payload: {}) => {
    return db
      .collection(collection)
      .add(payload)
      .catch(errorHandler(`Add to collection ${collection}`))
  }

  const get = async (collection: string) => {
    try {
      const res = await db
        .collection(collection)
        .get()
        .catch(errorHandler(`Get collection ${collection}`))
      const json: any = []

      res.forEach(doc => {
        json.push(doc.data())
      })
      return json
    } catch (err) {
      return errorHandler(err)
    }
  }

  const getInvitedSurveys = async (): Promise<IInvitedSurveys> => {
    const invitedSurveys: IInvitedSurveys = []
    let snapshot = await db
      .collectionGroup('surveySubmissions')
      .where('submissionUserId', '==', userInfo?.email)
      .orderBy('dateSent', 'desc')
      .get()
      .catch(errorHandler(`Get invited surveys by submissionId`))

    for (let i = 0; i < snapshot.docs.length; i++) {
      const doc = snapshot.docs[i]
      invitedSurveys.push({ survey: doc.data().survey, answerId: doc.id })
    }

    return invitedSurveys
  }

  const getSurveys = async (userId: string): Promise<ISurvey[]> => {
    const snapshot = db.collection('surveys').orderBy('dateCreated', 'desc')
    const role = await getUserType(userId)

    if (role === UserTypes.admin) {
      const data = await snapshot.get().catch(errorHandler(`Get all surveys ordered by dateCreated (admin)`))
      const surveys = data.docs.map(doc => ({ ...(doc.data() as ISurvey), id: doc.id }))

      return surveys
    }

    const data = await snapshot.where('owners', 'array-contains', userInfo?.uid).get().catch(errorHandler(`Get all surveys (owner)`))
    const surveys = data.docs.map(doc => ({ ...(doc.data() as ISurvey), id: doc.id }))

    return surveys
  }

  const getSurvey = async (surveyId: string): Promise<ISurvey | undefined> => {
    const snapshot = db.collection('surveys').doc(surveyId)
    const survey = await snapshot.get().catch(errorHandler(`Get a survey by SurveyId`))

    if (survey.exists) {
      return survey.data() as ISurvey
    }

    return undefined
  }

  const getAnswer = async (surveyId: string, answerId: string): Promise<ISurveySubmission | null> => {
    const doc = await db
      .collection(`surveys/${surveyId}/surveySubmissions`)
      .doc(answerId)
      .get()
      .catch(errorHandler(`Get a single survey answer`))
    let answer = null
    answer = doc.data() as ISurveySubmission
    return answer
  }

  const getSurveySubmissions = async (surveyId: string): Promise<ISurveySubmission[]> => {
    const snapshot = await db
      .collection(`surveys/${surveyId}/surveySubmissions`)
      .get()
      .catch(errorHandler(`Get all survey submissions of a survey`))

    const submissions: ISurveySubmission[] = snapshot.docs.map(submission => ({
      ...(submission.data() as ISurveySubmission),
      id: submission.id
    }))

    // console.log('getting the submissions: ', submissions)
    return submissions
  }

  const getSurveySubmissionsDict = async (surveyId: string): Promise<{ [answerId: string]: ISurveySubmission }> => {
    const snapshot = await db
      .collection(`surveys/${surveyId}/surveySubmissions`)
      .get()
      .catch(errorHandler(`Get all survey submissions of a survey (with ID)`))
    const submissions = snapshot.docs.reduce<{ [answerId: string]: ISurveySubmission }>((acc, doc) => {
      const docData = doc.data() as ISurveySubmission
      acc[doc.id] = docData
      return acc
    }, {})
    return submissions
  }

  const onSurveySubmission = async (surveyId: string, callback: (surveySubmissions: ISurveySubmission[]) => void) => {
    const unsubscribe = db
      .collection(`surveys/${surveyId}/surveySubmissions`)
      .where('survey.type', '==', SurveyTypes.public)
      .where('submissionUserId', '==', userInfo!.email)
      .onSnapshot(snapshot => {
        const docs = snapshot.docs.map(doc => ({ ...(doc.data() as ISurveySubmission), id: doc.id }))

        callback(docs)
      })

    return unsubscribe
  }

  // upload a file, and returns a download link when its complete
  // can add a progress update callback to get the percentage updated (0 to 1)
  function uploadFile(
    path: string,
    data: Blob | File,
    progressUpdateCb?: (progress: number) => void,
    metadata?: firebase.storage.UploadMetadata
  ) {
    return new Promise((resolve: (downloadUrl: string) => void, reject: (err: Error) => void) => {
      const fileRef = storage.ref(path)
      const task = fileRef.put(data, metadata)

      task.on(
        'state_changed',
        function onProgress(snapshot) {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes
          if (progressUpdateCb) progressUpdateCb(progress)
        },
        function onError(err) {
          return errorHandler(`Uploading a file`)(err)
        },
        async function onComplete() {
          const downloadUrl = await task.snapshot.ref.getDownloadURL()
          resolve(downloadUrl)
        }
      )
    })
  }

  const duplicateSurvey = (survey: ISurvey, userId: string) =>
    saveSurvey({
      ...survey,
      name: `Copy of ${survey.name}`,
      createdBy: userInfo!.displayName ? userInfo!.displayName : survey.createdBy,
      owners: [userId],
      id: '',
      dateSent: null,
      numResponses: 0
    })

  async function uploadSurveyLogo(imageFile: File, progressUpdateCb?: (progress: number) => void) {
    const storagePath = 'survey_logo/' + uuid() + '_' + path.basename(imageFile.name)
    const downloadLink = await uploadFile(storagePath, imageFile, progressUpdateCb)
    return downloadLink
  }

  const saveSurvey = async (survey: ISurvey) => {
    let saveResult
    if (!userInfo) {
      console.error('Unable to save survey without user info, likely logged before auto-saving completed')
      return survey
    }

    survey = Object.assign({}, survey)

    if (!survey.owners.length) survey.owners = [userInfo.uid]
    if (!survey.createdBy) survey.createdBy = userInfo.displayName || ''
    if (!survey.dateCreated) survey.dateCreated = timeSinceEpoch()

    if (survey.id) {
      await db.collection('surveys').doc(survey.id).set(survey).catch(errorHandler('Setting survey data'))
      return survey
    } else {
      //@ts-ignore
      delete survey.id
      survey.owners = [userInfo.uid]
      saveResult = await db.collection('surveys').add(survey).catch(errorHandler('Adding a new survey'))
      survey.id = saveResult.id
      return survey
    }
  }

  const initializeUserProfile = async (email: string) => {
    const snapshot = await db.collection('profiles').where('user', '==', email).get().catch(errorHandler(`Finding user profile`))
    if (snapshot.empty) {
      const profile: IProfile = {
        user: email,
        topics: [],
        initialized: false,
        dateCreated: Date.now(),
        dateUpdated: Date.now()
      }

      const doc = await db.collection('profiles').add(profile).catch(errorHandler('Create new user profile'))
      return doc.id
    }
    return snapshot.docs[0].id
  }

  const submitResponse = async (surveyId: string, answerId: string, responses: IUserResponse) => {
    return await saveAnswer(surveyId, answerId, responses, true)
    // const surveyRef = db.collection(`surveys`).doc(surveyId)
    // await surveyRef.update({ numResponses: firebase.firestore.FieldValue.increment(1) }).catch(errorHandler(`Increment numResponses`))
  }

  const getContacts = async () => {
    const contacts = await db.collection('contacts').doc(userInfo?.uid).get().catch(errorHandler(`Get contacts of an owner`))
    return contacts.data() as IContacts
  }

  const getAllContacts = async () => {
    const contacts = await db.collection('contacts').get().catch(errorHandler(`Get contacts of an admin`))
    let allContacts: IContacts = {}
    contacts.forEach(c => {
      const data = c.data() as IContacts
      allContacts = { ...allContacts, ...data }
    })
    return allContacts
  }

  const inviteEmails = async (invitations: ISurveyInvitation[], subject: string, message: string, survey: ISurvey) => {
    const invitationsObj = invitations.reduce<IContacts>((acc, val) => {
      const email = val.email.toLowerCase()
      if (val.name) {
        acc[email] = val
      } else if (email) {
        acc[email] = { email }
      }
      return acc
    }, {})

    await db
      .collection('contacts')
      .doc(userInfo?.uid)
      .set({ ...invitationsObj }, { merge: true })
      .catch(errorHandler('Set contact by uid'))

    const docRef = db.collection('surveys').doc(survey.id)

    if (!survey.dateSent) {
      await docRef.update({ dateSent: timeSinceEpoch() }).catch(errorHandler('Update survey dateSent'))
    }

    for (let i = 0; i < invitations.length; i++) {
      const email = invitations[i].email.toLowerCase()
      const _answerSurvey = createNewAnswerSurvey(survey)
      _answerSurvey.dateReceived = timeSinceEpoch()

      const profileId = await initializeUserProfile(email)
      const newSubmission: ISurveySubmission = { survey: _answerSurvey, userResponse: {}, submissionUserId: email, profileId }
      const doc = await docRef.collection('surveySubmissions').add(newSubmission).catch(errorHandler(`Add new survey submission`))

      const answerId = doc.id
      const url = `${window.location.origin}/#/survey/${survey.id}/${answerId}`

      add('mail', {
        to: email,
        from: `Proxy Match <survey@${window.location.host.indexOf('localhost') <= -1 ? window.location.host : 'proxymatch.io'}>`,
        message: {
          subject,
          html: surveyInvitationEmail({
            message,
            surveyLink: url
          })
          // text: message,
          // html: `
          //         ${message}
          //         <br/>
          //         <a href="${url}">Click here to visit the survey</a>
          //         <br/>
          //         <br/>
          //         If the link does not work, copy this url.
          //         <br/>
          //         ${url}
          //         <br/>
          //         <br/>
          //         <h3>The Proxy Match Team</h3>
          //         <p>This email address is not monitored - please do not reply directly.</p>
          //   `
        }
      })
    }

    await docRef
      .update({ numberInvited: firebase.firestore.FieldValue.increment(invitations.length) })
      .catch(errorHandler(`Increment survey numberInvited`))
    const data = (await docRef.get().catch(errorHandler(`Get survey submission after update`))).data() as ISurvey
    data.id = docRef.id
    return data
  }

  const deleteSurvey = (surveyId: string) => {
    return db.collection('surveys').doc(surveyId).delete().catch(errorHandler(`Delete survey from collection`))
  }

  const saveAnswer = async (surveyId: string, answerId: string, response: IUserResponse, isComplete?: boolean) => {
    const doc = await db
      .collection(`surveys/${surveyId}/surveySubmissions`)
      .doc(answerId)
      .get()
      .catch(errorHandler(`get Survey answer by id`))
    const docData = doc.data() as ISurveySubmission
    if (!docData) return

    const updateDoc: Partial<ISurveySubmission> = {
      userResponse: response,
      survey: {
        ...docData.survey,
        isStarted: true,
        dateStarted: docData.survey.dateStarted || timeSinceEpoch()
      }
    }
    if (isComplete) {
      updateDoc.survey!.isComplete = true
      updateDoc.survey!.dateSubmitted = timeSinceEpoch()
    }

    await doc.ref.update(updateDoc).catch(errorHandler(`update survey answer`))
  }

  const onUpdateManagedSurveys = async (userId: string, callback: (data: ISurvey[]) => void) => {
    const snapshot = db.collection(`surveys`).orderBy('dateCreated', 'desc')
    const role = await getUserType(userId)

    if (role === UserTypes.admin) {
      const unsubscribe = snapshot.onSnapshot(querySnapshot => {
        const surveys: ISurvey[] = querySnapshot.docs.map(doc => ({ ...(doc.data() as ISurvey), id: doc.id }))

        callback(surveys)
      }, errorHandler(`unsubscribe from surveys list`))

      return unsubscribe
    } else {
      const unsubscribe = snapshot.where('owners', 'array-contains', userInfo?.uid).onSnapshot(querySnapshot => {
        const surveys: ISurvey[] = querySnapshot.docs.map(doc => ({ ...(doc.data() as ISurvey), id: doc.id }))

        callback(surveys)
      }, errorHandler('subscribe to surveys list'))

      return unsubscribe
    }
  }

  const onUpdateInvitedSurveys = (callback: (data: IInvitedSurveys) => void) => {
    const unsubscribe = db
      .collectionGroup('surveySubmissions')
      .where('submissionUserId', '==', userInfo?.email)
      .onSnapshot(querySnapshot => {
        const invitedSurveys: IInvitedSurveys = []
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const doc = querySnapshot.docs[i]
          invitedSurveys.push({ survey: doc.data().survey, answerId: doc.id })
        }

        callback(invitedSurveys)
      }, errorHandler(`subscribe to surveySubmissions list`))

    return unsubscribe
  }

  const getAllOwners = async (): Promise<string[]> => {
    const permissionsSnapshot = await db.collection('permissions').get().catch(errorHandler(`get all owners`))
    return permissionsSnapshot.docs.map(doc => doc.data().email)
  }

  const updateOwner = async (surveyId: string, uid: string) => {
    const surveyRef = await db.collection('surveys').doc(surveyId)
    const surveyDoc = await surveyRef.get()

    if (surveyDoc.exists) {
      await surveyRef
        .update({
          owners: [uid]
        })
        .catch(errorHandler('update owner'))
    }
  }

  const getUserIdFromPermissions = async (email: string): Promise<string | null> => {
    const snapshot = await db.collection('permissions').get()

    const doc = snapshot.docs.find(doc => doc.data().email === email)

    if (doc?.exists) {
      return doc.id
    }
    return null
  }

  const getUserEmailFromPermissions = async (userId: string): Promise<string | null> => {
    const doc = await db.collection('permissions').doc(userId).get().catch(errorHandler(`get user's permission`))

    if (doc.exists) {
      const data = doc.data() as { email: string }

      return data.email
    }

    return null
  }

  const getUserType = async (userId: string) => {
    const doc = await db.collection('permissions').doc(userId).get().catch(errorHandler(`get user's permission`))

    if (doc.exists) {
      const data = doc.data() as { role: UserTypes }

      return data!.role || UserTypes.owner
    }

    return UserTypes.respondent
  }

  const getTopics = async (): Promise<ITopic[]> => {
    let snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    snapshot = await db.collection('topics').get().catch(errorHandler('get topics'))
    return snapshot.docs.map(doc => ({ ...(doc.data() as ITopic), id: doc.id }))
  }

  const addTopic = async (userId?: string): Promise<ITopic | null> => {
    const snapshot = await db
      .collection('topics')
      .add({ name: '', owner: userInfo?.uid || null })
      .catch(errorHandler('add new topic'))
    const topic = await snapshot.get().catch(errorHandler('get topic just added'))

    return { ...(topic.data() as ITopic), id: topic.id }
  }

  const deleteTopic = async (topicId: string): Promise<void> => {
    return await db.collection('topics').doc(topicId).delete().catch(errorHandler('delete topic'))
  }

  const updateTopic = async (topicId: string, name: string): Promise<void> => {
    return await db.collection('topics').doc(topicId).update({ name }).catch(errorHandler('update topic'))
  }

  const reconnect = async () => {
    await db.disableNetwork()
    await db.enableNetwork()
  }

  const getProfile = async (profileId: string) => {
    return (await db.collection('profiles').doc(profileId).get().catch(errorHandler('Get a profile by id'))).data() as IProfile
  }

  const updateProfileName = async (profileId: string, name: string) => {
    return db.collection('profiles').doc(profileId).update({ name }).catch(errorHandler('Update profile'))
  }

  const updateProfile = async (profileId: string, topicIds: string[]) => {
    const oldProfile = await getProfile(profileId)

    const oldTopicsById = oldProfile.topics?.reduce<{ [id: string]: IUserTopic }>((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {})

    const profile: Partial<IProfile> = {
      topics: topicIds.map(id => ({
        id,
        dateUpdated: oldTopicsById[id] ? oldTopicsById[id].dateUpdated : Date.now()
      })),
      initialized: true,
      dateUpdated: Date.now()
    }
    await db.collection('profiles').doc(profileId).update(profile).catch(errorHandler('Update profile'))
  }

  useEffect(() => {
    // An attempt to remove garbage collection to help remove the iOS error.
    firebase.firestore().settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    })

    async function enableOfflineMode() {
      try {
        await firebase.firestore().enablePersistence({
          synchronizeTabs: true
        })
      } catch (e) {
        console.error('Failed to enable firestore persistence', e)
      }
    }

    enableOfflineMode()
  }, [])

  const getUserEmail = async (uid: string) => {
    try {
      const callResult = await firebase.functions().httpsCallable('getUserEmail')({ uid }).catch(errorHandler('Get user email'))

      if (callResult.data.status === 'error') {
        errorHandler(callResult.data.message)
        return null
      }

      return callResult.data.email as string
    } catch (e) {
      errorHandler(e)
    }

    return null
  }

  const checkPublicSurveys = () => {
    return firebase.functions().httpsCallable('addPublicSurveys')().catch(errorHandler('Check public survey'))
  }

  const getSurveyAnalytics = async (surveyId: string) => {
    const analyticsDoc = await db.collection('analytics').doc(surveyId).get()
    return analyticsDoc.data() as IAnalytics | undefined
  }

  const getSurveyComments = async (surveyId: string) => {
    const commentsDoc = await db.collection('surveyComments').doc(surveyId).get()
    return commentsDoc.data() as ISurveyComments | undefined
  }

  const updateUserEmail = async (newEmail: string, password: string) => {
    return new Promise<void>(resolve => {
      if (!userInfo || !userInfo.email) return

      firebase
        .auth()
        .signInWithEmailAndPassword(userInfo.email, password)
        .then(userCredential => {
          userCredential.user?.updateEmail(newEmail)
          resolve()
        })
    })
  }

  const reauthenticate = (password: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!userInfo || !userInfo.email) return reject('invalid userInfo')

      const credential = firebase.auth.EmailAuthProvider.credential(userInfo.email, password)

      userInfo
        .reauthenticateWithCredential(credential)
        .then(function () {
          resolve()
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  const updatePassword = (currentPassword: string, newPassword: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await reauthenticate(currentPassword)
        await userInfo?.updatePassword(newPassword)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  const state: IFirebaseContext = {
    isInitialized: true,
    lastError,
    showError,
    getSurveyAnalytics,
    getSurveyComments,
    setShowError,
    add,
    get,
    saveSurvey,
    duplicateSurvey,
    getSurveys,
    getSurvey,
    submitResponse,
    inviteEmails,
    saveAnswer,
    getAnswer,
    getInvitedSurveys,
    deleteSurvey,
    uploadFile,
    uploadSurveyLogo,
    getSurveySubmissions,
    getSurveySubmissionsDict,
    onSurveySubmission,
    onUpdateManagedSurveys,
    onUpdateInvitedSurveys,
    getUserType,
    getTopics,
    addTopic,
    deleteTopic,
    updateTopic,
    reconnect,
    checkPublicSurveys,
    getUserEmail,
    getUserEmailFromPermissions,
    getUserIdFromPermissions,
    getAllOwners,
    updateOwner,
    getContacts,
    getAllContacts,
    getProfile,
    updateProfile,
    updateProfileName,
    initializeUserProfile,
    updateUserEmail,
    reauthenticate,
    updatePassword
  }

  return <FirebaseContext.Provider value={state}>{props.children}</FirebaseContext.Provider>
}

export interface IWithFirebaseContextProps {
  firebaseFunctions: IFirebaseContext
}

export function withFirebaseContext<P extends IWithFirebaseContextProps>(Child: React.ComponentType<P>) {
  return function WithFirebaseFunctions(props: Subtract<P, IWithFirebaseContextProps>) {
    return <FirebaseContext.Consumer>{functions => <Child {...(props as P)} firebaseFunctions={functions} />}</FirebaseContext.Consumer>
  }
}

export default FirebaseProvider
