import './css/SentSurvey.css'

import { Toggle } from '@fluentui/react'
import { Button } from 'components/Button'
import { OwnerEdit } from 'components/OwnerEdit'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { writeToClipboard } from 'utils'

import { DashboardHeader } from '../components/DashboardHeader'
import { AlertType, AppStateContext } from '../contexts/appState'
import { ISurvey, ISurveyInvitation, SurveyTypes } from '../types'
import { QuestionCreation } from './CreateSurvey/QuestionCreation'

export type ISentSurveyProps = {
  survey: ISurvey
  submit: (to: ISurveyInvitation[], subject: string, message: string, file?: File) => void
}

export const SentSurvey: React.FunctionComponent<ISentSurveyProps> = props => {
  const { survey } = props

  const history = useHistory()
  const { addAlert, dashboardEvents } = useContext(AppStateContext)

  const [, setIsPrivate] = useState(true)
  const [questionsCollapsed, setQuestionsCollapsed] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    function onPublish() {
      history.push(`/dashboard/owner/publish/${survey.id}`)
    }

    dashboardEvents.on('publish', onPublish)

    return () => {
      dashboardEvents.off('publish', onPublish)
    }
  }, [survey.id])

  const handleCopyLinkClick = () => {
    writeToClipboard(`${window.location.protocol}//${window.location.host}/#/survey/${survey.id}`)
    addAlert('Link to survey was copied to clipboard.', AlertType.notification, 1000)
  }

  const handlePrivateToggle = (_: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
    setIsPrivate(!checked || false)
  }

  const numCollapsed = Object.values(questionsCollapsed).reduce((count, val) => count + (val ? 1 : 0), 0)
  const setCollapsed = useCallback(
    (id: string, isCollapsed: boolean) => {
      if (questionsCollapsed[id] !== isCollapsed) {
        questionsCollapsed[id] = isCollapsed
      }
      setQuestionsCollapsed(Object.assign({}, questionsCollapsed))
    },
    [questionsCollapsed]
  )

  const collapseAll = useCallback(() => {
    survey.questions.forEach(q => {
      questionsCollapsed[q.id] = true
    })
    setQuestionsCollapsed(Object.assign({}, questionsCollapsed))
  }, [survey, questionsCollapsed])

  const viewAll = useCallback(() => {
    for (const id in questionsCollapsed) questionsCollapsed[id] = false
    setQuestionsCollapsed(Object.assign({}, questionsCollapsed))
  }, [questionsCollapsed])

  // check if the topic is collapsed
  const isTopicCollapsed = (topic: string) => {
    const questions = survey.questions
    if (!questions) throw Error('Checking non existing topic!')

    let isCollapsed = true
    for (const q of questions) {
      if (!questionsCollapsed[q.id]) {
        isCollapsed = false
        break
      }
    }
    if (questions.length === 0) isCollapsed = false
    return isCollapsed
  }

  // pre calculate everything
  const topicsCollapsed: { [topic: string]: boolean } = {}
  for (const t of survey.topics) {
    topicsCollapsed[t] = isTopicCollapsed(t)
  }

  return (
    <div className="create-survey-container">
      <DashboardHeader
        editable
        toggleable
        title={survey.name}
        description={survey.description}
        stats={[{ key: 'Questions', value: survey.questions.length }]}
        toggleLabel="Private"
        toggleValue={survey.type === SurveyTypes.private}
        onToggle={handlePrivateToggle}>
        {survey.type === SurveyTypes.public && (
          <Button secondary onClick={handleCopyLinkClick}>
            Copy Link
          </Button>
        )}
        <OwnerEdit survey={survey} />
        <div className="create-survey-banner-collapse">
          <div style={{ width: '5.5rem', cursor: 'pointer', marginRight: '6px' }} onClick={numCollapsed > 0 ? viewAll : collapseAll}>
            {numCollapsed === survey.questions.length ? 'Expand All' : 'Collapse All'}
          </div>
          <Toggle
            checked={!(numCollapsed === survey.questions.length)}
            onChange={numCollapsed > 0 ? viewAll : collapseAll}
            styles={{ root: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
          />
        </div>
      </DashboardHeader>

      <div className="questions-and-topics">
        <div className="container">
          <div style={{ marginTop: 32, marginBottom: 32 }}></div>
          {/* questions */}
          {survey.questions.map((question, index) => {
            return (
              <QuestionCreation
                isDisabled={true}
                key={question.id}
                question={question}
                index={index}
                isCollapsed={!!questionsCollapsed[question.id]}
                setCollapsed={setCollapsed}
                onBlur={() => {}}
                onChangeValue={() => {}}
                onChangeQuestionResponse={() => {}}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
