import { pvmTemplate } from './pvmTemplate'

interface ISurveyInvitationEmailProps {
  message?: string
  surveyLink: string
}

export const surveyInvitationEmail = ({ message, surveyLink }: ISurveyInvitationEmailProps) => {
  return pvmTemplate({
    title: 'Proxy Value Match Survey invitation',
    showLinkButton: true,
    linkText: 'Visit the survey',
    link: surveyLink,
    bodyHtmlText: `
      <div class="text-large text-bold">${message}</div>
      <div style="height:24px;"></div>
    `,
    bottomHtmlText: `
      <div style="height:24px;"></div>
      <div>If this link does not work, copy this url.</div>
      <div class="text-small">${surveyLink}</div>

      <div style="height:12px;"></div>
      <div class="text-large text-bold">The Proxy Match Team</div>
      <div>This email address is not monitored - please do not reply directly.</div>
    `
  })
}
