import React, { useContext } from 'react'
import { match, RouteComponentProps, Switch, useHistory, useParams, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'

import BackButton from '../../components/BackButton'
import { AppStateContext, IAppState } from '../../contexts/appState'
import { DataCacheContext } from '../../contexts/dataCacheContext'
import { IQuestion, QuestionTypes, UserTypes } from '../../types'
import DashboardLink, { DashboardLinkItem } from './DashboardLinks'

export const getDashboardHomeLinks = (appState: IAppState, userType: UserTypes): DashboardLinkItem[] => {
  const links = [
    {
      key: 'createNewSurvey',
      text: 'Create New Survey',
      iconName: 'Add',
      onClick: () => appState.dashboardEvents.trigger('createSurvey')
    },

    {
      key: '/dashboard/owner',
      text: 'Created Surveys',
      path: '/dashboard/owner',
      iconName: 'Surveys'
    },
    {
      key: '/dashboard/contacts',
      text: 'Contacts',
      path: '/dashboard/contacts',
      iconName: 'Contacts'
    }
  ]

  if (userType === UserTypes.admin) {
    return [
      ...links.slice(0, 1),
      {
        key: '/dashboard/topics',
        text: 'Topics',
        path: '/dashboard/topics',
        iconName: 'Topics'
      },
      ...links.slice(1, links.length)
    ]
  }

  return links
}

export const getCreateSurveyLinks = (appState: IAppState, match: { params: { surveyId: string } }): DashboardLinkItem[] => {
  return [
    {
      key: 'addQuestion',
      text: 'Add Question',
      iconName: 'Add',
      onClick: () => appState.dashboardEvents.trigger('addQuestion')
    },
    {
      key: 'preview',
      text: 'Preview',
      iconName: 'Preview',
      onClick: () => window.open(`http://${window.location.host}/#/dashboard/owner/survey-preview/${match.params.surveyId}`)
      // path: `/dashboard/owner/survey-preview/${match.params.surveyId}`
    },
    {
      key: 'publish',
      text: 'Publish',
      iconName: 'Publish',
      onClick: () => appState.dashboardEvents.trigger('publish')
    }
  ]
}

const getSentSurveyLinks = (appState: IAppState, match: { params: { surveyId: string } }, openEndedQuestions?: IQuestion[]) => [
  {
    key: 'preview',
    text: 'Preview',
    iconName: 'Preview',
    // onClick: () => appState.dashboardEvents.trigger('preview')
    onClick: () => window.open(`http://${window.location.host}/#/dashboard/owner/survey-preview/${match.params.surveyId}`)
  },
  {
    key: 'publish',
    text: 'Publish',
    iconName: 'Publish',
    path: `/dashboard/owner/publish/${match.params.surveyId}`
  },
  {
    key: 'surveyContacts',
    text: 'Survey Contacts',
    iconName: 'Contacts',
    path: `/dashboard/owner/contacts/${match.params.surveyId}`
  },
  // go to analyze page
  {
    key: 'analyze',
    text: 'Analyze',
    iconName: 'Analyze',
    path: `/dashboard/owner/analyze-survey/${match.params.surveyId}`
  },
  ...(openEndedQuestions && openEndedQuestions.length
    ? [{ key: 'comments', text: 'Comments', iconName: 'Comment', path: `/dashboard/owner/survey-comments/${match.params.surveyId}` }]
    : [])
]

interface ISurveySentViewMenuFactoryProps {
  selectedItemKey?: string
  getBackPath?: (surveyId: string) => string
}

const SurveySentViewMenu: (factoryProps: ISurveySentViewMenuFactoryProps) => React.FC<RouteComponentProps<any>> = ({
  selectedItemKey,
  getBackPath
}) => {
  return function _SurveySentViewMenu(props) {
    const { match } = props
    const appState = useContext(AppStateContext)
    const { managedSurveys } = useContext(DataCacheContext)
    // const { getSurveySubmissionsDict } = useContext(FirebaseContext)
    const history = useHistory()
    const { surveyId } = useParams<{ surveyId: string }>()
    const survey = managedSurveys[surveyId]
    const openEndedQuestions = survey?.questions.filter(q => q.type === QuestionTypes.openEnded)
    // const [hasAnswer, setHasAnswer] = useState(false)

    // check if the survey has any answer to open ended questions
    // useEffect(() => {
    //   const exec = async () => {
    //     const answers = await getSurveySubmissionsDict(surveyId)
    //     let _hasAnswer = false
    //     for (const q of openEndedQuestions) {
    //       for (const key in answers) {
    //         const a = answers[key]
    //         if (!!a.userResponse[q.id]?.answers[q.choices[0].id]?.value) {
    //           _hasAnswer = true
    //           break
    //         }
    //       }
    //       if (_hasAnswer) break
    //     }

    //     setHasAnswer(_hasAnswer)
    //   }
    //   exec()
    // }, [surveyId])

    return (
      <>
        {getBackPath && <BackButton onClickBack={() => history.push(getBackPath(surveyId))} />}
        <DashboardLink items={getSentSurveyLinks(appState, match, openEndedQuestions)} selectedItemKey={selectedItemKey} />
      </>
    )
  }
}

const SurveyView = () => {
  const match = useRouteMatch<{ surveyId: string }>()
  const history = useHistory()
  const appState = useContext(AppStateContext)

  return (
    <>
      <BackButton onClickBack={() => history.push('/dashboard/owner')} />
      <DashboardLink items={getCreateSurveyLinks(appState, match)} />
    </>
  )
}

const SurveyPreviewMenu = () => {
  const match = useRouteMatch<{ surveyId: string }>()
  const history = useHistory()

  return (
    <>
      <BackButton onClickBack={() => history.push('/dashboard/owner/survey-view/' + match.params.surveyId)} />
    </>
  )
}

const SurveySentViewMenuMain = SurveySentViewMenu({
  getBackPath: () => '/dashboard/owner/'
})

const SurveySentViewMenuContacts = SurveySentViewMenu({
  getBackPath: surveyId => `/dashboard/owner/survey-sent-view/${surveyId}`,
  selectedItemKey: 'surveyContacts'
})

const SurveySentViewMenuComments = SurveySentViewMenu({
  getBackPath: surveyId => `/dashboard/owner/survey-sent-view/${surveyId}`,
  selectedItemKey: 'comments'
})

const SurveyViewMenuPublish = () => {
  const appState = useContext(AppStateContext)
  const { managedSurveys } = useContext(DataCacheContext)
  const { surveyId } = useParams<{ surveyId: string }>()
  const history = useHistory()
  const match = useRouteMatch<{ surveyId: string }>()
  const survey = managedSurveys[surveyId]
  const openEndedQuestions = survey.questions.filter(q => q.type === QuestionTypes.openEnded)

  if (survey.dateSent) {
    return (
      <>
        <BackButton onClickBack={() => history.push(`/dashboard/owner/survey-sent-view/${surveyId}`)} />
        <DashboardLink items={getSentSurveyLinks(appState, match, openEndedQuestions)} selectedItemKey="publish" />
      </>
    )
  }

  return (
    <>
      <BackButton onClickBack={() => history.push(`/dashboard/owner/survey-view/${surveyId}`)} />
      <DashboardLink items={getCreateSurveyLinks(appState, match)} selectedItemKey="publish" />
    </>
  )
}

const SurveySentViewMenuAnalyze = SurveySentViewMenu({
  getBackPath: surveyId => `/dashboard/owner/survey-sent-view/${surveyId}`,
  selectedItemKey: 'analyze'
})

interface IDashboardMenuProps {
  match: match<any>
}

const DashboardMenu = (props: IDashboardMenuProps) => {
  const { match } = props
  const appState = useContext(AppStateContext)
  const { userType } = useContext(DataCacheContext)

  return (
    <Switch>
      <Route exact path={match.path + '/owner/survey-view/:surveyId'} component={SurveyView} />

      <Route exact path={match.path + '/owner/publish/:surveyId'} component={SurveyViewMenuPublish} />

      <Route exact path={match.path + '/owner/survey-sent-view/:surveyId'} component={SurveySentViewMenuMain} />

      <Route exact path={match.path + '/owner/contacts/:surveyId'} component={SurveySentViewMenuContacts} />

      <Route exact path={match.path + '/owner/analyze-survey/:surveyId'} component={SurveySentViewMenuAnalyze} />

      <Route exact path={match.path + '/owner/survey-comments/:surveyId'} component={SurveySentViewMenuComments} />

      <Route exact path={match.path + '/owner/survey-preview/:surveyId'} component={SurveyPreviewMenu} />

      <Route path={match.path}>
        <DashboardLink items={getDashboardHomeLinks(appState, userType)} />
      </Route>
    </Switch>
  )
}

export default DashboardMenu
