export const chartPrimaryColorMap = [
  {
    backgroundColor: '#8ae6b0',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#22c960',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#27573b',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#5da2f0',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#3069d8',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#273d57',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#00ced1',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#007c7d',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#003e3f',
    labelColor: 'var(--white)'
  },
  {
    backgroundColor: '#23b978',
    labelColor: 'var(--white)'
  }
]

export const chartNaColor = {
  backgroundColor: '#b7b8b8',
  labelColor: 'var(--white)'
}

export const primaryBackgroundToLabelMap = [...chartPrimaryColorMap, chartNaColor].reduce<{ [bg: string]: string }>((agg, current) => {
  agg[current.backgroundColor] = current.labelColor
  return agg
}, {})

export const chartSecondaryColorMap: {
  [key: string]: {
    backgroundColor: string
    labelColor: string
  }
} = {
  0: {
    backgroundColor: '#d4c6f9',
    labelColor: 'var(--white)'
  },
  1: {
    backgroundColor: '#bba6f2',
    labelColor: 'var(--white)'
  },
  2: {
    backgroundColor: '#a286ec',
    labelColor: 'var(--white)'
  },
  3: {
    backgroundColor: '#8867e5',
    labelColor: 'var(--white)'
  },
  4: {
    backgroundColor: '#6f47df',
    labelColor: 'var(--white)'
  },
  5: {
    backgroundColor: '#5627d8',
    labelColor: 'var(--white)'
  },
  6: {
    backgroundColor: '#4c22be',
    labelColor: 'var(--white)'
  },
  7: {
    backgroundColor: '#421ea4',
    labelColor: 'var(--white)'
  },
  8: {
    backgroundColor: '#38198a',
    labelColor: 'var(--white)'
  },
  9: {
    backgroundColor: '#2e1570',
    labelColor: 'var(--white)'
  },
  10: {
    backgroundColor: '#241056',
    labelColor: 'var(--white)'
  }
}
