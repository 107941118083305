import './css/surveyPreview.css'
import './css/surveyRespondentView.css'

import { ChoiceGroup, IChoiceGroupOption, TextField } from '@fluentui/react'
import { CheckboxAnimated } from 'components/CheckboxAnimated'
import { DottedSlider } from 'components/DottedSlider'
import SurveyImportantTray from 'components/SurveyImportantTray'
import { ContentState, convertToRaw } from 'draft-js'
import React from 'react'
import { IAllAnswers, IChoice, IQuestion, QuestionTypes } from 'types'

type IQuestionRespondentViewProps = {
  question: IQuestion
  onCheck: (responseId: string) => void
  userQuestionResponses: IAllAnswers
  onChangeOpenEnded: (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlurText: (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  isDisabled?: boolean
  showMissed?: boolean
  index: number
  importance?: number | null
  onChangeImportance: (value: number) => void
}

export const QuestionRespondentView: React.FunctionComponent<IQuestionRespondentViewProps> = props => {
  const { showMissed, question, userQuestionResponses, onChangeOpenEnded, onBlurText, isDisabled, onCheck } = props
  const { type, value, infoTitle, info } = question

  const responsesById: { [key: string]: IChoice } = {}
  const responseIndices: { [key: string]: number } = {}

  question.choices.forEach((r, idx) => {
    responsesById[r.id] = r
    responseIndices[r.id] = idx
  })

  const renderResponses = () => {
    // handle text response
    if (type === QuestionTypes.openEnded) {
      let textValue = ''

      if (userQuestionResponses) {
        textValue = Object.values(userQuestionResponses)[0].value as string
      }

      return (
        <div className="question-respondentview-textfield">
          <TextField
            data-testid="respondent-view-input"
            disabled={isDisabled}
            onBlur={onBlurText}
            value={textValue}
            styles={textFieldStyles}
            multiline={true}
            rows={4}
            onChange={onChangeOpenEnded}
          />
        </div>
      )
    }

    if (question.type === QuestionTypes.trueFalse) {
      const choiceGroupOptions: IChoiceGroupOption[] = question.choices.map((resp, index) => {
        const userResp = userQuestionResponses[resp.id]
        return {
          index,
          key: resp.id,
          text: question.choices[index].value,
          checked: !!userResp.value,
          onRenderField: (props, render) => <div>{render!(props)}</div>,
          onChange: () => onCheck(resp.id)
        }
      })
      return <ChoiceGroup options={choiceGroupOptions} disabled={isDisabled} />
    }

    if (question.type === QuestionTypes.multipleChoice) {
      const choiceGroupOptions: IChoiceGroupOption[] = question.choices.map((resp, index) => {
        const userResp = userQuestionResponses[resp.id]
        return {
          index,
          key: resp.id,
          text: question.choices[index].value,
          checked: !!userResp.value,
          onRenderField: (props, render) => <div>{render!(props)}</div>,
          onChange: () => onCheck(resp.id)
        }
      })
      return <ChoiceGroup options={choiceGroupOptions} disabled={isDisabled} />
    }

    return Object.values(question.choices).map((r, index) => {
      const userResponse = userQuestionResponses[r.id]
      let checked = false
      if (!!userResponse.value) {
        checked = true
      }
      return (
        <div key={value + '_' + r + '_' + index} className="question-respondentview-checkbox">
          {/* <Checkbox disabled={isDisabled} checked={checked} onChange={() => onCheck(r.id)} label={responsesById[r.id].value} /> */}
          <CheckboxAnimated disabled={isDisabled} checked={checked} onChange={() => onCheck(r.id)} label={responsesById[r.id].value} />
        </div>
      )
    })
  }

  let isAnswered = false
  for (const r of Object.values(userQuestionResponses)) {
    if (r) {
      isAnswered = true
      break
    }
  }

  const showWarning = showMissed && question.required && !isAnswered
  const rawInfoLength = info
    ? convertToRaw(ContentState.createFromText(info))
        .blocks.map(block => (!block.text.trim() && '\n') || block.text)
        .join('\n').length
    : 0

  return (
    <div className="question-respondentview-question-body" style={{ border: showWarning ? '2px solid red' : undefined }}>
      <div className="question-respondentview-question-title">
        {/* {question.required && <div style={{ color: 'red', userSelect: 'none' }}>*</div>} */}

        <div style={{ display: 'flex' }}>
          {!question.required && <div style={{ marginRight: 5 }}>(Optional)</div>} {value}
        </div>
        {question.type === QuestionTypes.checkboxes ? (
          <div className="question-respondentview-question-title-info">You may check all answers that apply.</div>
        ) : null}
      </div>
      {rawInfoLength > 0 && <SurveyImportantTray infoTitle={infoTitle} info={info} />}

      <div className="question-respondentview-responses">{renderResponses()}</div>
      {question.hasImportantScale && (
        <div className={'question-respondentview-scale'}>
          <ImportantSlider
            onChangeImportance={props.onChangeImportance}
            importance={props.importance || undefined}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </div>
  )
}

const textFieldStyles = {
  root: {
    width: '100%'
  },
  field: {
    color: 'var(--bodyText)'
  }
}

interface IImportantSliderProps {
  isDisabled?: boolean
  importance?: number
  onChangeImportance: (importance: number) => void
}

const ImportantSlider = ({ isDisabled, importance, onChangeImportance }: IImportantSliderProps) => (
  <div className="importance-slider-container">
    <div className="important-scale-label">
      <div>From a scale of 0-10, how important is this to you?</div>
    </div>
    <DottedSlider isDisabled={isDisabled} value={importance} onChange={onChangeImportance} min={0} max={10} numSections={10} />
    <div className="important-scale-bottom-label">
      <div>Not At All</div>
      <div>Extremely</div>
    </div>
  </div>
)
