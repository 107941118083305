import React from 'react'
import shortid from 'shortid'

import { Subtract } from '../utils'
import EventInterface from '../utils/EventInterface'

export type IViewMode = 'list' | 'thumb'

export enum AlertType {
  notification,
  warning,
  error
}

export interface IAlert {
  id: string
  message: string
  type: AlertType
  startTime: Date
  shouldFade: boolean
}

export interface IAppState {
  update: (props: Partial<IAppState>) => void
  alerts: IAlert[]
  headerSearch: string
  viewMode: IViewMode | string
  setViewMode: (s: IViewMode) => void
  addAlert: (message: string, alertType: AlertType, timeout?: number) => string
  removeAlert: (alertId: string) => boolean
  fadeOutAlert: (alertId: string) => boolean
  dashboardEvents: EventInterface
}

const defaultAppState: IAppState = {
  update: () => {},
  alerts: [],
  headerSearch: '',
  viewMode: localStorage.getItem('viewMode') || 'thumb',
  setViewMode: () => {},
  addAlert: () => '',
  removeAlert: () => false,
  fadeOutAlert: () => false,
  dashboardEvents: new EventInterface()
}

const AppStateContext = React.createContext<IAppState>(defaultAppState)

export interface IAppStateProviderProps {
  children: React.ReactNode
}

class AppStateProvider extends React.Component<IAppStateProviderProps, IAppState> {
  constructor(props: IAppStateProviderProps) {
    super(props)
    this.state = {
      ...defaultAppState,
      update: this.update,
      setViewMode: this.setViewMode,
      addAlert: this.addAlert,
      removeAlert: this.removeAlert,
      fadeOutAlert: this.fadeOutAlert
    }
  }

  update = (props: Partial<IAppState>) => {
    const p: any = props
    this.setState(p)
  }

  setViewMode = (viewMode: IViewMode) => {
    this.setState({ viewMode })
    localStorage.setItem('viewMode', viewMode)
  }

  addAlert = (message: string, alertType: AlertType, timeout: number = 5000) => {
    const newAlerts = Array.from(this.state.alerts)
    const newAlert = {
      id: shortid(),
      message,
      type: alertType,
      startTime: new Date(),
      shouldFade: false
    }
    newAlerts.push(newAlert)

    this.setState({
      alerts: newAlerts
    })

    if (timeout) {
      setTimeout(this.fadeOutAlert, timeout, newAlert.id)
    }

    return newAlert.id
  }

  fadeOutAlert = (alertId: string) => {
    const idx = this.state.alerts.findIndex(item => item.id === alertId)
    if (idx === -1) return false
    const newAlert = this.state.alerts[idx]

    newAlert.shouldFade = true
    this.setState({
      alerts: Array.from(this.state.alerts)
    })
    return true
  }

  removeAlert = (alertId: string) => {
    const idx = this.state.alerts.findIndex(item => item.id === alertId)
    if (idx === -1) return false

    const newAlerts = Array.from(this.state.alerts)
    newAlerts.splice(idx, 1)
    this.setState({
      alerts: newAlerts
    })
    return true
  }

  render() {
    return <AppStateContext.Provider value={this.state}>{this.props.children}</AppStateContext.Provider>
  }
}

export interface IWithAppStateContextProps {
  appState: IAppState
}

export function withAppStateContext<P extends IWithAppStateContextProps>(Child: React.ComponentType<P>) {
  return function WithAuth(props: Subtract<P, IWithAppStateContextProps>) {
    return <AppStateContext.Consumer>{appState => <Child {...(props as P)} appState={appState} />}</AppStateContext.Consumer>
  }
}

export { AppStateContext, AppStateProvider }
