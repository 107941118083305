import '../containers/css/registration.css'

import React, { useEffect, useState } from 'react'

import { ButtonForm, EmailLoginForm } from '../containers'
import { signInWithEmail, userPasswordReset } from '../contexts/authContext'

export interface ILoginFormProps {}

const strings = {
  login: 'Login'
}

const buttonText = {
  facebookButtonText: 'Login with Facebook',
  microsoftButtonText: 'Login with Microsoft',
  googleButtonText: 'Login with Google',
  emailButtonText: 'Login with Email',
  createAccountButtonText: 'CREATE ACCOUNT'
}

export const LoginForm: React.FunctionComponent<ILoginFormProps> = () => {
  const [loginEmailForm, setLoginEmailForm] = useState(false)
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const [passwordResetView, setPasswordResetView] = useState(false)
  const [emailResetSentSuccess, setEmailResetSentSuccess] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [loginError, setLoginError] = useState('')
  const [passwordResetMessage, setPasswordResetMessage] = useState('')
  const [passwordResetError, setPasswordResetError] = useState('')

  useEffect(() => {
    setShowEmailLogin(!showEmailLogin)
  }, [loginEmailForm])

  const emailFormClick = () => {
    if (loginEmailForm) setLoginEmailForm(false)
    else setLoginEmailForm(true)
  }

  const onPasswordResetClick = () => {
    setEmailValue('')
    setPasswordValue('')
    setEmailResetSentSuccess(false)
    setPasswordResetMessage('')
    setPasswordResetView(!passwordResetView)
  }

  const onEmailLoginFormSubmit = async (email: string, password: string) => {
    setLoginError('')

    await signInWithEmail(email, password)
      .then(user => {
        if (!user) {
          setLoginError('auth/user-null')
        } else if (user === 'email not verified') {
          setLoginError('auth/user-not-verified')
        }
      })
      .catch(error => {
        setLoginError(error.code)
      })
  }

  const onEmailResetFormSubmit = async (email: string) => {
    setPasswordResetError('')

    await userPasswordReset(email)
      .then(_ => {
        setEmailResetSentSuccess(true)
        setPasswordResetMessage(`A link has been sent to ${email} to reset your password`)
      })
      .catch(error => {
        setEmailResetSentSuccess(true)
        setPasswordResetError(`The address you provided ${email} could not be found`)
        console.log('email reset error', error)
      })
  }

  const onLoginFormChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    const target = e.target as HTMLTextAreaElement
    switch (target.id) {
      case 'email': {
        setEmailValue(value!)
        break
      }
      case 'password': {
        setPasswordValue(value!)
        break
      }
    }
  }

  return (
    <>
      {showEmailLogin ? (
        <ButtonForm
          emailFormClick={emailFormClick}
          pageTitle={strings.login}
          facebookButtonText={buttonText.facebookButtonText}
          microsoftButtonText={buttonText.microsoftButtonText}
          googleButtonText={buttonText.googleButtonText}
          emailButtonText={buttonText.emailButtonText}
          signup={false}
        />
      ) : (
        <EmailLoginForm
          emailValue={emailValue}
          passwordValue={passwordValue}
          loginError={loginError}
          emailFormClick={emailFormClick}
          onLoginFormChange={onLoginFormChange}
          onEmailLoginFormSubmit={onEmailLoginFormSubmit}
          onPasswordResetClick={onPasswordResetClick}
          onEmailResetFormSubmit={onEmailResetFormSubmit}
          passwordResetView={passwordResetView}
          emailResetSentSuccess={emailResetSentSuccess}
          passwordResetMessage={passwordResetMessage}
          passwordResetError={passwordResetError}
        />
      )}
    </>
  )
}
