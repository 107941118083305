import './BackButton.css'

import { Icon } from '@fluentui/react'
import React from 'react'

const BackButton = ({ onClickBack }: { onClickBack: () => void }) => {
  return (
    <div className="back-button-container" onClick={onClickBack}>
      <div className="back-button" onClick={onClickBack}>
        <Icon className="back-button-icon" iconName="Back" />
        <div>Back</div>
        <div className="back-button-spacer"></div>
      </div>
    </div>
  )
}

export default BackButton
