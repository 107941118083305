import { Dropdown, Icon, IconButton, IDropdownOption, TextField, Toggle } from '@fluentui/react'
import { ContentState, convertFromRaw, EditorState, genKey } from 'draft-js'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import shortid from 'shortid'

import CollapseButton from '../../components/CollapseButton'
import { RichTextEditor } from '../../components/RichTextEditor'
import { DataCacheContext } from '../../contexts/dataCacheContext'
import { IChoice, IQuestion, QuestionTypes } from '../../types'
import { createEmptyResponses, indexToChar } from '../../utils'
import { useForceUpdate } from '../../utils/reactUtils'

export interface IHighlightedFieldsQuestion {
  isTitle?: boolean
  isEmptyChoice?: boolean
  choices?: { [responseId: string]: boolean }
}

const toggleStyle = { root: { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 0 0 5px' } }

const toggleContainerStyle = {
  backgroundColor: 'var(--white)',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  boxShadow: 'var(--textFieldShadow)'
}

const dropdownOptions: IDropdownOption[] = [
  {
    key: QuestionTypes.trueFalse,
    text: 'Yes/No'
  },
  {
    key: QuestionTypes.multipleChoice,
    text: 'Multiple Choice'
  },
  {
    key: QuestionTypes.checkboxes,
    text: 'Checkboxes'
  },
  {
    key: QuestionTypes.openEnded,
    text: 'Open Ended'
  }
]

// const menuButtonStyles: Partial<IButtonStyles> = {
//   root: {
//     background: 'transparent',
//     boxShadow: 'none',
//     selectors: {
//       '&:active': {
//         background: 'transparent',
//         boxShadow: 'none'
//       }
//     },
//     padding: 0
//   },
//   rootHovered: {
//     background: 'transparent',
//     boxShadow: 'none'
//   },
//   rootFocused: {
//     background: 'transparent',
//     boxShadow: 'none'
//   },
//   rootExpanded: {
//     background: 'transparent',
//     boxShadow: 'none'
//   },
//   menuIcon: {
//     fontSize: '24px',
//     fontWeight: 'bold'
//   }
// }

const changeQuestionType = (q: IQuestion, type: QuestionTypes) => {
  if (q.type === type) return

  q.choices = createEmptyResponses(type)

  q.type = type
}

const changeTopic = (q: IQuestion, topicId: string) => {
  if (q.topic === topicId) return

  q.topic = topicId
}

export type IQuestionCreationProps = {
  question: IQuestion
  index: number
  onDeleteQuestion?: (questionId: string) => void
  onDuplicateQuestion?: (questionId: string) => void
  isDisabled?: boolean
  onMoveQuestion?: (dragId: string, hoverId: string) => void
  isCollapsed: boolean
  setCollapsed: (id: string, isCollapsed: boolean) => void
  displayIsDragged?: boolean
  setIsQuestionDragged?: (id: string, isDragged: boolean) => void
  displayIsEmpty?: boolean
  onBlur: (questionId: string, answerId?: string) => void
  highlightedFields?: IHighlightedFieldsQuestion
  onChangeValue: (questionId: string, value?: string) => void
  onChangeQuestionResponse: (questionId: string, responseId: string, value?: string) => void

  // calls after the question is modified
  saveFunc?: (i?: void) => any
}

function stopEvent(e: React.DragEvent) {
  e.preventDefault()
  e.stopPropagation()
}

interface DragItem {
  id: string
  type: string
}

const questionTextFieldStyles = {
  field: {
    borderRadius: '3px 0 0 3px',
    backgroundColor: 'var(--bodyBackground)'
  }
}

const highlightedQuestionTextFieldStyles = {
  field: {
    borderRadius: '3px 0 0 3px',
    backgroundColor: 'var(--bodyBackground)',
    boxShadow: 'inset 0px 0px 0px 4px #f00'
  }
}

const questionTypeDropdownStyles = {
  title: {
    width: '8rem',
    borderRadius: '0 3px 3px 0',
    border: 'none',
    boxShadow: 'var(--textFieldShadow)'
  }
}

const topicDropdownStyles = {
  ...questionTypeDropdownStyles,
  title: { ...questionTypeDropdownStyles.title, width: '13rem' }
}

// const requiredToggleStyles = { root: { margin: 0 } }

const _QuestionCreation = (props: IQuestionCreationProps) => {
  const {
    question,
    index: questionIndex,
    onDeleteQuestion: onDeleteQuestionProp,
    // onDuplicateQuestion,
    isDisabled,
    isCollapsed,
    displayIsDragged,
    displayIsEmpty,
    onMoveQuestion,
    setCollapsed,
    setIsQuestionDragged,
    saveFunc,
    onBlur,
    onChangeValue,
    onChangeQuestionResponse,
    highlightedFields
  } = props

  const { topics, getTopics } = useContext(DataCacheContext)
  const [newResponseId, setNewResponseId] = useState(shortid.generate())
  const [responseBeingDragged, setResponseBeingDragged] = useState<IChoice | null>(null)

  const forceUpdate = useForceUpdate()

  const onChangeResponse = useCallback(
    (responseIndex: number, val: string, id?: string) => {
      // if its a new response, add it
      if (responseIndex === question.choices.length) {
        // new responses generate their own id
        question.choices.push({ id: id || '', value: val })
        setNewResponseId(shortid.generate())
      } // else update it
      else {
        question.choices[responseIndex].value = val
      }

      // remove if the last response becomes empty
      if (responseIndex === question.choices.length - 1 && !val) {
        const r = question.choices.pop()
        setNewResponseId(r ? r.id : '')
        // forceUpdate()
      }

      forceUpdate()
      if (id) {
        onChangeQuestionResponse(question.id, id, val)
      }
      if (saveFunc) saveFunc()
    },
    [question, forceUpdate, saveFunc]
  )

  const onDeleteResponse = useCallback(
    (responseIndex: number) => {
      question.choices.splice(responseIndex, 1)
      forceUpdate() // needs to update since number of responses is changing
      if (saveFunc) saveFunc()
    },
    [question, forceUpdate, saveFunc]
  )

  const onChangeQuestion = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val?: string) => {
      question.value = val || ''
      onChangeValue(question.id, val)
      if (saveFunc) saveFunc()
    },
    [question, saveFunc]
  )

  const onChangeQuestionType = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      let type = option && option.key
      if (typeof type !== 'number') return

      type = Number(type)
      changeQuestionType(question, type)
      onChangeValue(question.id, question.value)
      forceUpdate() // needs to update since number of responses is changing
      if (saveFunc) saveFunc()
    },
    [question, forceUpdate, saveFunc]
  )

  const onChangeTopic = useCallback(
    (_, option?: IDropdownOption) => {
      const id = option && option.key

      changeTopic(question, id as string)
      forceUpdate()
      if (saveFunc) saveFunc()
    },
    [question, forceUpdate, saveFunc]
  )

  // const onChangeRequired = useCallback(
  //   (event: React.MouseEvent<HTMLElement, MouseEvent>, required?: boolean) => {
  //     question.required = !!required
  //     if (saveFunc) saveFunc()
  //   },
  //   [question, saveFunc]
  // )
  const onToggleScale = useCallback(() => {
    question.hasImportantScale = !question.hasImportantScale
    if (saveFunc) saveFunc()
    forceUpdate()
  }, [question, saveFunc, forceUpdate])

  const onToggleRequired = useCallback(() => {
    question.required = !question.required
    if (saveFunc) saveFunc()
    forceUpdate()
  }, [question, saveFunc, forceUpdate])

  const onChangeInfoTitle = useCallback(
    (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
      question.infoTitle = newValue ? newValue : ''
      if (saveFunc) saveFunc()
    },
    [question, saveFunc]
  )

  const onChangeInfo = useCallback(
    (value: string) => {
      question.info = value
      if (saveFunc) saveFunc()
    },
    [question, saveFunc]
  )

  const onSetCollapsed = useCallback(() => {
    setCollapsed(question.id, !isCollapsed)
  }, [question, setCollapsed, isCollapsed])

  const allowDeleteQuestion = !isDisabled && !!onDeleteQuestionProp
  const onDeleteQuestion = useCallback(() => {
    console.log(question.id)

    if (onDeleteQuestionProp) onDeleteQuestionProp(question.id)
  }, [question, onDeleteQuestionProp])

  const draggableRef = useRef<HTMLDivElement | null>(null)
  const item: DragItem = {
    type: 'question',
    id: question.id
  }

  const isDraggingRef = useRef(false)
  const [, drag, preview] = useDrag({
    item,
    collect: (monitor: any) => {
      const d = monitor.isDragging()
      if (isDraggingRef.current !== d) {
        isDraggingRef.current = d
        setIsQuestionDragged && setIsQuestionDragged(question.id, d)
      }
      return { opacity: monitor.isDragging() ? 0.4 : 1 }
    }
  })

  const [, drop] = useDrop({
    accept: 'question',
    hover(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!draggableRef.current) return
      if (!onMoveQuestion) return

      const dragId = dragItem.id
      const hoverId = question.id

      if (dragId === hoverId) return

      const hoverBoundingRect = draggableRef.current.getBoundingClientRect()

      // center in the local coord
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverTopY = hoverBoundingRect.top

      // item location in local coordinate
      const clientOffsetY = monitor.getClientOffset()
      const hoverClientY = clientOffsetY!.y - hoverBoundingRect.top
      const dragTopY = monitor.getInitialClientOffset()!.y

      // if moving from up to down, then must be over first half
      if (dragTopY < hoverTopY && hoverClientY < hoverMiddleY) {
        return
      }

      // if moving from down to up, then must be less than first half
      if (dragTopY > hoverTopY && hoverClientY > hoverMiddleY) {
        return
      }

      // move question if all conditions are passed
      onMoveQuestion(dragId, hoverId)
    },
    drop(dragItem: DragItem, monitor: DropTargetMonitor) {
      // for when the question is moved out of group...
      // (NOTE: we are called setIsDragged for another question)
      const dragId = dragItem.id
      setIsQuestionDragged && setIsQuestionDragged(dragId, false)
    }
  })

  drag(drop(draggableRef))

  useEffect(() => {
    const fetchTopics = async () => {
      await getTopics()
    }

    fetchTopics()
  }, [getTopics])

  let response: React.ReactNode = null
  if (question.type === QuestionTypes.trueFalse) {
    response = (
      <>
        <div className="flex-row response-row">
          <div className="question-container-left-side">
            <div className="response-label-text">A</div>
          </div>
          <div className="question-container-center-content">
            <TextField defaultValue="Yes" disabled={true} />
          </div>
          <div className="question-container-right-side"></div>
        </div>

        <div className="flex-row response-row">
          <div className="question-container-left-side">
            <div className="response-label-text">B</div>
          </div>
          <div className="question-container-center-content">
            <TextField defaultValue="No" disabled={true} />
          </div>
          <div className="question-container-right-side"></div>
        </div>
      </>
    )
  }

  if (question.type === QuestionTypes.multipleChoice || question.type === QuestionTypes.checkboxes) {
    // must be an array to preserve new response key so option doesn't lose focus when being added to responses
    response = (
      <MultipleChoiceRows
        isDisabled={isDisabled}
        newResponseId={newResponseId}
        question={question}
        questionIndex={questionIndex}
        onChange={onChangeResponse}
        onDelete={onDeleteResponse}
        onMoveResponse={() => {
          forceUpdate()
          saveFunc && saveFunc()
        }}
        responseBeingDragged={responseBeingDragged}
        setResponseBeingDragged={setResponseBeingDragged}
        onBlur={(responseId: string) => onBlur(question.id, responseId)}
        highlightedFields={highlightedFields?.choices}
        isEmptyResponseHighlighted={highlightedFields?.isEmptyChoice}
      />
    )
  }

  const isTitleHighlighted = highlightedFields && highlightedFields.isTitle

  return (
    <div
      className="create-question-container flex-column"
      ref={preview}
      style={{
        opacity: displayIsEmpty ? 0.7 : displayIsDragged ? 0.5 : undefined
      }}>
      <div className="question-text-container">
        <div className="question-container-left-side">
          {!isDisabled && onMoveQuestion && (
            <div className="drag-icon" ref={draggableRef}>
              <Icon iconName="Drag" styles={{ root: { display: 'flex', alignItems: 'center' } }} />
            </div>
          )}
          {(isDisabled || !onMoveQuestion) && <div style={{ marginRight: 34 }} />}
          <div style={{ textAlign: 'center', flex: 1 }}>{questionIndex + 1}</div>
        </div>

        <div className="question-container-center-content">
          <TextField
            tabIndex={questionIndex + 1}
            defaultValue={question.value}
            className="question-textfield"
            placeholder="Enter Question"
            styles={isTitleHighlighted ? highlightedQuestionTextFieldStyles : questionTextFieldStyles}
            disabled={isDisabled}
            onDragStart={stopEvent}
            onChange={onChangeQuestion}
            onBlur={() => onBlur(question.id)}
          />
          <div style={toggleContainerStyle}>
            Required
            <Toggle checked={question.required} onChange={onToggleRequired} styles={toggleStyle} disabled={isDisabled} />
          </div>
          <div style={toggleContainerStyle}>
            Scaled
            <Toggle checked={question.hasImportantScale} onChange={onToggleScale} styles={toggleStyle} disabled={isDisabled} />
          </div>
          <Dropdown
            disabled={isDisabled}
            options={dropdownOptions}
            defaultSelectedKey={question.type}
            onChange={onChangeQuestionType}
            styles={questionTypeDropdownStyles}
          />
          <Dropdown
            disabled={isDisabled}
            options={[
              { id: 'blank', key: 'blank', text: 'No Topic' },
              ...topics.map(topic => ({ id: topic.id, key: topic.id, text: topic.name }))
            ]}
            onChange={onChangeTopic}
            defaultSelectedKey={topics.filter(topic => topic.id === question.topic).length > 0 ? question.topic : 'blank'}
            styles={topicDropdownStyles}
          />
        </div>

        <div className="question-container-right-side">
          {allowDeleteQuestion && (
            <IconButton
              iconProps={{
                iconName: 'Delete'
              }}
              styles={{
                root: {
                  background: 'transparent',
                  boxShadow: 'none'
                },
                rootHovered: {
                  background: 'transparent',
                  boxShadow: 'none'
                },
                rootPressed: {
                  background: 'var(--themeDark)',
                  color: 'var(--themeSecondary)',
                  boxShadow: 'none'
                },
                icon: {
                  fontSize: '18px'
                }
              }}
              onClick={onDeleteQuestion}
            />
          )}

          <CollapseButton isCollapsed={isCollapsed} onClick={onSetCollapsed} width={'42px'} />
        </div>
      </div>

      {!isCollapsed && (
        <div className="responses-container">
          <AddMoreInfoRow
            infoTitle={question.infoTitle}
            info={question.info}
            onChangeInfoTitle={onChangeInfoTitle}
            onChangeInfo={onChangeInfo}
            isDisabled={isDisabled}
          />
          {response}
        </div>
      )}
    </div>
  )
}

interface IAddMoreInfoProps {
  infoTitle: string | null
  info: string | null
  onChangeInfoTitle: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void
  onChangeInfo: (value: string) => void
  isDisabled?: boolean
  // onDeleteInfo: () => void
}

const id = genKey()

const AddMoreInfoRow = ({ infoTitle, info, onChangeInfoTitle, onChangeInfo, isDisabled }: IAddMoreInfoProps) => {
  return (
    <div className="flex-row">
      <div className="question-container-left-side"></div>
      <div className="moreinfo-container">
        <div className="moreinfo-title">
          <TextField
            placeholder="Why is this important?"
            defaultValue={infoTitle || undefined}
            onChange={onChangeInfoTitle}
            maxLength={30}
            disabled={isDisabled || false}
          />
          <div style={{ position: 'absolute', right: 14, bottom: 17, color: infoTitle && infoTitle.length === 30 ? 'red' : undefined }}>
            {infoTitle ? infoTitle.length : 0}/30
          </div>
        </div>
        <RichTextEditor
          id={id}
          value={EditorState.createWithContent(info ? convertFromRaw(JSON.parse(info)) : ContentState.createFromText(''))}
          placeholder="Add more info here"
          onChange={onChangeInfo}
          isDisabled={isDisabled || false}
        />
      </div>
      <div className="question-container-right-side"></div>
    </div>
  )
}

interface IMultipleChoiceResponseRowProps {
  id: string
  index: number
  questionIndex: number
  initialResponseValue: string
  isDisabled?: boolean
  onChange: (index: number, newVal: string, id?: string) => void
  onDelete?: (index: number) => void
  opacity?: number
  isDraggable: boolean
  ref?: React.MutableRefObject<HTMLDivElement | null>
  onMoveResponse?: (dragId: string, hoverId: string) => void
  setIsResponseDragged?: (id: string, isDragged: boolean) => void
  onBlur: (responseId: string) => void
  isHighlighted?: boolean
}

const MultipleChoiceResponseRow = React.memo((props: IMultipleChoiceResponseRowProps) => {
  const {
    id,
    index,
    questionIndex,
    isDisabled,
    initialResponseValue,
    onChange,
    onDelete,
    opacity,
    isDraggable,
    onMoveResponse,
    setIsResponseDragged,
    onBlur,
    isHighlighted
  } = props

  const draggableRef = useRef<HTMLDivElement | null>(null)

  const item: DragItem = {
    type: 'response',
    id
  }

  const [, drop] = useDrop({
    accept: 'response',
    hover(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!draggableRef.current) return
      if (!onMoveResponse) return

      const dragId = dragItem.id
      const hoverId = id

      if (dragId === hoverId) return

      const hoverBoundingRect = draggableRef.current.getBoundingClientRect()

      // center in the local coord
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverTopY = hoverBoundingRect.top

      // item location in local coordinate
      const clientOffsetY = monitor.getClientOffset()
      const hoverClientY = clientOffsetY!.y - hoverBoundingRect.top
      const dragTopY = monitor.getInitialClientOffset()!.y

      // if moving from up to down, then must be over first half
      if (dragTopY < hoverTopY && hoverClientY < hoverMiddleY) {
        return
      }

      // if moving from down to up, then must be less than first half
      if (dragTopY > hoverTopY && hoverClientY > hoverMiddleY) {
        return
      }

      // move question if all conditions are passed
      onMoveResponse(dragId, hoverId)
    },
    drop(dragItem: DragItem, monitor: DropTargetMonitor) {
      // for when the question is moved out of group...
      // (NOTE: we are called setIsDragged for another question)
      const dragId = dragItem.id
      setIsResponseDragged && setIsResponseDragged(dragId, false)
    }
  })
  const isDraggingRef = useRef(false)

  const [, drag, preview] = useDrag({
    item,
    collect: (monitor: any) => {
      if (!isDraggingRef) return
      const d = monitor.isDragging()
      if (isDraggingRef.current !== d) {
        isDraggingRef.current = d
        setIsResponseDragged && setIsResponseDragged(id, d)
      }
      return { opacity: monitor.isDragging() ? 0.4 : 1 }
    }
  })

  drag(drop(draggableRef))

  const onChangeResponse = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      onChange(index, newValue || '', id)
    },
    [index, onChange]
  )

  const onDeleteResponse = useCallback(() => {
    onDelete && onDelete(index)
  }, [index, onDelete])

  return (
    <div className="flex-row response-row" key={id} style={{ opacity }} ref={preview}>
      <div className="question-container-left-side">
        {!isDisabled && isDraggable && (
          <div draggable className="drag-icon" ref={draggableRef}>
            <Icon iconName="Drag" styles={{ root: { display: 'flex', alignItems: 'center' } }} />
          </div>
        )}
        {!isDisabled && !isDraggable && <div style={{ marginRight: '34px', height: '100%' }}></div>}

        <div style={{ flex: 1 }} />

        <div className="response-label-text">{indexToChar(index)}</div>
      </div>

      <div className="question-container-center-content">
        <TextField
          defaultValue={initialResponseValue}
          placeholder="Enter Response Option"
          tabIndex={questionIndex + 1}
          disabled={isDisabled}
          onDragStart={stopEvent}
          onChange={onChangeResponse}
          onBlur={() => onBlur(id)}
          styles={isHighlighted ? highlightedQuestionTextFieldStyles : undefined}
        />
      </div>

      <div className="question-container-right-side">
        {!isDisabled && onDelete && (
          <IconButton
            styles={{
              root: {
                color: 'var(--white)',
                margin: 'auto',
                width: 72,
                borderRadius: 32
              },
              rootPressed: {
                background: 'var(--themeDark)',
                color: 'var(--themeSecondary)'
              },
              icon: {
                color: 'var(--white)',
                fontSize: '18px'
              }
            }}
            iconProps={{ iconName: 'Delete' }}
            onClick={onDeleteResponse}
          />
        )}
      </div>
    </div>
  )
})

interface IMultipleChoiceRowsProps {
  question: IQuestion
  questionIndex: number
  isDisabled?: boolean
  onChange: (index: number, newVal: string, id?: string) => void
  onDelete?: (index: number) => void
  newResponseId: string
  onMoveResponse: () => void
  responseBeingDragged?: IChoice | null
  setResponseBeingDragged: (response: IChoice | null) => void
  onBlur: (responseId: string) => void
  highlightedFields?: { [responseId: string]: boolean }
  isEmptyResponseHighlighted?: boolean
}

const MultipleChoiceRows = (props: IMultipleChoiceRowsProps) => {
  const {
    question,
    questionIndex,
    onChange,
    onDelete,
    isDisabled,
    newResponseId,
    onMoveResponse: onMoveResponseProp,
    setResponseBeingDragged,
    responseBeingDragged,
    onBlur,
    highlightedFields,
    isEmptyResponseHighlighted
  } = props

  //   const [state, setState] = useState({})

  const responsesById: { [key: string]: IChoice } = {}
  const responseIndices: { [key: string]: number } = {}

  question.choices.forEach((r, idx) => {
    responsesById[r.id] = r
    responseIndices[r.id] = idx
  })

  const setIsResponseDragged = (id: string, isDragged: boolean) => {
    if (isDragged && responseBeingDragged?.id !== id) {
      setResponseBeingDragged(responsesById[id])
    }

    if (!isDragged) {
      setResponseBeingDragged(null)
    }
  }

  const onMoveResponse = (dragId: string, hoverId: string) => {
    const dragResponse = responsesById[dragId]
    const dragIndex = responseIndices[dragId]
    const hoverResponse = responsesById[hoverId]
    const hoverIndex = responseIndices[hoverId]

    if (!dragResponse || !hoverResponse) return

    question.choices.splice(dragIndex, 1)
    question.choices.splice(hoverIndex, 0, dragResponse)

    onMoveResponseProp()
  }

  const rows = [
    ...question.choices.map((response, index) => {
      return (
        <MultipleChoiceResponseRow
          id={response.id}
          key={response.id}
          questionIndex={questionIndex}
          initialResponseValue={response.value}
          index={index}
          isDisabled={isDisabled}
          onChange={onChange}
          onDelete={onDelete}
          isDraggable={true}
          setIsResponseDragged={setIsResponseDragged}
          onMoveResponse={onMoveResponse}
          onBlur={() => onBlur(response.id)}
          isHighlighted={highlightedFields && response.id in highlightedFields}
        />
      )
    })
  ]

  if (!isDisabled) {
    rows.push(
      <MultipleChoiceResponseRow
        id={newResponseId}
        key={newResponseId}
        questionIndex={questionIndex}
        isDisabled={isDisabled}
        initialResponseValue={''}
        index={question.choices.length}
        onChange={onChange}
        opacity={0.7}
        isDraggable={false}
        onBlur={() => onBlur(newResponseId)}
        isHighlighted={isEmptyResponseHighlighted}
      />
    )
  }

  return <>{rows}</>
}

export const QuestionCreation = React.memo(_QuestionCreation)
