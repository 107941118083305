import './css/SurveyContacts.css'

import { Button } from 'components/Button'
import React, { FC, useContext, useEffect, useState } from 'react'

import { DashboardHeader } from '../components/DashboardHeader'
import { SurveyContactsTable } from '../components/SurveyContactsTable'
import { DataCacheContext } from '../contexts/dataCacheContext'
import { ISurvey, ISurveyContact, ISurveyInvitation, ISurveySubmission, ResponseStatus } from '../types'
import { determineQuestionsAnswered, timeToLocaleDate } from '../utils'
import { OwnerSurveyDashboardModals } from './OwnerSurveyDashboardModals'
import { Paginator } from './Paginator'

interface ISurveyContactsProps {
  survey: ISurvey
  submit: (to: ISurveyInvitation[], subject: string, message: string) => void
}

const pageLimit = 50

export const SurveyContacts: FC<ISurveyContactsProps> = ({ survey, submit }) => {
  const { isFetching, getSurveySubmissions, contacts } = useContext(DataCacheContext)
  const [surveyContacts, setSurveyContacts] = useState<ISurveyContact[]>([])
  const [sentCount, setSentCount] = useState(0)
  const [respondedCount, setRespondedCount] = useState(0)
  const [startedCount, setStartedCount] = useState(0)
  const [awaitingCount, setAwaitingCount] = useState(0)
  const [search, setSearch] = useState<string | undefined>('')
  const [filterMode, setFilterMode] = useState<'' | 'waiting' | 'completed' | 'started'>('')
  const [page, setPage] = useState(0)

  const getResponseStatus = (surveySubmission: ISurveySubmission): ResponseStatus => {
    if (surveySubmission.survey.isComplete) return ResponseStatus.Responded
    if (surveySubmission.survey.isStarted) return ResponseStatus.Started
    return ResponseStatus.AwaitingResponse
  }

  const handleSearchChange = (_?: React.ChangeEvent<HTMLInputElement | undefined> | undefined, value?: string | undefined) => {
    setPage(0)
    setSearch(value)
  }

  useEffect(() => {
    const fetchSurveySubmissions = async () => {
      const surveySubmissions = await getSurveySubmissions(survey.id)
      const surveyContacts: ISurveyContact[] = surveySubmissions.map(surveySubmission => {
        const numQuestionsAnswered = determineQuestionsAnswered(surveySubmission)

        const name = contacts && contacts[surveySubmission.submissionUserId] ? contacts[surveySubmission.submissionUserId].name || '' : ''

        return {
          name,
          email: surveySubmission.submissionUserId,
          dateReceived: surveySubmission.survey.dateReceived ? timeToLocaleDate(surveySubmission.survey.dateReceived) : '-',
          dateStarted: surveySubmission.survey.dateStarted ? timeToLocaleDate(surveySubmission.survey.dateStarted) : '-',
          dateResponded: surveySubmission.survey.dateSubmitted ? timeToLocaleDate(surveySubmission.survey.dateSubmitted) : '-',
          responseStatus: getResponseStatus(surveySubmission),
          questionsAnswered: `${numQuestionsAnswered}/${surveySubmission.survey.questions.length}`,
          surveyUrl: `${window.location.protocol}//${window.location.host}/#/survey/${surveySubmission.survey.id}/${surveySubmission.id}`
        }
      })

      setSurveyContacts(surveyContacts)
      setSentCount(surveyContacts.length)
    }

    fetchSurveySubmissions()
  }, [getSurveySubmissions, contacts])

  useEffect(() => {
    const getAwaitingCount = surveyContacts.reduce(
      (count, surveyContact) => count + (surveyContact.responseStatus === ResponseStatus.AwaitingResponse ? 1 : 0),
      0
    )
    const getRespondedCount = surveyContacts.reduce(
      (count, surveyContact) => count + (surveyContact.responseStatus === ResponseStatus.Responded ? 1 : 0),
      0
    )
    const getStartedCount = surveyContacts.reduce(
      (count, surveyContact) => count + (surveyContact.responseStatus === ResponseStatus.Started ? 1 : 0),
      0
    )

    setAwaitingCount(getAwaitingCount)
    setRespondedCount(getRespondedCount)
    setStartedCount(getStartedCount)
  }, [surveyContacts, setAwaitingCount, setRespondedCount])

  const filteredSurveyContacts = surveyContacts.filter(c => {
    if (search && c.email.indexOf(search) === -1 && c.name.indexOf('' + search) === -1) return false
    if (filterMode === 'waiting' && c.responseStatus !== ResponseStatus.AwaitingResponse) return false
    if (filterMode === 'completed' && c.responseStatus !== ResponseStatus.Responded) return false
    if (filterMode === 'started' && c.responseStatus !== ResponseStatus.Started) return false
    return true
  })

  useEffect(() => {
    setPage(0)
  }, [filterMode])
  const pagination = () => (
    <div className="paginator-contacts-container">
      <Paginator
        maxPaginatorDisplay={Math.ceil(filteredSurveyContacts.length / pageLimit)}
        countPages={Math.ceil(filteredSurveyContacts.length / pageLimit)}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  )

  return (
    <div className="create-survey-container">
      <DashboardHeader title={survey.name} description={survey.description} onSearch={handleSearchChange}>
        <div className="survey-contacts-filters">
          <Button secondary={filterMode === ''} ghost={filterMode !== ''} onClick={() => setFilterMode('')} text={`${sentCount} Sent`} sm />
          <Button
            secondary={filterMode === 'completed'}
            ghost={filterMode !== 'completed'}
            onClick={() => setFilterMode('completed')}
            text={`${respondedCount} Completed`}
            sm
          />
          <Button
            secondary={filterMode === 'started'}
            ghost={filterMode !== 'started'}
            onClick={() => setFilterMode('started')}
            text={`${startedCount} Started`}
            sm
          />
          <Button
            secondary={filterMode === 'waiting'}
            ghost={filterMode !== 'waiting'}
            onClick={() => setFilterMode('waiting')}
            text={`${awaitingCount} Waiting`}
            sm
          />
        </div>
      </DashboardHeader>

      <div className="questions-and-topics questions-and-topics-left">
        <div className="container">
          <h2 className="survey-contacts-title">Response Details</h2>
          {filteredSurveyContacts.length === 0 ? (
            <div>No Results Found</div>
          ) : (
            <>
              {pagination()}
              <SurveyContactsTable
                loading={isFetching}
                contacts={filteredSurveyContacts.slice(pageLimit * page, (page + 1) * pageLimit)}
                page={page}
              />
              {pagination()}
            </>
          )}
        </div>
      </div>

      <OwnerSurveyDashboardModals survey={survey} submit={submit} />
    </div>
  )
}
