import {
  IBasePickerStyles,
  IButtonStyles,
  ICheckboxStyleProps,
  ICheckboxStyles,
  IChoiceGroupOptionStyleProps,
  IChoiceGroupOptionStyles,
  IContextualMenu,
  ICustomizations,
  IDatePickerStyles,
  IDetailsColumnStyles,
  IDetailsHeaderStyles,
  IDetailsListStyles,
  IDetailsRowStyles,
  IDialogContentStyles,
  IDialogFooterStyles,
  IDialogStyles,
  IDropdownStyleProps,
  IDropdownStyles,
  ILabelStyles,
  ILinkStyles,
  IMessageBarStyles,
  IModalStyles,
  IPeoplePickerItemSelectedStyleProps,
  IPeoplePickerItemSelectedStyles,
  IPersonaCoinStyles,
  IPivotStyles,
  ISearchBoxStyles,
  ITextFieldStyleProps,
  ITextFieldStyles,
  ITextStyles,
  IToggleStyleProps,
  IToggleStyles
} from '@fluentui/react'

export const CheckboxStyles = (props: ICheckboxStyleProps): ICheckboxStyles => ({
  root: {
    selectors: {
      ':hover .ms-Checkbox-checkbox, :focus .ms-Checkbox-checkbox': {
        border: '2px solid var(--checkboxHoverBorder)'
      },
      ...(!props.checked && {
        ':hover .ms-Checkbox-checkmark, :focus .ms-Checkbox-checkmark': {
          opacity: 0
        }
      }),
      ...(props.checked && {
        '.ms-Checkbox-checkbox': {
          color: 'var(--checkboxColor)',
          border: '2px solid var(--checkboxHoverBorder)',
          background: 'var(--checkboxChecked)'
        },
        ':hover .ms-Checkbox-checkbox, :focus .ms-Checkbox-checkbox': {
          border: '2px solid var(--checkboxHoverBorder)',
          background: 'var(--checkboxCheckedHover)'
        },
        ':hover .ms-Checkbox-checkmark, :focus .ms-Checkbox-checkmark': {
          opacity: 1
        }
      }),
      ...(props.disabled && {
        ':hover .ms-Checkbox-checkbox, :focus .ms-Checkbox-checkbox': {}
      })
    }
  },
  checkbox: {
    border: '2px solid var(--checkboxBorder)',
    borderRadius: 7,
    fontWeight: 900
  }
})

export const ChoiceGroupOptionStyles = (props: IChoiceGroupOptionStyleProps): IChoiceGroupOptionStyles => ({
  root: {
    selectors: {
      '.ms-ChoiceField-field::before': {
        border: '2px solid var(--checkboxBorder)'
      },
      ':hover .ms-ChoiceField-field::before, :focus .ms-ChoiceField-field::before': {
        background: 'none',
        border: '2px solid var(--themeSecondary)'
      },
      ':hover .ms-ChoiceField-field::after, :focus .ms-ChoiceField-field::after': {
        borderColor: 'transparent',
        backgroundColor: 'transparent'
      },
      ...(props.checked && {
        '.ms-ChoiceField-field::before': {
          border: '2px solid var(--themeSecondary)'
        },
        '.ms-ChoiceField-field::after': {
          borderColor: 'var(--themeSecondary)'
        },
        ':hover .ms-ChoiceField-field::after': {
          borderColor: 'var(--themeSecondary)'
        }
      })
    },
    marginBottom: '12px'
  },
  field: {
    fontSize: '16px'
  }
})

export const ContextualMenuStyles: Partial<IContextualMenu> = {
  root: {
    width: 130,
    minWidth: 'auto'
  },
  subComponentStyles: {
    menuItem: {
      root: {
        fontWeight: 400
      }
    }
  }
}

export const DatePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    background: 'transparent',
    selectors: {
      input: {
        paddingLeft: 5,
        fontWeight: 400,
        border: 'none'
      }
    }
  },
  callout: {
    selectors: {
      '.ms-DatePicker-day--highlighted .ms-DatePicker-day--today': {}
    }
  },
  textField: {
    background: 'transparent'
  },
  icon: {
    paddingTop: 5,
    fontSize: 18
  }
}

export const DefaultButtonStyles: Partial<IButtonStyles> = {
  root: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    background: 'transparent',
    boxShadow: 'none',
    border: '2px solid var(--button)',
    color: 'var(--button)',
    outline: 'none',
    borderRadius: 25,
    selectors: {
      '::after': {
        top: '0 !important',
        right: '0 !important',
        bottom: '0 !important',
        left: '0 !important'
      }
    }
  },
  rootHovered: {
    background: 'transparent',
    boxShadow: 'none',
    border: '2px solid var(--buttonHover)',
    color: 'var(--buttonHover)'
  },
  rootPressed: {
    background: 'transparent',
    boxShadow: 'none',
    border: '2px solid var(--buttonActive)',
    color: 'var(--buttonActive)'
  }
}

export const DetailsColumnStyles: Partial<IDetailsColumnStyles> = {
  root: {
    height: 30,
    justifyContent: 'center',
    padding: 0,
    selectors: {
      ':hover i[data-icon-name="GripperBarVertical"]': { display: 'none' },
      ':hover': {
        background: 'transparent'
      },
      ':active': {
        background: 'transparent'
      },
      '&:first-child': {
        justifyContent: 'flex-start'
      },
      '&:first-child .ms-DetailsHeader-cellTitle': {
        justifyContent: 'flex-start',
        paddingLeft: '48px'
      }
    }
  },
  cellTitle: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  cellName: {
    fontWeight: 600,
    fontSize: '1rem'
  }
}

export const DetailsHeaderStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    lineHeight: '1',
    height: 30,
    padding: 0,
    background: 'transparent',
    border: 'none'
  },
  dropHintCaretStyle: {
    top: -12
  },
  dropHintLineStyle: {
    height: 30
  }
}

export const DetailsListStyles: Partial<IDetailsListStyles> = {
  root: {
    background: 'transparent'
  },
  headerWrapper: {
    height: 30
  },
  contentWrapper: {
    minHeight: 42,
    marginBottom: '24px'
  }
}

export const DetailsRowStyles: Partial<IDetailsRowStyles> = {
  root: {
    borderBottom: 0,
    cursor: 'pointer',
    background: 'var(--detailsRowBackground)',
    boxShadow: '0px 6px 11px #63A0884D',
    borderRadius: '6px',
    marginTop: '24px',
    minHeight: '56px',
    selectors: {
      '&:hover': {
        background: 'var(--detailsRowBackgroundHover)'
      },
      '&:active': {
        background: 'var(--detailsRowBackgroundActive)'
      },
      '&:focus': {
        background: 'var(--detailsRowBackgroundActive)'
      },
      '&:hover:focus': {
        background: 'var(--detailsRowBackgroundActive)'
      },
      '&:hover .ms-DetailsRow-cell': {
        color: 'var(--detailsCellColor)'
      },
      '&:active .ms-DetailsRow-cell': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:active .ms-Link': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:active:hover .ms-Link': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:active .ms-Button--icon': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus .ms-DetailsRow-cell': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus .ms-Link': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus .ms-Button--icon': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus:hover .ms-DetailsRow-cell': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus:hover .ms-Link': {
        color: 'var(--detailsCellColorActive)'
      },
      '&:focus:hover .ms-Button--icon': {
        color: 'var(--detailsCellColorActive)'
      }
    }
  },
  cell: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    background: 'transparent',
    color: 'var(--detailsCellColor)',
    justifyContent: 'center',
    selectors: {
      '& .ms-Link': {
        color: 'var(--detailsCellColor)',
        width: '100%',
        lineHeight: '48px'
      },
      '& .ms-Link:hover': {
        color: 'var(--detailsCellColor)'
      },
      '&:first-child': {
        paddingLeft: '48px',
        justifyContent: 'flex-start'
      },
      '&:last-child': {
        paddingRight: '4px'
      }
    }
  }
}

export const DialogContentStyles: Partial<IDialogContentStyles> = {
  title: {
    fontSize: 18,
    fontWeight: 700
  }
}

export const DialogFooterStyles: Partial<IDialogFooterStyles> = {}

export const DialogStyles: Partial<IDialogStyles> = {}

export const DropdownStyles = (props: Partial<IDropdownStyleProps>): Partial<IDropdownStyles> => ({
  dropdown: [
    {
      selectors: {
        '&:focus:after': { border: '1px solid transparent' },
        '&:hover .ms-Dropdown-title, &:active .ms-Dropdown-title, &:focus .ms-Dropdown-title': {
          color: 'var(--dropdownItemColor)'
        },
        '&:active .ms-Dropdown-title': {
          color: 'var(--dropdownItemColor)'
        },
        '&:hover .ms-Dropdown-caretDown': { color: 'var(--dropdownItemColor)' },
        '&:focus .ms-Dropdown-caretDown': { color: 'var(--dropdownItemColor)' },
        '&:active .ms-Dropdown-caretDown': { color: 'var(--dropdownItemColor)' }
      }
    },
    props.isOpen && {
      selectors: {
        '&:hover .ms-Dropdown-caretDown': { color: 'var(--dropdownItemColor)' },
        '.ms-Dropdown-caretDown': { color: 'var(--dropdownItemColor)' }
      }
    }
  ],
  caretDown: {
    color: 'var(--almostBlack)',
    fontSize: 16,
    fontWeight: 700
  },
  dropdownItemsWrapper: {
    // background: 'var(--lightGrey)'
  },
  dropdownItemSelected: {
    minHeight: 26,
    color: 'var(--dropdownItemColor)'
  },
  dropdownItem: {
    color: 'var(--dropdownItemColor)',
    minHeight: 26,
    selectors: {}
  },
  dropdownOptionText: {
    fontSize: 14,
    fontWeight: 300
  },
  title: {
    fontWeight: 400,
    borderRadius: 5
  },
  subComponentStyles: {
    label: {},
    multiSelectItem: {
      label: {
        selectors: {
          ':active, :focus, :focus:hover': { background: 'var(--dropdownItemColor)' },
          '.ms-Dropdown-optionText': { marginLeft: 10 }
        }
      }
    },
    panel: {}
  },
  callout: {
    borderRadius: 5,
    // background: 'var(--lightGrey)',
    selectors: {
      '.ms-Callout-main': {
        borderRadius: 5
      }
    }
  }
})

export const IconButtonStyles: Partial<IButtonStyles> = {
  root: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    backgroundColor: 'var(--button)',
    color: 'var(--iconButtonColor)',
    boxShadow: 'var(--buttonShadow)',
    border: 'none',
    outline: 'none',
    borderRadius: 5,
    selectors: {
      '::after': {
        top: '0 !important',
        right: '0 !important',
        bottom: '0 !important',
        left: '0 !important'
      }
    }
  },
  rootHovered: {
    backgroundColor: 'var(--buttonHover)',
    color: 'var(--iconButtonColor)',
    boxShadow: 'var(--buttonShadowHover)',
    border: 'none',
    outline: 'none'
  },
  rootPressed: {
    backgroundColor: 'var(--buttonActive)',
    color: 'var(--iconButtonColor)',
    boxShadow: 'var(--buttonShadowActive)',
    border: 'none',
    outline: 'none'
  }
}

export const LabelStyles: Partial<ILabelStyles> = {
  root: {
    fontSize: 16,
    fontWeight: 300
  }
}

export const LinkStyles: ILinkStyles = {
  root: {
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'var(--link)',
    selectors: {
      '&:disabled': {
        textDecoration: 'none'
      }
    }
  }
}

export const MessageBarStyles: IMessageBarStyles = {}

export const ModalStyle: Partial<IModalStyles> = {
  main: { background: 'var(--bodyBackground)' },
  scrollableContent: {
    maxHeight: '80vh'
  }
}

export const PersonaCoinStyles: Partial<IPersonaCoinStyles> = {
  initials: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: 18,
    paddingTop: 1,
    color: 'var(--white)'
  }
}

export const PivotStyles: Partial<IPivotStyles> = {
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 50,
    width: `100%`,
    selectors: {
      '.ms-Pivot-link:hover': { background: 'transparent' },
      '.ms-Pivot-link.is-selected:hover': { background: 'transparent' }
    }
  },
  link: {
    width: 32,
    height: 32,
    lineHeight: 32
  },
  linkIsSelected: {
    lineHeight: 32,
    height: 32,
    selectors: {
      ':before': { left: 0, right: 0, transition: '', bottom: -10 },
      ':after': { height: 0 }
    }
  },
  linkContent: {
    display: 'flex',
    background: 'transparent',
    color: 'var(--button)'
  },
  icon: {
    color: 'inherit',
    fontSize: 18,
    width: 16,
    height: 16,
    lineHeight: 16
  }
}

export const PrimaryButtonStyles: Partial<IButtonStyles> = {
  root: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    background: 'var(--button)',
    boxShadow: 'var(--buttonShadow)',
    border: 'none',
    outline: 'none',
    borderRadius: 25,
    color: 'var(--buttonText)',
    selectors: {
      '::after': {
        top: '0 !important',
        right: '0 !important',
        bottom: '0 !important',
        left: '0 !important'
      }
    }
  },
  rootHovered: {
    background: 'var(--buttonHover)',
    boxShadow: 'var(--buttonShadowHover)',
    border: 'none',
    outline: 'none',
    color: 'var(--buttonText)'
  },
  rootPressed: {
    background: 'var(--buttonActive)',
    boxShadow: 'var(--buttonShadowActive)',
    border: 'none',
    outline: 'none',
    color: 'var(--themePrimaryHover)'
  },
  rootDisabled: {
    background: 'var(--lightGrey)',
    color: 'var(--white)'
  },
  icon: {
    color: 'var(--buttonText)'
  }
}

export const SearchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    color: 'var(--bodyText)',
    borderRadius: '40px',
    borderColor: 'var(--bodyText)',
    selectors: {
      '&::after': {
        borderRadius: '40px'
      }
    }
  },
  clearButton: {
    selectors: {
      '& > .ms-Button': {
        backgroundColor: 'transparent',
        background: 'none',
        boxShadow: 'none'
      },
      '& > .ms-Button:hover': {
        backgroundColor: 'transparent',
        background: 'none',
        boxShadow: 'none'
      }
    }
  }
}

export const TagPickerStyles: Partial<IBasePickerStyles> = {
  root: {
    width: '100%',
    background: 'transparent',
    color: 'var(--themePrimaryActive)',
    boxShadow: 'var(--textFieldShadow)',
    borderRadius: 3
  },
  input: {
    height: 32
  },
  text: {
    border: 'none',
    background: 'transparent',
    color: 'var(--themePrimaryActive)',
    selectors: {
      '::after': { display: 'none' }
    }
  }
}

export const PeoplePickerItemSelectedStyles = (
  props: Partial<IPeoplePickerItemSelectedStyleProps>
): Partial<IPeoplePickerItemSelectedStyles> => {
  console.log(props)

  return {
    root: {
      backgroundColor: props.invalid ? '#c93b22' : 'var(--themePrimary)',
      color: 'var(--white)',
      selectors: {
        ':hover': {
          color: 'var(--white)',
          backgroundColor: props.invalid ? '#a83622' : 'var(--themePrimary)',
          selectors: {
            '.ms-TooltipHost': {
              color: 'var(--white)'
            },
            button: {
              backgroundColor: '#a83622'
            }
          }
        }
      }
    },
    removeButton: {
      color: 'var(--white)',
      backgroundColor: props.invalid ? '#c93b22' : 'var(--themePrimary)',
      selectors: {
        ':hover': {
          background: props.invalid ? '#a83622' : 'var(--themePrimary)',
          color: 'var(--white)'
        },
        ':active': {
          background: 'var(--lightGrey)',
          color: 'var(--white)'
        }
      }
    },
    subComponentStyles: {
      persona: {
        primaryText: { color: 'var(--white)', selectors: { ':hover': { color: 'var(--white)' } } },
        secondaryText: { display: 'none' }
      }
    }
  }
}

export const TextFieldStyles = (props: Partial<ITextFieldStyleProps>): Partial<ITextFieldStyles> => ({
  root: {
    width: '100%'
  },
  field: {
    boxSizing: 'border-box',
    color: 'var(--themePrimaryActive)',
    boxShadow: 'var(--textFieldShadow)',
    borderRadius: 3,
    background: 'transparent',
    ...(props.hasErrorMessage && { boxShadow: '0 0 0pt 2pt var(--errorMessage)' })
  },
  fieldGroup: [
    {
      borderRadius: 3,
      background: 'var(--bodyBackground)',
      border: 'none'
    },
    !props.focused && {
      ...(props.hasErrorMessage && { boxShadow: '0 0 0pt 2pt var(--errorMessage)' })
    },
    props.focused && {
      ...(props.hasErrorMessage && { boxShadow: '0 0 0pt 2pt var(--errorMessage)' }),
      selectors: {
        ':after': { border: 'none' }
      }
    }
  ],
  description: {
    width: '60px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  errorMessage: {
    paddingTop: 0,
    animation: 'none',
    justifyContent: 'flex-end',
    color: 'var(--red)',
    fontSize: '14px',
    fontWeight: 'normal'
  }
})

export const TextStyles: Partial<ITextStyles> = {
  root: {
    cursor: 'pointer'
  }
}

export const ToggleStyles = (props: IToggleStyleProps): Partial<IToggleStyles> => {
  return {
    pill: {
      background: props.checked ? 'var(--togglePillChecked)' : 'var(--togglePill)',
      selectors: {
        '&:hover .ms-Toggle-thumb': {
          background: props.checked ? 'var(--toggleThumbChecked)' : 'var(--toggleThumb)'
        }
      }
    },
    thumb: {
      background: props.checked ? 'var(--toggleThumbChecked)' : 'var(--toggleThumb)',
      border: props.checked ? 'var(--toggleThumbBorderChecked)' : 'var(--toggleThumbBorder)',
      transform: 'scale(1.5)',
      boxShadow: 'var(--toggleThumbShadow)'
    }
  }
}

export const Customizations: Partial<ICustomizations> = {
  scopedSettings: {
    Checkbox: { styles: CheckboxStyles },
    ChoiceGroupOption: { styles: ChoiceGroupOptionStyles },
    CompactPeoplePicker: { styles: TagPickerStyles },
    NormalPeoplePicker: { styles: TagPickerStyles },
    PeoplePickerItem: { styles: PeoplePickerItemSelectedStyles },
    ContextualMenu: { styles: ContextualMenuStyles },
    DatePicker: { styles: DatePickerStyles },
    DefaultButton: { styles: DefaultButtonStyles },
    DetailsColumn: { styles: DetailsColumnStyles },
    DetailsHeader: { styles: DetailsHeaderStyles },
    DetailsList: { styles: DetailsListStyles },
    DetailsRow: { styles: DetailsRowStyles },
    Dialog: { styles: DialogStyles },
    DialogContent: { styles: DialogContentStyles },
    DialogFooter: { styles: DialogFooterStyles },
    Dropdown: { styles: DropdownStyles },
    IconButton: { styles: IconButtonStyles },
    Label: { styles: LabelStyles },
    Link: { styles: LinkStyles },
    MessageBar: { styles: MessageBarStyles },
    Modal: { styles: ModalStyle },
    PersonaCoin: { styles: PersonaCoinStyles },
    Pivot: { styles: PivotStyles },
    PrimaryButton: { styles: PrimaryButtonStyles },
    SearchBox: { styles: SearchBoxStyles },
    TagPicker: { styles: TagPickerStyles },
    Text: { styles: TextStyles },
    TextField: { styles: TextFieldStyles },
    Toggle: { styles: ToggleStyles }
  }
}
