import './index.css'

import { ChoiceGroupOption, IconButton, Modal } from '@fluentui/react'
import { Button } from 'components/Button'
import { DashboardHeader } from 'components/DashboardHeader'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { OwnerSurveyDashboardModals } from 'containers/OwnerSurveyDashboardModals'
import { Paginator } from 'containers/Paginator'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext, useEffect, useState } from 'react'
import { IQuestion, IQuestionComment, ISurvey, ISurveyComments, ISurveyInvitation, QuestionTypes } from 'types'
import { timeToLocaleDate } from 'utils'

interface ISurveyCommentsProps {
  survey: ISurvey
  submit: (to: ISurveyInvitation[], subject: string, message: string) => void
}

const itemsPerPage = 10
const maxNumPageList = 7

export const SurveyComments: React.FC<ISurveyCommentsProps> = props => {
  const { survey } = props

  const [surveyComments, setSurveyComments] = useState<ISurveyComments>()
  const { getSurveyComments } = useContext(FirebaseContext)
  const [allQuestions, setAllQuestions] = useState<IQuestion[]>([])
  const [question, setQuestion] = useState<IQuestion | null>(null)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [showQuestionsModal, setShowQuestionsModal] = useState(false)
  const [nextQuestionSelect, setNextQuestionSelect] = useState<IQuestion | null>(null)
  const [filteredComments, setFilteredComments] = useState<IQuestionComment[]>([])

  const pageCount = Math.ceil(filteredComments.length / itemsPerPage)

  const [page, setPage] = useState(0)

  const paginatedComments: IQuestionComment[] = filteredComments.slice(page * itemsPerPage, (page + 1) * itemsPerPage)

  const handleSearchChange = (event?: React.ChangeEvent<HTMLInputElement | undefined>, str?: string) => {
    let newSearch = str || ''
    setSearch(newSearch)
    setPage(0)

    if (!surveyComments || !question || str === undefined) return
    const questionComments = surveyComments.questions[question.id]
    const filteredComments = questionComments.filter(({ comment }) => comment.toLowerCase().indexOf(str) !== -1)

    setFilteredComments(filteredComments)
  }

  const isQuestionAnswered = (question: IQuestion) => {
    if (!surveyComments) return

    const questionComments = surveyComments.questions[question.id]
    return questionComments.some(({ comment }) => comment.length)
  }

  // initialize by fetching and setting the initial values
  useEffect(() => {
    const questions = survey.questions.filter(q => q.type === QuestionTypes.openEnded)
    setAllQuestions(questions)

    const q = questions.length ? questions[0] : null
    setQuestion(q)

    const fetchComments = async (surveyId: string) => {
      setLoading(true)
      const surveyComments = await getSurveyComments(surveyId)
      setSurveyComments(surveyComments)
      setLoading(false)
    }
    if (survey.id) {
      fetchComments(survey.id)
    }
  }, [survey.id])

  useEffect(() => {
    if (question && surveyComments) {
      setSearch('')
      setFilteredComments(surveyComments.questions[question.id])
    }
  }, [question])

  const onClickSelectQuestion = () => {
    setShowQuestionsModal(true)
    setNextQuestionSelect(question)
  }

  if (loading) return <LoadingSpinner />

  return (
    <div className="create-survey-container" style={{ height: '100%' }}>
      <DashboardHeader title={survey.name} description={survey.description} search={search} onSearch={handleSearchChange} />

      <div className="survey-comments">
        <div className="survey-comments-question-selector" style={{ visibility: paginatedComments.length > 0 ? 'visible' : 'hidden' }}>
          <div className="survey-comments-question-title">{question?.value}</div>
          <Button lg onClick={onClickSelectQuestion}>
            Select Question
          </Button>
        </div>

        <div className="survey-comments-list-board">
          {paginatedComments.length > 0 && (
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th className="options-cell" style={{ width: '80px' }}></th>
                  <th style={{ textAlign: 'left' }}>Comment</th>
                  <th className="date-cell" style={{ width: '120px' }}>
                    <u>Submitted On</u>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedComments.map(({ comment, dateSubmitted }, index) => {
                  if (!question) return null
                  return (
                    <tr key={index}>
                      <td className="options-cell"></td>
                      <td>{comment}</td>
                      <td className="date-cell">{timeToLocaleDate(dateSubmitted)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}

          {!paginatedComments.length && (
            <div className="survey-comments-list-missing">
              <div className="survey-comments-list-missing-header">No Results Found</div>
            </div>
          )}
        </div>

        <div className="survey-comments-page-list">
          <Paginator maxPaginatorDisplay={maxNumPageList} countPages={pageCount} currentPage={page} onPageChange={setPage} />
        </div>
      </div>

      <OwnerSurveyDashboardModals survey={survey} submit={props.submit} />

      <Modal isOpen={showQuestionsModal} onDismiss={() => setShowQuestionsModal(false)} styles={{ main: { borderRadius: 10 } }}>
        <div className="question-select-modal">
          <div>
            <IconButton
              iconProps={{
                iconName: 'Cancel'
              }}
              onClick={() => setShowQuestionsModal(false)}
              styles={{
                root: {
                  background: 'transparent',
                  color: 'var(--themePrimary)',
                  boxShadow: 'none',
                  float: 'right',
                  marginTop: '12px',
                  marginRight: '12px'
                },
                rootHovered: {
                  background: 'transparent',
                  color: 'var(--themeSecondary)',
                  boxShadow: 'none'
                },
                rootPressed: {
                  background: 'transparent',
                  color: 'var(--themeDark)',
                  boxShadow: 'none'
                },
                icon: {
                  fontSize: '20px',
                  fontWeight: 'bold'
                }
              }}
            />
          </div>

          <div className="question-select-body">
            <div className="question-select-title">Open-ended Questions</div>
            <div className="question-select-subtitle">Select Question to view comments</div>
            {allQuestions.map(q => {
              const isAnswered = isQuestionAnswered(q)
              return (
                <div
                  key={q.id}
                  onClick={() => isAnswered && setNextQuestionSelect(q)}
                  className={['question-select-row', !isAnswered ? 'question-select-row-disabled' : null].join(' ')}>
                  <div className="question-select-text">{q.value}</div>
                  <div className="question-select-radio">
                    <ChoiceGroupOption key={q.value} text={''} checked={nextQuestionSelect === q} />
                  </div>
                </div>
              )
            })}
          </div>

          <div className="question-select-footer">
            <Button
              ghost
              onClick={() => {
                setShowQuestionsModal(false)
              }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (nextQuestionSelect) {
                  setQuestion(nextQuestionSelect)
                }
                setShowQuestionsModal(false)
              }}>
              View
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
