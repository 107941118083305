import '../containers/css/welcome.css'

import React from 'react'

import { SignupForm } from '../containers'
import { AuthWrapper } from '../containers/AuthWrapper'

const Signup = () => (
  <AuthWrapper>
    <SignupForm />
  </AuthWrapper>
)

export default Signup
