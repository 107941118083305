import './css/surveyRespondentView.css'

import { AnimatedSubmitButton } from 'components/AnimatedSubmitButton'
import { Button } from 'components/Button'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SurveyProgressBar from '../components/SurveyProgressBar'
import { IAllAnswers, IAnswer, IQuestion, IUserResponse, IUserSurveyCopy, QuestionTypes } from '../types'
import { QuestionRespondentView } from './QuestionRespondentView'

interface ISurveyRespondentViewProps {
  survey: IUserSurveyCopy
  answerId?: string
  filterByTopicIds?: string[]
  submitResponses: (responses: IUserResponse) => void
  initialResponses?: IUserResponse
  updateResponses?: (responses: IUserResponse) => void
  isDisabled?: boolean
  customSubmitText?: string
  submitted?: boolean
  hasError?: boolean
}

type IQuestionWithIndex = IQuestion & {
  index: number
}

const updateWaitTime = 250

const createEmptyUserResponses = (question: IQuestion) => {
  const questionResponses: IAllAnswers = {}
  question.choices.forEach(resp => {
    questionResponses[resp.id] = {
      id: resp.id,
      value: false
    }
  })

  return questionResponses
}

const generateEmptyResponses = (questions: IQuestion[]): IUserResponse => {
  const emptyResponses: IUserResponse = {}
  questions.forEach(question => {
    emptyResponses[question.id] = {
      hasAnswer: false,
      answers: createEmptyUserResponses(question),
      importance: null
    }
  })

  return emptyResponses
}

export const SurveyRespondentView: FC<ISurveyRespondentViewProps> = ({
  survey,
  answerId,
  filterByTopicIds,
  initialResponses,
  updateResponses,
  isDisabled,
  customSubmitText,
  submitResponses,
  submitted = false,
  hasError = false
}) => {
  const filterTopicsSet = new Set(filterByTopicIds)

  const filteredQuestions = survey.questions.filter(q => {
    if (filterTopicsSet.size) {
      return filterTopicsSet.has(q.topic) || !q.topic
    } else {
      return !q.topic
    }
  })

  const [userResponses, setUserResponses] = useState(initialResponses ? initialResponses : generateEmptyResponses(survey.questions))

  useEffect(() => {
    if (initialResponses) setUserResponses(initialResponses)
  }, [initialResponses])

  const [lastUpdatedResponses, setLastUpdated] = useState(Date.now())
  const [answered, setAnswered] = useState(0)
  const history = useHistory()

  const questionsPerPage = 1
  const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage)
  const [currentPage, setCurrentPage] = useState(0)

  const [displayedQuestions, setDisplayedQuestions] = useState<IQuestionWithIndex[]>(
    filteredQuestions.length ? [{ ...filteredQuestions[0], index: 0 }] : []
  )

  useEffect(() => {
    const incrementAnswered = () => {
      const count = filteredQuestions.reduce((accumulator, question) => {
        const hasAnswer = userResponses[question.id].hasAnswer

        if (hasAnswer) {
          return accumulator + 1
        } else {
          return accumulator
        }
      }, 0)

      setAnswered(count)
    }

    if (survey.dateSubmitted) {
      history.push(`/analyze-survey/${survey.id}/${answerId}`)
    }

    incrementAnswered()
  }, [userResponses])

  useEffect(() => {
    if (filteredQuestions.length) {
      const questionsWithIndex: IQuestionWithIndex[] = filteredQuestions.map((question, index) => ({ ...question, index }))
      const questionsSliced = questionsWithIndex.splice(questionsPerPage * currentPage, questionsPerPage)
      setDisplayedQuestions(questionsSliced)
    }
  }, [currentPage, questionsPerPage])

  const questionById = (questionId: string) => {
    const question = filteredQuestions.filter(question => question.id === questionId)[0]
    return question
  }

  const setNewResponse = async (questionId: string, responseId: string, value: string | boolean) => {
    const question = questionById(questionId)
    const userQuestionResponse = userResponses[questionId].answers[responseId]

    let newResponses: IUserResponse = {
      ...userResponses,
      [question.id]: { hasAnswer: false, answers: {}, importance: null }
    }

    if (question.type === QuestionTypes.trueFalse || question.type === QuestionTypes.multipleChoice) {
      let newQuestionResponses: IAllAnswers = {}
      question.choices.forEach(resp => {
        // if checked this response, check it. other response is unchecked
        if (resp.id === responseId) {
          newQuestionResponses[resp.id] = {
            id: resp.id,
            value: true
          }
        } else {
          newQuestionResponses[resp.id] = {
            id: resp.id,
            value: false
          }
        }
        newResponses[question.id].answers = newQuestionResponses
        newResponses[question.id].hasAnswer = true
        newResponses[question.id].importance = userResponses[question.id].importance
      })
    } else {
      const newQuestionResponse: IAnswer = {
        ...userQuestionResponse,
        value
      }
      newResponses = {
        ...userResponses,
        [questionId]: {
          hasAnswer: false,
          answers: { ...userResponses[questionId].answers, [responseId]: newQuestionResponse },
          importance: userResponses[question.id].importance
        }
      }
      Object.values(newResponses[questionId].answers).forEach(r => {
        if (r.value) {
          newResponses[questionId].hasAnswer = true
        }
      })
    }

    const nowDate = Date.now()

    if (nowDate - lastUpdatedResponses > updateWaitTime) {
      updateResponses && updateResponses(newResponses)
      setLastUpdated(nowDate)
    }

    setUserResponses(newResponses)
  }

  const onChangeOpenEnded = (questionId: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement
    const value = target.value
    const newResponse = value
    const responseId = Object.values(userResponses[questionId].answers)[0].id

    setNewResponse(questionId, responseId, newResponse)
  }

  const onCheck = (questionId: string, responseId: string) => {
    const response = userResponses[questionId].answers[responseId].value

    setNewResponse(questionId, responseId, !response)
  }

  const onChangeImportance = (questionId: string) => (importance?: number) => {
    const newResponses = {
      ...userResponses,
      [questionId]: {
        ...userResponses[questionId],
        importance: importance === undefined ? null : importance
      }
    }
    setUserResponses(newResponses)
    updateResponses && updateResponses(newResponses)
  }

  const handleCurrentPage = (currentPage: number) => {
    document.getElementsByClassName('survey-respondentview-container-outer')[0].scrollTo(0, 0)
    document.getElementsByClassName('app')[0].scrollTo(0, 0)

    setCurrentPage(currentPage)
  }

  const submitSurvey = () => {
    submitResponses(userResponses)
  }

  let isAllQuestionsOnPageAnswered = true
  for (let q of displayedQuestions) {
    if (!userResponses[q.id].hasAnswer && q.required) {
      isAllQuestionsOnPageAnswered = false
      break
    }
  }

  return (
    <div className="container user-container">
      <div className="survey-respondentview-progressbar-wrapper">
        <SurveyProgressBar numberOfQuestions={filteredQuestions.length} numberOfQuestionsCompleted={answered} />
      </div>
      <div className="survey-respondentview-container-outer">
        {currentPage === 0 && (
          <div className="survey-respondentview-header">
            <div className="survey-respondentview-title">{survey.name ? survey.name : 'Untitled Survey'}</div>
            {/* <div className="survey-respondentview-description">{survey.description ? survey.description : 'No description'}</div> */}
            <div className="survey-respondentview-description">{survey.description ?? ''}</div>
          </div>
        )}
        {!filteredQuestions.length && <EmptyQuestionsView />}
        {displayedQuestions.map(question => (
          <QuestionRespondentView
            index={question.index}
            key={question.id}
            question={question}
            importance={userResponses[question.id].importance === null ? undefined : userResponses[question.id].importance}
            onCheck={responseId => onCheck(question.id, responseId)}
            onChangeOpenEnded={onChangeOpenEnded(question.id)}
            onChangeImportance={onChangeImportance(question.id)}
            userQuestionResponses={userResponses[question.id].answers || {}}
            onBlurText={() => updateResponses && updateResponses(userResponses)}
            isDisabled={isDisabled}
          />
        ))}
        <div className="survey-respondentview-submit">
          {totalPages > 0 && (
            <div className="survey-respondentview-submit-group">
              {currentPage !== 0 && <Button onClick={() => handleCurrentPage(currentPage - 1)}>Previous</Button>}
              {currentPage + 1 !== totalPages && (
                <Button disabled={!isAllQuestionsOnPageAnswered} onClick={() => handleCurrentPage(currentPage + 1)}>
                  Next
                </Button>
              )}
            </div>
          )}
          {totalPages > 0 && currentPage + 1 === totalPages ? (
            <div className="survey-respondentview-agreement">
              By clicking submit, you certify that this is you and these are your answers.
              <div className="survey-respondentview-agreement-submit">
                <AnimatedSubmitButton
                  onClick={submitSurvey}
                  renderContentUnsubmitted={() => <div>{customSubmitText || (isDisabled ? 'Submitted' : 'Submit Survey')}</div>}
                  renderContentSubmitted={() => <div>Successful</div>}
                  renderContentError={() => <div>Submit Failed</div>}
                  disabled={isDisabled || !isAllQuestionsOnPageAnswered}
                  submitted={submitted}
                  hasError={hasError}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const EmptyQuestionsView = () => {
  const history = useHistory()

  return (
    <div className="survey-respondentview-empty">
      <div>No Applicable Questions</div>
      <div>
        Thanks for your interest in this survey! It looks like you didn’t select any topics this survey is asking about, so there are no
        questions to answer.
      </div>
      <div className="survey-respondentview-empty-buttons">
        <Button onClick={() => history.push('/')}>Back to Dashboard</Button>
        <Button onClick={() => history.push('/account-settings')}>Review my topics</Button>
      </div>
    </div>
  )
}
