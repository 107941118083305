export default class EventInterfaceBase {
  _eventCallbacks: { [key: string]: Set<(...args: any[]) => void> }
  _onetimeCallbacks: { [key: string]: Set<(...args: any[]) => void> }

  constructor() {
    this._eventCallbacks = {}
    this._onetimeCallbacks = {}
  }

  addEventCallbacks = (eventTypes: string[]) => {
    for (const e of eventTypes) {
      if (!this._eventCallbacks[e]) this._eventCallbacks[e] = new Set()
      if (!this._onetimeCallbacks[e]) this._onetimeCallbacks[e] = new Set()
    }
  }

  addEventListener = (event: string, callback: (...args: any[]) => void) => {
    if (!this._eventCallbacks[event]) this._eventCallbacks[event] = new Set()
    this._eventCallbacks[event].add(callback)
  }

  removeEventListener(event: string, callback: (...args: any[]) => void) {
    if (!this._eventCallbacks[event]) return
    this._eventCallbacks[event].delete(callback)
  }

  removeAllEventListener(event: string) {
    if (!this._eventCallbacks[event]) return
    this._eventCallbacks[event].clear()
  }

  addEventListenerOnce(event: string, callback: (...args: any[]) => void) {
    if (!this._onetimeCallbacks[event]) this._onetimeCallbacks[event] = new Set()
    this._onetimeCallbacks[event].add(callback)
  }

  removeEventListenerOnce(event: string, callback: (...args: any[]) => void) {
    if (!this._onetimeCallbacks[event]) return
    this._onetimeCallbacks[event].delete(callback)
  }

  removeAllEventListenerOnce(event: string) {
    if (!this._onetimeCallbacks[event]) return
    this._onetimeCallbacks[event].clear()
  }

  trigger(event: string, ...props: any[]) {
    if (this._eventCallbacks[event]) {
      this._eventCallbacks[event].forEach(f => {
        f(...props)
      })
    }

    if (this._onetimeCallbacks[event]) {
      this._onetimeCallbacks[event].forEach(f => {
        f(...props)
      })
      this._onetimeCallbacks[event].clear()
    }
  }

  on(event: string, callback: (...args: any[]) => void) {
    this.addEventListener(event, callback)
  }

  onNext(event: string, callback: (...args: any[]) => void) {
    this.addEventListenerOnce(event, callback)
  }

  off(event: string, callback: (...args: any[]) => void) {
    this.removeEventListener(event, callback)
  }

  offNext(event: string, callback: (...args: any[]) => void) {
    this.removeEventListenerOnce(event, callback)
  }

  offAll(event: string) {
    this.removeAllEventListener(event)
  }

  offNextAll(event: string) {
    this.removeAllEventListenerOnce(event)
  }

  dispose() {
    this._eventCallbacks = {}
    this._onetimeCallbacks = {}
  }
}
