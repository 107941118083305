import React from 'react'
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer } from 'recharts'

import { chartNaColor, chartPrimaryColorMap, chartSecondaryColorMap, primaryBackgroundToLabelMap } from './ChartColors'

const RADIAN = Math.PI / 180

const renderLabel = (props: PieLabelRenderProps, isOuterLayer?: boolean) => {
  const { payload, innerRadius, outerRadius, cx, cy, midAngle, percent, fill } = props

  const nInnerRadius = Number(innerRadius)
  const nOuterRadius = Number(outerRadius)
  const nMidAngle = Number(midAngle)
  const nCx = Number(cx)
  const nCy = Number(cy)

  if (!percent || percent <= 0) return null

  let factor = 0.6
  // let useDefault = false
  if (percent < 0.05) {
    factor = 1.25
    //   useDefault = true
  }

  const radius = nInnerRadius + (nOuterRadius - nInnerRadius) * factor
  const x = nCx + radius * Math.cos(-nMidAngle * RADIAN)
  const y = nCy + radius * Math.sin(-nMidAngle * RADIAN)

  let labelFill
  if (isOuterLayer) {
    labelFill = props.name && chartSecondaryColorMap[props.name] ? chartSecondaryColorMap[props.name].labelColor : 'var(--white)'
  } else {
    labelFill = primaryBackgroundToLabelMap[fill || '']
  }

  return (
    <text x={x} y={y} fill={labelFill} textAnchor="middle" dominantBaseline="central" fontSize={12}>
      {(isOuterLayer && payload.label) || (isOuterLayer && payload.name)} {!isOuterLayer && `${Math.floor(percent * 100)}%`}
    </text>
  )
}

export interface IVisChartDataEntry {
  label?: string
  name: string
  value: number
  backgroundColor?: string
  labelColor?: string
}

export interface ICustomPieChartProps {
  width?: number
  height?: number
  radius?: number
  // showLegend?: boolean
  data: IVisChartDataEntry[]
  countImportance?: { [importance: string]: number }
  outerEndAngle?: number
}

const rotateMod = 180

export function CustomPieChart(props: ICustomPieChartProps) {
  const { data, countImportance } = props

  let importanceArr: { name: string; value: number | null }[] | undefined = undefined

  if (countImportance) {
    importanceArr = Object.entries(countImportance).map(([importanceKey, count]) => {
      return {
        name: importanceKey,
        value: count
      }
    })
  }

  return (
    <ResponsiveContainer width={props.width || '100%'} height={props.height || 300}>
      <PieChart width={props.width} height={props.height}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={!importanceArr ? 80 : 40}
          outerRadius={!importanceArr ? 120 : 80}
          fill="#8884d8"
          labelLine={false}
          startAngle={360 + rotateMod}
          endAngle={0 + rotateMod}
          label={renderLabel}>
          {data.map((entry, index) => {
            const backgroundColor = entry.backgroundColor || chartPrimaryColorMap[index % chartPrimaryColorMap.length].backgroundColor
            const labelColor = entry.labelColor || chartPrimaryColorMap[index % chartPrimaryColorMap.length].labelColor
            return <Cell key={entry.name} fill={backgroundColor} data-label-color={labelColor} />
          })}
        </Pie>
        {importanceArr && (
          <Pie
            data={importanceArr}
            dataKey="value"
            innerRadius={80}
            outerRadius={120}
            startAngle={360 + rotateMod}
            endAngle={360 - (360 - (props.outerEndAngle || 0)) + rotateMod}
            fill="#82ca9d"
            labelLine={false}
            label={props => renderLabel(props, true)}>
            {importanceArr.map(entry => (
              <Cell
                key={entry.name}
                fill={
                  entry.name && chartSecondaryColorMap[entry.name]
                    ? chartSecondaryColorMap[entry.name].backgroundColor
                    : chartNaColor.backgroundColor
                }
              />
            ))}
          </Pie>
        )}
      </PieChart>
    </ResponsiveContainer>
  )
}
