import './CollapseButton.css'

import { Icon } from '@fluentui/react'
import React from 'react'

export interface ICollapseButtonProps {
  onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isCollapsed?: boolean
  width?: number | string
}

function CollapseButton(props: ICollapseButtonProps) {
  const { onClick, isCollapsed, width } = props
  return (
    <div
      data-testid="collapse-button-container"
      className={['flex-row-centered', 'collapse-icon', isCollapsed ? 'is-flipped' : undefined].join(' ')}
      onClick={onClick}
      style={{ width }}>
      <Icon iconName={'ChevronDownMed'} />
    </div>
  )
}

export default CollapseButton
