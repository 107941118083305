import { DefaultButton, IButtonStyles, PrimaryButton } from '@fluentui/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ButtonTypes } from 'shared-types'

interface IButtonProps {
  type?: 'submit' | 'button'
  responsive?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  ghost?: boolean
  xl?: boolean
  lg?: boolean
  md?: boolean
  sm?: boolean
  xs?: boolean
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  text?: string
  link?: boolean
  href?: string
  icon?: string
  onClick?: () => void
  disabled?: boolean
}

export const Button: FC<IButtonProps> = ({
  children,
  type = 'button',
  responsive,
  primary,
  secondary,
  tertiary,
  ghost,
  xl,
  lg,
  md,
  sm,
  xs,
  mt,
  mr,
  mb,
  ml,
  text,
  link,
  href,
  icon,
  onClick,
  disabled
}) => {
  const buttonType = primary
    ? ButtonTypes.primary
    : secondary
    ? ButtonTypes.secondary
    : tertiary
    ? ButtonTypes.tertiary
    : ghost
    ? ButtonTypes.ghost
    : ButtonTypes.secondary
  const ButtonComponent =
    buttonType === ButtonTypes.secondary
      ? PrimaryButton
      : buttonType === ButtonTypes.tertiary
      ? PrimaryButton
      : buttonType === ButtonTypes.ghost
      ? DefaultButton
      : PrimaryButton
  const styles: IButtonStyles = {
    root: {
      minWidth: `${responsive ? '100%' : xl ? 260 : lg ? 185 : md ? 145 : sm ? 90 : xs ? 65 : 145}px`,
      height: sm ? 32 : xs ? 28 : 45,
      background:
        buttonType === ButtonTypes.primary
          ? 'var(--themePrimary)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--themeSecondary)'
          : buttonType === ButtonTypes.tertiary
          ? 'var(--themeTertiary)'
          : buttonType === ButtonTypes.ghost
          ? 'transparent'
          : undefined,
      boxShadow:
        buttonType === ButtonTypes.primary
          ? 'var(--buttonPrimaryShadow)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--buttonSecondaryShadow)'
          : buttonType === ButtonTypes.ghost
          ? 'var(--buttonPrimaryShadow)'
          : 'var(--buttonPrimaryShadow)',
      marginTop: mt ? mt : undefined,
      marginRight: mr ? mr : undefined,
      marginBottom: mb ? mb : undefined,
      marginLeft: ml ? ml : undefined,
      fontSize: sm ? 14 : xs ? '0.7rem' : 18
    },
    rootHovered: {
      background:
        buttonType === ButtonTypes.primary
          ? 'var(--themeSecondary)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--themePrimary)'
          : buttonType === ButtonTypes.tertiary
          ? 'var(--themeSecondary)'
          : buttonType === ButtonTypes.ghost
          ? 'transparent'
          : 'var(--themeSecondary)',
      boxShadow:
        buttonType === ButtonTypes.primary
          ? 'var(--buttonSecondaryShadow)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--buttonPrimaryShadow)'
          : buttonType === ButtonTypes.ghost
          ? 'var(--buttonSecondaryShadow)'
          : 'var(--buttonPrimaryShadow)'
    },
    rootPressed: {
      boxShadow: 'var(--buttonPressedShadow)'
    },
    rootDisabled: {
      background:
        buttonType === ButtonTypes.primary
          ? 'var(--themePrimaryDisabled)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--themeSecondaryDisabled)'
          : buttonType === ButtonTypes.ghost
          ? 'transparent'
          : 'var(--themeSecondaryDisabled)',
      boxShadow:
        buttonType === ButtonTypes.primary
          ? 'var(--buttonPrimaryDisabledShadow)'
          : buttonType === ButtonTypes.secondary
          ? 'var(--buttonSecondaryDisabledShadow)'
          : buttonType === ButtonTypes.ghost
          ? 'var(--buttonPrimaryDisabledShadow)'
          : 'var(--buttonPrimaryDisabledShadow)',
      border: buttonType === ButtonTypes.ghost ? '2px solid var(--themePrimaryDisabled)' : undefined
    },
    label: {
      fontSize: xl ? 18 : xs ? '0.7rem' : undefined
    }
  }

  if (link) {
    return (
      <ButtonComponent as={Link as any} text={text} href={href} disabled={disabled} styles={styles} iconProps={{ iconName: icon }}>
        {children}
      </ButtonComponent>
    )
  }

  return (
    <ButtonComponent type={type} text={text} onClick={onClick} disabled={disabled} styles={styles} iconProps={{ iconName: icon }}>
      {children}
    </ButtonComponent>
  )
}
