import './SearchInput.css'

import { IconButton, ISearchBox, SearchBox } from '@fluentui/react'
import React, { ChangeEvent, createRef, FC, useEffect, useState } from 'react'

interface ISearchInputProps {
  value?: string
  title?: string
  mt?: number
  mb?: number
  onChange: (event?: ChangeEvent<HTMLInputElement | undefined> | undefined, value?: string) => void
}

export const SearchInput: FC<ISearchInputProps> = ({ value, title, mt, mb, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const searchRef = createRef<ISearchBox>()

  useEffect(() => {
    if (isOpen) searchRef.current?.focus()

    onChange(undefined, '')
  }, [isOpen])

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setIsOpen(false)
    }
  }

  const handleToggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="search-input" style={{ marginTop: mt ? mt : undefined, marginBottom: mb ? mb : undefined }}>
      {title && <h1 className="search-input-title">{title}</h1>}
      {isOpen ? (
        <SearchBox
          componentRef={searchRef}
          value={value}
          onChange={(event, value) => onChange(event, value)}
          onBlur={handleBlur}
          iconProps={{ iconName: undefined }}
          styles={{ root: { width: '100%', marginRight: 10 } }}
        />
      ) : (
        <IconButton
          data-testid="search-button"
          iconProps={{ iconName: 'Search' }}
          onClick={handleToggleOpen}
          styles={{
            root: { background: 'transparent', boxShadow: 'none' },
            rootHovered: { background: 'transparent', boxShadow: 'none' }
          }}
        />
      )}
    </div>
  )
}
