import './index.css'

import { Link } from '@fluentui/react'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext, OnlineStatus, signOut } from '../../contexts/authContext'
import { DataCacheContext } from '../../contexts/dataCacheContext'
import { UserTypes } from '../../types'
import { AvatarDropdown } from './AvatarDropdown'

export enum NavigateOptions {
  surveys,
  upgrade,
  settings,
  signout
}

export interface IHeaderProps {}

const Header = (props: IHeaderProps) => {
  const { userInfo, online } = useContext(AuthContext)
  const { userType } = useContext(DataCacheContext)
  const history = useHistory()

  const headerNavigate = async (option: NavigateOptions) => {
    switch (option) {
      case NavigateOptions.surveys:
        history.push('/dashboard')
        break
      case NavigateOptions.settings:
        history.push('/account-settings')
        break
      case NavigateOptions.upgrade:
        history.push('/dashboard')
        break
      case NavigateOptions.signout:
        await signOut()
        history.push('/')
        break
      default:
        break
    }
  }

  return (
    <div className="header-container">
      <div className="header-logo">
        <Link href={userType === UserTypes.owner || userType === UserTypes.admin ? '/#/dashboard' : '/#/surveys'}>
          <img alt="ProxyMatch logo" height="34px" width="auto" src="./PM_1color@2x.png" />
        </Link>
      </div>

      <div style={{ flex: 1 }}></div>

      {/* display user logo if logged in */}
      {online === OnlineStatus.SignedIn && userInfo && (
        <div className="header-avatar">
          <AvatarDropdown headerNavigate={headerNavigate} />
        </div>
      )}

      {/* display log in button if not */}
      {online === OnlineStatus.SignedOut && history.location.pathname !== '/login' ? (
        <div className="header-login">
          <Link style={{ fontSize: '18px', textDecoration: 'none', color: 'var(--pvmLogo)' }} href="#/login">
            Login
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default Header
