import { DatePicker, Dropdown, IDropdownOption } from '@fluentui/react'
import React from 'react'

const getHours = (min: number, max: number) => {
  const ret: IDropdownOption[] = []
  for (let i = min; i <= max; i++) {
    ret.push({
      key: i,
      text: String(i).padStart(2, '0')
    })
  }
  return ret
}

const amHours = getHours(0, 11)
const pmHours = getHours(1, 11)
pmHours.unshift({
  key: 12,
  text: '12'
})

const getMinutes = () => {
  const ret: IDropdownOption[] = []
  for (let i = 0; i < 60; i += 5) {
    ret.push({
      key: i,
      text: String(i).padStart(2, '0')
    })
  }
  return ret
}
const minutes = getMinutes()

const ampm: IDropdownOption[] = [
  {
    key: 'am',
    text: 'AM'
  },
  {
    key: 'pm',
    text: 'PM'
  }
]

export interface IPreciseTimePickerProps {
  value: Date
  isDisabled?: boolean
  onChange: (newValue: Date) => void
}

function onFormatDate(value?: Date) {
  if (!value) return ''

  const day = value.getDate()
  const month = value.getMonth()
  const year = value.getFullYear()

  return `${month + 1}/${day}/${year}`
}

function PreciseTimePicker(props: IPreciseTimePickerProps) {
  const { value, isDisabled, onChange } = props

  const day = value.getDate()
  const month = value.getMonth()
  const year = value.getFullYear()

  let hour = value.getHours()
  let beforeNoon = true
  let min = value.getMinutes()
  min = Math.floor(min / 5) * 5

  if (hour >= 12) {
    beforeNoon = false
  }

  const onSelectDay = (newVal?: Date | null) => {
    if (!newVal) return

    const newDay = newVal.getDate()
    const newMonth = newVal.getMonth()
    const newYear = newVal.getFullYear()
    onChange(new Date(newYear, newMonth, newDay, hour, min))
  }

  const onSelectHour = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
    let newHour = option && Number(option.key)
    if (newHour === undefined) return

    if (!beforeNoon && newHour < 12) {
      newHour += 12
    }
    onChange(new Date(year, month, day, newHour, min))
  }

  const onSelectMin = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
    let newMin = option && Number(option.key)
    if (newMin === undefined) return

    onChange(new Date(year, month, day, hour, newMin))
  }

  const onSelectAmPm = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
    const newBeforeNoon = option && String(option.key) === 'am'
    if (newBeforeNoon === undefined) return

    let newHour = hour
    // change pm to am
    if (newBeforeNoon && !beforeNoon) {
      newHour -= 12
    }

    // change am to pm
    if (!newBeforeNoon && beforeNoon) {
      newHour += 12
    }

    onChange(new Date(year, month, day, newHour, min))
  }

  return (
    <div className="flex-row-centered">
      <DatePicker
        placeholder={'Select a date'}
        value={value}
        disabled={isDisabled}
        onSelectDate={onSelectDay}
        styles={{ root: { paddingRight: '12px' } }}
        minDate={new Date()}
        formatDate={onFormatDate}
      />
      <Dropdown
        options={beforeNoon ? amHours : pmHours}
        selectedKey={hour > 12 ? hour - 12 : hour}
        disabled={isDisabled}
        styles={{ title: { minWidth: '2rem' }, caretDown: { fontSize: '12px' } }}
        onChange={onSelectHour}
      />
      <div style={{ fontWeight: 'bold', fontSize: '18px' }}>:</div>
      <Dropdown
        options={minutes}
        selectedKey={min}
        disabled={isDisabled}
        styles={{ title: { minWidth: '2rem' }, caretDown: { fontSize: '12px' } }}
        onChange={onSelectMin}
      />
      <Dropdown
        options={ampm}
        selectedKey={beforeNoon ? 'am' : 'pm'}
        disabled={isDisabled}
        styles={{ title: { minWidth: '2rem' }, caretDown: { fontSize: '12px' } }}
        onChange={onSelectAmPm}
      />
    </div>
  )
}

export default PreciseTimePicker
