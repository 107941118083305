import { useCallback, useRef } from 'react'

export interface IAutosaveState<I> {
  timeoutSinceLastSaved: number
  isSaving: boolean
  saveQueued: boolean
  queuedInput?: I
}

export interface IAutosaver<I> {
  autosave: (input: I) => Promise<void>
  state: autosaveState<I>
}

export default function useAutosave<T, I>(
  saveFunc?: (input: I) => Promise<T> | T,
  onDoneSave?: (retValue: T) => void | Promise<void>,
  minTimeout: number = 1000
): IAutosaver<I> {
  const state = useRef<IAutosaveState<I>>({
    timeoutSinceLastSaved: 0,
    isSaving: false,
    saveQueued: false,
    queuedInput: undefined
  })

  const autosave = useCallback(
    async function tryAutoSave(input: I) {
      if (!saveFunc) return

      if (state.current.isSaving) {
        state.current.saveQueued = true
        state.current.queuedInput = input
        return
      }
      state.current.isSaving = true

      const timeElapsed = Date.now() - state.current.timeoutSinceLastSaved
      if (timeElapsed < minTimeout) {
        await new Promise(resolve => setTimeout(resolve, minTimeout - timeElapsed))
      }

      const val = await saveFunc(input)
      if (onDoneSave) await onDoneSave(val)
      state.current.timeoutSinceLastSaved = Date.now()
      state.current.isSaving = false

      if (state.current.saveQueued) {
        state.current.saveQueued = false
        const queuedInput = state.current.queuedInput
        state.current.queuedInput = undefined
        tryAutoSave(queuedInput!)
      }
    },
    [saveFunc, onDoneSave, minTimeout, state]
  )

  return { autosave, state: state.current }
}

export interface autosaveState<I> {
  timeoutSinceLastSaved: number
  isSaving: boolean
  saveQueued: boolean
  queuedInput?: I
}

export function createAutosaver<T, I>(
  saveFunc: (input: I) => Promise<T>,
  onDoneSave?: (retValue: T) => void | Promise<void>,
  minTimeout: number = 1000
): IAutosaver<I> {
  const state: autosaveState<I> = {
    timeoutSinceLastSaved: 0,
    isSaving: false,
    saveQueued: false,
    queuedInput: undefined
  }

  async function autosave(input: I) {
    if (state.isSaving) {
      state.saveQueued = true
      state.queuedInput = input
      return
    }
    state.isSaving = true

    const timeElapsed = Date.now() - state.timeoutSinceLastSaved
    if (timeElapsed < minTimeout) {
      await new Promise(resolve => setTimeout(resolve, minTimeout - timeElapsed))
    }

    const val = await saveFunc(input)
    if (onDoneSave) await onDoneSave(val)
    state.timeoutSinceLastSaved = Date.now()
    state.isSaving = false

    if (state.saveQueued) {
      state.saveQueued = false
      const queuedInput = state.queuedInput
      state.queuedInput = undefined
      autosave(queuedInput!)
    }
  }

  return { autosave, state }
}
