import './css/Toggle.css'

import React from 'react'

interface IRectangleToggleProps extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean
}

const RectangleToggle: React.FC<IRectangleToggleProps> = props => {
  const { checked, children, className, ...otherProps } = props

  return (
    <div {...otherProps} className={[checked ? 'rectangle-toggle-checked' : 'rectangle-toggle-unchecked', className].join(' ')}>
      {children}
    </div>
  )
}

export { RectangleToggle }
