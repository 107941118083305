import 'draft-js/dist/Draft.css'
import './RichTextEditor.css'

import { IPoint } from '@fluentui/react'
import { AlertType, AppStateContext } from 'contexts/appState'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertToRaw,
  DraftEditorCommand,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState
} from 'draft-js'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'

import { Editor } from './Editor'
import { Link } from './Link'
import { LinkCallout } from './LinkCallout'
import { Toolbar } from './Toolbar'

interface IRichTextEditorProps {
  id: string
  value: EditorState
  placeholder?: string
  onChange: (value: string) => void
  isDisabled?: boolean
  readOnly?: boolean
}

const MAX_LENGTH = 359

export const RichTextEditor: FunctionComponent<IRichTextEditorProps> = ({ id, value, placeholder, onChange, isDisabled, readOnly }) => {
  const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()

      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
  }
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [linkSelected, setLinkSelected] = useState(false)
  const [initialText, setInitialText] = useState('')
  const [initialLink, setInitialLink] = useState('')
  const [isCreating, setIsCreating] = useState(false)
  const [target, setTarget] = useState<IPoint>({ x: 382, y: 406 })
  const { addAlert } = useContext(AppStateContext)

  const handleLinkModalOpen = (value: boolean, create: boolean, selectionText: string, link: string) => {
    setIsCreating(create)
    setInitialText(selectionText)
    setInitialLink(link)
    setLinkModalOpen(value)
  }

  const handleBlockStyle = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType()

    if (type === 'blockquote') {
      return 'rich-text-editor-blockquote'
    }

    if (type === 'text-align-left') {
      return 'rich-text-editor-left'
    }
    if (type === 'text-align-center') {
      return 'rich-text-editor-center'
    }
    if (type === 'text-align-right') {
      return 'rich-text-editor-right'
    }

    return ''
  }

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      handleChange(newState)

      return 'handled'
    }

    return 'not-handled'
  }

  const getLinkSelected = (editorState: EditorState) => {
    const selection = editorState.getSelection()
    const windowSelection = window.getSelection()

    if (windowSelection) {
      const oRange = windowSelection.rangeCount > 0 ? windowSelection.getRangeAt(0) : { getBoundingClientRect: () => ({ left: 0, top: 0 }) }
      const cursorBounds = oRange.getBoundingClientRect()
      const x = cursorBounds.left
      const y = cursorBounds.top + 20

      if (x <= 0 && y <= 20) {
        setTarget({ x: 382, y: 406 })
      } else {
        setTarget({ x, y })
      }
    }

    if (!selection.isCollapsed()) {
      return RichUtils.currentBlockContainsLink(editorState)
    } else {
      const key = selection.getStartKey()
      const block = editorState.getCurrentContent().getBlockForKey(key)
      const entity = block.getEntityAt(selection.getStartOffset())

      return entity !== null
    }
  }

  const handleChange = (editorState: EditorState) => {
    const raw = JSON.stringify(convertToRaw(editorState.getCurrentContent()))

    onChange(raw)
    setEditorState(editorState)

    if (getLinkSelected(editorState)) {
      setLinkSelected(true)
    } else {
      setLinkSelected(false)
    }
  }

  const handleLink = (text: string, url: string, create: boolean) => {
    let currentEntitySelection: SelectionState
    const contentState = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    const currentSelectionOffset = currentSelection.getStartOffset()
    const currentSelectionIsCollapsed = currentSelection.isCollapsed()
    const currentSelectionDirection = currentSelection.getIsBackward() ? 'backward' : 'forward'
    const currentBlock = contentState.getBlockForKey(currentSelection.getStartKey())
    const currentBlockKey = currentBlock.getKey()

    // if we are creating a new link (not editing)
    if (create) {
      const currentCollapsedSelection = new SelectionState({
        // start of selection
        anchorKey: currentBlockKey,
        anchorOffset: currentSelectionOffset,
        // end of selection
        focusKey: currentBlockKey,
        focusOffset: currentSelectionOffset
      })
      if (currentSelectionIsCollapsed) {
        // if there is no selection currently, insert the text and creeate link,
        const contentStateWithEntityText = Modifier.insertText(contentState, currentCollapsedSelection, text)
        const contentStateWithEntity = contentStateWithEntityText.createEntity('LINK', 'IMMUTABLE', { text, url })
        const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey()
        const newBlockWithEntity = contentStateWithEntity.getBlockForKey(currentCollapsedSelection.getStartKey())
        const newSelectionOfEntity = new SelectionState({
          anchorKey: newBlockWithEntity.getKey(),
          anchorOffset: currentCollapsedSelection.getStartOffset(),
          focusKey: newBlockWithEntity.getKey(),
          focusOffset: currentCollapsedSelection.getStartOffset() + text.length
        })
        const newEditorStateWithEntity = EditorState.set(editorState, {
          currentContent: contentStateWithEntity
        })
        const newEditorStateWithEntityAndSelection = EditorState.acceptSelection(newEditorStateWithEntity, newSelectionOfEntity)

        handleChange(RichUtils.toggleLink(newEditorStateWithEntity, newEditorStateWithEntityAndSelection.getSelection(), newEntityKey))
      } else {
        // if there is a selection, remove old text, insert new at same start point
        const contentStateWithoutOldText = Modifier.removeRange(contentState, currentSelection, currentSelectionDirection)
        const contentStateWithEntityText = Modifier.insertText(contentStateWithoutOldText, currentCollapsedSelection, text)
        const contentStateWithEntity = contentStateWithEntityText.createEntity('LINK', 'IMMUTABLE', { text, url })
        const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey()
        const newBlockWithEntity = contentStateWithEntity.getBlockForKey(currentCollapsedSelection.getStartKey())
        const newSelectionOfEntity = new SelectionState({
          anchorKey: newBlockWithEntity.getKey(),
          anchorOffset: currentCollapsedSelection.getStartOffset(),
          focusKey: newBlockWithEntity.getKey(),
          focusOffset: currentCollapsedSelection.getStartOffset() + text.length
        })
        const newEditorStateWithEntity = EditorState.set(editorState, {
          currentContent: contentStateWithEntity
        })
        const newEditorStateWithEntityAndSelection = EditorState.acceptSelection(newEditorStateWithEntity, newSelectionOfEntity)

        handleChange(RichUtils.toggleLink(newEditorStateWithEntity, newEditorStateWithEntityAndSelection.getSelection(), newEntityKey))
      }
    } else {
      // goes through the current block, and gets each entity
      findLinkEntities(
        currentBlock,
        (entityStart, entityEnd) => {
          // determines the current entity based on where the cursor (selection start) is
          const isCurrentEntity = currentSelectionOffset >= entityStart && currentSelectionOffset <= entityEnd

          if (isCurrentEntity) {
            currentEntitySelection = new SelectionState({
              // start of selection
              anchorKey: currentBlockKey,
              anchorOffset: entityStart,
              // end of selection
              focusKey: currentBlockKey,
              focusOffset: entityEnd
            })

            const currentCollapsedSelection = new SelectionState({
              // start of selection
              anchorKey: currentBlockKey,
              anchorOffset: currentEntitySelection.getStartOffset(),
              // end of selection
              focusKey: currentBlockKey,
              focusOffset: currentEntitySelection.getStartOffset()
            })
            const currentEntitySelectionDirection = 'forward'
            // remove current entity (will be replacing it)
            const contentStateWithoutOldEntity = Modifier.removeRange(contentState, currentEntitySelection, currentEntitySelectionDirection)
            const contentStateWithEntityText = Modifier.insertText(contentStateWithoutOldEntity, currentCollapsedSelection, text)
            const contentStateWithEntity = contentStateWithEntityText.createEntity('LINK', 'IMMUTABLE', { text, url })
            const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey()
            const newBlockWithEntity = contentStateWithEntity.getBlockForKey(currentCollapsedSelection.getStartKey())
            const newSelectionOfEntity = new SelectionState({
              anchorKey: newBlockWithEntity.getKey(),
              anchorOffset: currentCollapsedSelection.getStartOffset(),
              focusKey: newBlockWithEntity.getKey(),
              focusOffset: currentCollapsedSelection.getStartOffset() + text.length
            })
            const newEditorStateWithEntity = EditorState.set(editorState, {
              currentContent: contentStateWithEntity
            })
            const newEditorStateWithEntityAndSelection = EditorState.acceptSelection(newEditorStateWithEntity, newSelectionOfEntity)

            handleChange(RichUtils.toggleLink(newEditorStateWithEntity, newEditorStateWithEntityAndSelection.getSelection(), newEntityKey))
          }
        },
        contentState
      )
    }
  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log('you can type max ten characters')

      return 'handled'
    }

    return 'not-handled'
  }

  const handlePastedText = (pastedText: string) => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      addAlert('Could not paste because your clipboard will not fit in the remaining space.', AlertType.error, 1000)

      return 'handled'
    }

    return 'not-handled'
  }

  useEffect(() => {
    const decorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link }])
    const editorStateFromValue = EditorState.set(value, { decorator })

    setEditorState(editorStateFromValue)
  }, [])

  return (
    <div className="rich-text-container">
      <div className="rich-text-editor-container">
        {!readOnly && (
          <Toolbar
            editorState={editorState}
            handleChange={handleChange}
            linkSelected={linkSelected}
            setLinkModalOpen={handleLinkModalOpen}
            maxLength={MAX_LENGTH}
            isDisabled={isDisabled}
          />
        )}
        <Editor
          id={id}
          editorState={editorState}
          placeholder={placeholder}
          handleBlockStyle={handleBlockStyle}
          handleChange={handleChange}
          handleKeyCommand={handleKeyCommand}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          readOnly={readOnly || isDisabled}>
          <LinkCallout
            target={target}
            open={linkModalOpen}
            text={initialText}
            link={initialLink}
            create={isCreating}
            onDismiss={() => setLinkModalOpen(false)}
            onLink={handleLink}
          />
        </Editor>
      </div>
    </div>
  )
}
