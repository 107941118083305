import './index.css'

import { PrimaryButton } from '@fluentui/react'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext, useEffect, useState } from 'react'
import { ISurveySubmission, IUserSurveyCopy } from 'shared-types'
import { timeToLocaleDate } from 'utils'

interface IRespondentThumbProps {
  itemKey: string
  answerId: string
  survey: IUserSurveyCopy
  onSurveyClick: () => void
  onAnalyzeClick: () => void
  filterByTopicIds: string[]
}

export const RespondentThumb: React.FC<IRespondentThumbProps> = props => {
  const [submission, setSubmission] = useState<ISurveySubmission | null>(null)
  const { itemKey, answerId, survey, onSurveyClick, onAnalyzeClick, filterByTopicIds } = props

  const [answersLeft, setAnswersLeft] = useState(-1)
  const [filteredQuestionsByTopicLength, setFilteredQuestionsByTopicLength] = useState(0)

  const { getAnswer } = useContext(FirebaseContext)
  useEffect(() => {
    getAnswer(itemKey, answerId).then(setSubmission)
  }, [survey.id])

  useEffect(() => {
    const answersArray = submission ? Object.values(submission.userResponse) : []
    const hasAnswers = answersArray.filter(answer => answer.hasAnswer === true)
    const hasAnswersCount = hasAnswers.length

    const filterTopicsSet = new Set(filterByTopicIds)
    const filteredQuestions = survey.questions.filter(q => {
      if (filterByTopicIds.length) {
        return filterTopicsSet.has(q.topic) || !q.topic
      } else {
        return !q.topic
      }
    })

    const hasNoAnswersCount = filteredQuestions.length - hasAnswersCount
    setFilteredQuestionsByTopicLength(filteredQuestions.length)
    setAnswersLeft(hasNoAnswersCount)
  }, [submission, filterByTopicIds])

  const complete = survey.isComplete
  const started = !survey.isComplete && survey.isStarted
  const unstarted = !survey.isComplete && !survey.isStarted
  const colorClass = complete ? 'dashboard-thumb-completed' : started ? 'dashboard-thumb-started' : 'dashboard-thumb-new'

  const onClick = complete ? onAnalyzeClick : onSurveyClick

  return (
    <div className={`dashboard-thumb respondent-thumb ${colorClass}`} onClick={onClick}>
      <div className="dashboard-thumb-top"></div>
      <div className="dashboard-thumb-center">
        <div className="dashboard-survey-title" style={{ fontStyle: !survey.name ? 'italic' : undefined }}>
          {survey.name || 'Untitled Survey'}
        </div>
        {unstarted && <PrimaryButton onClick={onClick}>New</PrimaryButton>}
        {started && <PrimaryButton onClick={onClick}>Finish this survey</PrimaryButton>}
        {complete && <PrimaryButton onClick={onClick}>See Results</PrimaryButton>}
      </div>
      <div className="dashboard-thumb-bottom">
        {survey.dateSubmitted && (
          <div className="thumb-completion-status">
            You Submitted this on: {survey.dateSubmitted && timeToLocaleDate(survey.dateSubmitted)}
          </div>
        )}
        {!survey.dateSubmitted && (
          <div>
            {answersLeft > -1 ? (
              <div className="thumb-completion-status">
                You have {answersLeft} out of {filteredQuestionsByTopicLength} questions left in this Survey
              </div>
            ) : (
              <div className="thumb-completion-status">Loading...</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
