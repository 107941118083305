import { Icon } from '@fluentui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { matchSubroute } from '../../utils'

export interface DashboardLinkItem {
  key: string
  text: string
  path?: string
  iconName?: string
  onClick?: () => void
  disabled?: boolean
}

export interface IDashboardLinkProps {
  items: DashboardLinkItem[]
  selectedItemKey?: string
}

const DashboardLink = React.memo((props: IDashboardLinkProps) => {
  const { items: links, selectedItemKey } = props

  const history = useHistory()
  const {
    location: { pathname }
  } = history

  const pathsSplit = pathname.split('/')

  let _selectedItemKey = selectedItemKey
  if (!_selectedItemKey) {
    for (let i = 0; i < links.length; i++) {
      const path = links[i].path
      if (path && matchSubroute(pathsSplit, path.split('/'))) {
        _selectedItemKey = links[i].key
        break
      }
    }
  }

  const getMenuItemClassName = (link: DashboardLinkItem) => {
    if (!link.disabled) return ['dashboard-menu-item', _selectedItemKey === link.key ? 'selected-menu' : undefined].join(' ')
    return 'dashboard-menu-item dashboard-menu-item-disabled'
  }

  return (
    <>
      {links.map(link => (
        <div
          key={link.key}
          onClick={() => {
            if (link.disabled) return
            if (link.onClick) link.onClick()
            if (link.path) history.push(link.path)
          }}
          className={getMenuItemClassName(link)}>
          <div className={'dashboard-menu-item-inner'}>
            {link.iconName && <Icon iconName={link.iconName} />}
            <div className={'dashboard-menu-item-text'}>{link.text}</div>
          </div>
        </div>
      ))}
    </>
  )
})

export default DashboardLink
