import './index.css'

import React from 'react'

export interface IChartLabelProps {
  backgroundColor: string
  labelColor?: string
  name: string
  description?: string
}

export const ChartLabel: React.FC<IChartLabelProps> = props => {
  const { backgroundColor, labelColor, name, description } = props

  return (
    <div className="chart-legend-single">
      <div className="chart-legend-mark" style={{ backgroundColor, color: labelColor || 'var(--white)' }}>
        {name}
      </div>
      {description && <div className="chart-legend-text">{description}</div>}
    </div>
  )
}
