import { ChartLabel, IChartLabelProps } from 'components/charts/ChartLabel'
import React from 'react'

interface IChartLegendsProps {
  labels: IChartLabelProps[]
  style?: React.CSSProperties
}

export const ChartLegends: React.FC<IChartLegendsProps> = props => {
  const { labels, style } = props
  return (
    <div className="analyze-question-thumb-legends" style={style}>
      {labels.map((label, idx) => {
        return <ChartLabel key={idx} {...label} />
      })}
    </div>
  )
}
