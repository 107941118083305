import './css/OwnerEdit.css'

import { ChoiceGroupOption, IconButton, Modal, SearchBox } from '@fluentui/react'
import { Button } from 'containers'
import { DataCacheContext } from 'contexts/dataCacheContext'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext, useEffect, useState } from 'react'
import { ISurvey, UserTypes } from 'types'

interface IOwnerEditProps {
  survey: ISurvey
}

export const OwnerEdit = ({ survey }: IOwnerEditProps) => {
  const [surveyOwner, setSurveyOwner] = useState('')
  const [isOwnerEditModalActive, setIsOwnerEditModalActive] = useState(false)
  const [surveyOwners, setSurveyOwners] = useState<string[]>([])
  const [checkedOwnerId, setCheckedOwnerId] = useState('')
  const [ownerSearch, setOwnerSearch] = useState('')
  const { userType } = useContext(DataCacheContext)
  const { getUserEmailFromPermissions, getAllOwners, updateOwner, getUserIdFromPermissions } = useContext(FirebaseContext)

  useEffect(() => {
    getSetSurveyOwner()
  }, [survey.id])

  useEffect(() => {
    if (!isOwnerEditModalActive) {
      setCheckedOwnerId(surveyOwner)
    }
  }, [isOwnerEditModalActive])

  const getSetSurveyOwner = async () => {
    if (userType !== UserTypes.admin) {
      return
    }

    if (survey && survey.owners) {
      const ownerId = survey.owners[0]

      const userEmail = await getUserEmailFromPermissions(ownerId)

      if (userEmail) {
        setSurveyOwner(userEmail)
        setCheckedOwnerId(userEmail)
      }
    }

    getAllOwners().then(setSurveyOwners)
  }

  const onClickEdit = () => {
    setIsOwnerEditModalActive(true)
  }

  const onClickUpdateOwner = async () => {
    const ownerUID = await getUserIdFromPermissions(checkedOwnerId)
    if (ownerUID) {
      await updateOwner(survey.id, ownerUID)
      setSurveyOwner(checkedOwnerId)
    }
    setIsOwnerEditModalActive(false)
  }

  const handleSearchChange = (_?: React.ChangeEvent<HTMLInputElement | undefined> | undefined, value?: string | undefined) => {
    setOwnerSearch(value ?? '')
  }

  if (userType !== UserTypes.admin || !surveyOwner) return null

  return (
    <div className="owner-edit-root">
      <span>owned by {surveyOwner} </span>
      <IconButton
        data-testid="edit-button"
        iconProps={{ iconName: 'Edit' }}
        onClick={onClickEdit}
        styles={{
          root: { background: 'transparent', boxShadow: 'none' },
          rootHovered: { background: 'transparent', boxShadow: 'none' },
          rootPressed: { background: 'transparent', boxShadow: 'none' }
        }}
      />

      <Modal isOpen={isOwnerEditModalActive} onDismiss={() => setIsOwnerEditModalActive(false)}>
        <div className="edit-modal-container">
          <span>owned by {surveyOwner} </span>
          <SearchBox value={ownerSearch} onChange={handleSearchChange} className="edit-modal-search" />
          <div className="edit-modal-body">
            {surveyOwners
              .filter(owner => owner !== surveyOwner && owner.indexOf(ownerSearch) !== -1)
              .map(owner => (
                <div key={owner} className="edit-modal-owner">
                  <div className="edit-modal-name">{owner}</div>

                  <div onClick={() => setCheckedOwnerId(owner)}>
                    <ChoiceGroupOption key={owner} text={''} checked={owner === checkedOwnerId} />
                  </div>
                </div>
              ))}
          </div>
          <div className="edit-modal-buttons">
            <Button onClick={onClickUpdateOwner}>Update</Button>
            <Button onClick={() => setIsOwnerEditModalActive(false)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
