import { ProfileEditor } from 'containers/ProfileEditor'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isSurveyOutdated } from 'utils'
import { AnalyticEvents, logEvent } from 'utils/firebaseAnalytics'

import { LoadingSpinner } from '../components/LoadingSpinner'
import { SurveyRespondentView } from '../containers/SurveyRespondentView'
import { AuthContext } from '../contexts/authContext'
import { FirebaseContext } from '../contexts/firebaseContext'
import { IProfile, IUserResponse, IUserSurveyCopy } from '../types'

export type ISurveyProps = {}

export const Survey = (props: ISurveyProps) => {
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(true)
  const [userSurvey, setUserSurvey] = useState<IUserSurveyCopy | null>(null)
  const [isSurveyComplete, setIsSurveyComplete] = useState(false)
  const [surveyResponses, setSurveyResponses] = useState<IUserResponse | undefined>()
  const [surveyError, setSurveyError] = useState('')

  const [profile, setProfile] = useState<IProfile | null>(null)
  const [profileId, setProfileId] = useState<string | null>('')
  const [showProfileEditor, setShowProfileEditor] = useState(false)

  const { userInfo } = useContext(AuthContext)
  const { submitResponse, getAnswer, saveAnswer, getProfile, updateProfile } = useContext(FirebaseContext)

  const [buttonSubmitted, setButtonSubmitted] = useState(false)
  const [buttonError, setButtonError] = useState(false)

  const { surveyId, answerId } = useParams<{ surveyId: string; answerId: string }>()

  const history = useHistory()
  useEffect(() => {
    if (surveyId === null) return

    const loadSurveyContent = async () => {
      setIsLoadingSurvey(true)

      try {
        const userSurvey = surveyId && answerId ? await getAnswer(surveyId, answerId) : null

        if (!userSurvey) {
          setSurveyError('Cannot fetch survey')
          setIsLoadingSurvey(false)
          return
        }

        const { survey, userResponse: responses, profileId } = userSurvey
        const isUserSurveyComplete = survey.isComplete

        // app version major change
        if (isSurveyOutdated(survey)) {
          setSurveyError('Survey version is out of date. This survey is no longer supported!')
          setIsLoadingSurvey(false)
          return
        }
        const profile = profileId ? await getProfile(profileId) : null

        setSurveyError('')

        if (Object.keys(responses).length) {
          setSurveyResponses(responses)
        } else {
          setSurveyResponses(undefined)
        }
        setProfile(profile)
        setProfileId(profileId)
        if (profile && !profile.initialized) {
          setShowProfileEditor(true)
        }
        setUserSurvey(survey)
        setIsSurveyComplete(isUserSurveyComplete)
      } catch (err) {
        console.error('Failed to load user survey: ', err)
        setSurveyError(err.message)
        setUserSurvey(null)
      } finally {
        setIsLoadingSurvey(false)
      }
    }

    loadSurveyContent()
  }, [surveyId, userInfo?.uid])

  const submitSurveyResponse = async (responses: IUserResponse) => {
    if (!surveyId) return
    await submitResponse(surveyId, answerId, responses)
  }

  if (profile && showProfileEditor) {
    return (
      <ProfileEditor
        initialProfile={profile}
        onSaveProfile={async topicIds => {
          if (profileId) {
            await updateProfile(profileId, topicIds)
            setProfile(await getProfile(profileId))
          }
          setShowProfileEditor(false)
        }}
      />
    )
  }

  if (isSurveyComplete && userSurvey) {
    return (
      <SurveyRespondentView
        filterByTopicIds={profile?.topics?.map(t => t.id)}
        submitResponses={() => {}}
        survey={userSurvey}
        answerId={answerId}
        initialResponses={surveyResponses}
        updateResponses={() => {}}
        isDisabled={true}
      />
    )
  }

  if (userSurvey) {
    return (
      <SurveyRespondentView
        submitResponses={async responses => {
          try {
            await submitSurveyResponse(responses)
          } catch (e) {
            setButtonSubmitted(true)
            setButtonError(true)
            setTimeout(() => {
              setButtonSubmitted(false)
              setTimeout(() => setButtonError(false), 500)
            }, 1200)
            return
          }

          logEvent(AnalyticEvents.SubmitSurvey, { surveyId, answerId, responses })
          setButtonSubmitted(true)

          setTimeout(() => {
            history.push(`/analyze-survey/${surveyId}/${answerId}`)
          }, 1500)
        }}
        filterByTopicIds={profile?.topics?.map(t => t.id)}
        survey={userSurvey}
        answerId={answerId}
        initialResponses={surveyResponses}
        updateResponses={responses => {
          saveAnswer(userSurvey.id, answerId, responses)
        }}
        submitted={buttonSubmitted}
        hasError={buttonError}
      />
    )
  }

  return (
    <>
      {isLoadingSurvey && <LoadingSpinner />}
      {!isLoadingSurvey && surveyError && <SimpleHeader>Encountered an error loading survey: {surveyError}</SimpleHeader>}
    </>
  )
}

const SimpleHeader: React.FC = ({ children }) => {
  return <h1 style={{ textAlign: 'center', color: 'var(--errorMessage)' }}>{children}</h1>
}
