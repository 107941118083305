import '../containers/css/AccountSettings.css'

import { Modal, TextField } from '@fluentui/react'
import { Button } from 'containers'
import { ProfileEditor } from 'containers/ProfileEditor'
import { AuthContext } from 'contexts/authContext'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { IInvitedSurvey, IProfile } from 'types'

export const AccountSettings = () => {
  const { userInfo } = useContext(AuthContext)
  const {
    getAnswer,
    getProfile,
    updateProfile,
    updateProfileName,
    onUpdateInvitedSurveys,
    initializeUserProfile,
    reauthenticate,
    // updateUserEmail,
    updatePassword
  } = useContext(FirebaseContext)
  const [profileId, setProfileId] = useState<string | null>(null)
  const [profile, setProfile] = useState<IProfile>()
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [valueName, setValueName] = useState('')
  const [valueEmail, setValueEmail] = useState('')
  const [valueCurrentPassword, setValueCurrentPassword] = useState('')
  const [valueNewPassword1, setValueNewPassword1] = useState('')
  const [valueNewPassword2, setValueNewPassword2] = useState('')
  // const [hasVerifiedEmailPassword, setHasVerifiedEmailPassword] = useState(false)
  const [isVerifyingPassword, setIsVerifyingPassword] = useState(false)
  const [errorVerifyPassword, setErrorVerifyPassword] = useState('')
  const [errorUpdatePassword, setErrorUpdatePassword] = useState('')
  const [successUpdatePassword, setSuccessUpdatePassword] = useState(false)
  const [profileEmail, setProfileEmail] = useState('')
  const [valueChangePasswordCurrent, setValueChangePasswordCurrent] = useState('')
  const [isMatchingNewPasswords, setIsMatchingNewPasswords] = useState(false)

  useEffect(() => {
    setIsMatchingNewPasswords(!!valueNewPassword1 && !!valueNewPassword2 && valueNewPassword1 === valueNewPassword2)
  }, [valueNewPassword1, valueNewPassword2])

  useEffect(() => {
    if (userInfo?.email) {
      setProfileEmail(userInfo.email)
    }
  }, [userInfo?.email])

  useEffect(() => {
    if (profileId) {
      getProfile(profileId).then(setProfile)
    }
  }, [profileId])

  useEffect(() => {
    const getProfileId = async (invitedSurveys: { [key: string]: IInvitedSurvey }) => {
      const invitations = Object.values(invitedSurveys)

      for (let invite of invitations) {
        const answer = await getAnswer(invite.survey.id, invite.answerId)
        if (answer && answer.profileId) {
          setProfileId(answer.profileId)
          return
        }
      }

      if (userInfo?.email) {
        const profileId = await initializeUserProfile(userInfo.email)
        setProfileId(profileId)
      }
    }

    const unsubscribe = onUpdateInvitedSurveys(surveys => {
      const invitedSurveys = surveys.reduce((agg: { [key: string]: IInvitedSurvey }, val) => {
        agg[val.survey.id] = val
        return agg
      }, {})

      getProfileId(invitedSurveys)
    })

    return () => unsubscribe()
    // }, [userInfo])
  }, [])

  const onSaveProfile = async (topicIds: string[]) => {
    if (profileId) {
      await updateProfile(profileId, topicIds)
      setProfile(await getProfile(profileId))
    }
  }

  useEffect(() => {
    if (isEditingName) {
      if (profile && profile.name) {
        setValueName(profile.name)
      } else {
        setValueName('')
      }
    }
  }, [isEditingName, profile])

  const updateName = async () => {
    if (profileId && valueName) {
      await updateProfileName(profileId, valueName)
      setProfile(profile => (profile ? { ...profile, name: valueName } : profile))
    }
    setIsEditingName(false)
  }

  // const updateEmail = async () => {
  //   await updateUserEmail(valueEmail, valueCurrentPassword)
  //   await reauthenticate(valueCurrentPassword)
  //   setProfileEmail(valueEmail)
  //   setIsEditingEmail(false)
  // }

  const onChangeName = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    setValueName(value ?? '')
  }

  const onClickUpdatePassword = async () => {
    try {
      await updatePassword(valueChangePasswordCurrent, valueNewPassword1)
      setSuccessUpdatePassword(true)
      setValueChangePasswordCurrent('')
      setValueNewPassword1('')
      setValueNewPassword2('')
    } catch (e) {
      console.log(e)
      setErrorUpdatePassword(e.message)
    }
  }

  return (
    <div className="account-settings-root">
      <div className="account-settings-title">Account Settings</div>
      <div className="account-settings-property">
        <div>Name:</div>
        <div className="account-settings-name">
          {isEditingName ? <TextField value={valueName} onChange={onChangeName} /> : profile?.name}
        </div>
        {isEditingName ? (
          <>
            <Button onClick={updateName}>Update</Button>
            <Button onClick={() => setIsEditingName(false)}>Cancel</Button>
          </>
        ) : (
          <Button onClick={() => setIsEditingName(true)}>Edit</Button>
        )}
      </div>
      <div className="account-settings-property">
        <div>Email: </div>

        <div className="account-settings-name">
          {isEditingEmail ? (
            <TextField
              value={valueEmail}
              onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                if (value) {
                  setValueEmail(value)
                }
              }}
            />
          ) : (
            profileEmail
          )}
        </div>

        {/* {isEditingEmail ? (
          <>
            <Button onClick={updateEmail}>Update</Button>
            <Button onClick={() => setIsEditingEmail(false)}>Cancel</Button>
          </>
        ) : (
          <Button
            onClick={() => {
              if (hasVerifiedEmailPassword) {
                setIsEditingEmail(true)
              } else {
                setIsVerifyingPassword(true)
              }
            }}>
            Edit
          </Button>
        )} */}
      </div>
      {profile && (
        <div className="account-settings-topics">
          <div>Topics:</div> <ProfileEditor hideTitle onSaveProfile={onSaveProfile} initialProfile={profile} />
        </div>
      )}

      <div className="account-settings-password-container">
        <div>Password</div>
        <TextField
          placeholder="current password"
          type="password"
          value={valueChangePasswordCurrent}
          onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
            if (value !== undefined) {
              setValueChangePasswordCurrent(value)
            }
          }}
          errorMessage={errorUpdatePassword}
        />
        <TextField
          placeholder="new password"
          value={valueNewPassword1}
          type="password"
          onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
            if (value !== undefined) {
              setValueNewPassword1(value)
            }
          }}
          errorMessage={errorUpdatePassword}
        />
        <TextField
          placeholder="new password again"
          type="password"
          value={valueNewPassword2}
          onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
            if (value !== undefined) {
              setValueNewPassword2(value)
            }
          }}
          errorMessage={errorUpdatePassword}
        />
        {successUpdatePassword && <div>successfully updated password</div>}
        <Button onClick={onClickUpdatePassword} disabled={!isMatchingNewPasswords}>
          Update Password
        </Button>
      </div>

      <Modal isOpen={isVerifyingPassword} onDismiss={() => setIsVerifyingPassword(false)}>
        <div className="account-settings-email-modal">
          <div>Change Email</div>
          <div>enter password to make changes</div>
          <TextField
            id="password"
            placeholder="Password"
            type="password"
            value={valueCurrentPassword}
            errorMessage={errorVerifyPassword}
            onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
              if (value !== undefined) {
                setValueCurrentPassword(value)
              }
            }}
          />
          <Button
            onClick={async () => {
              try {
                await reauthenticate(valueCurrentPassword)
                // setHasVerifiedEmailPassword(true)
                setIsEditingEmail(true)
                setIsVerifyingPassword(false)
              } catch (e) {
                console.log(e)
                if (e.code === 'auth/wrong-password') {
                  setErrorVerifyPassword(e.message)
                }
              }
            }}>
            Enter
          </Button>
          <Button onClick={() => setIsVerifyingPassword(false)}>Cancel</Button>
        </div>
      </Modal>
    </div>
  )
}
