import { Callout, IconButton, IPoint, TextField } from '@fluentui/react'
import { Button } from 'components/Button'
import React, { FC, FormEvent, useEffect, useState } from 'react'

interface ILinkModalProps {
  target: IPoint
  open: boolean
  text: string
  link: string
  create: boolean
  onDismiss: () => void
  onLink: (text: string, link: string, create: boolean) => void
}

interface ILinkForm {
  text: string
  link: string
}

export const LinkCallout: FC<ILinkModalProps> = ({ target, open, text, link, create, onDismiss, onLink }) => {
  const [values, setValues] = useState<ILinkForm>({ text: '', link: '' })
  const [errors, setErrors] = useState<{ text?: string; link?: string }>({})

  const handleDismiss = () => {
    setValues({ text: '', link: '' })
    setErrors({})
    onDismiss()
  }

  const validate = (values: ILinkForm) => {
    const errors: { text?: string; link?: string } = {}

    if (!/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/.test(values.link)) {
      errors.link = 'Enter a full URL, e.g. https://google.com/'
    }

    if (values.text.length <= 3 || values.text.length >= 128) {
      errors.text = 'Enter at least 3 characters and at most 128 characters'
    }

    return errors
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const errors = validate(values)

    if (Object.keys(errors).length <= 0) {
      onLink(values.text, values.link, create)
      handleDismiss()
    } else {
      setErrors(errors)
    }
  }

  const handleChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => {
    const target = event!.target as HTMLInputElement
    const name = target.name

    setValues({ ...values, [name]: value })
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrors({ ...errors, [event.target.name]: undefined })
  }

  useEffect(() => {
    if (open) {
      setValues({ text, link })
    }
  }, [text, link, open, setValues])

  if (open) {
    return (
      <Callout isBeakVisible styles={{ root: { width: 600 } }} target={target} onDismiss={handleDismiss}>
        <form data-testid="link-callout-form" autoComplete="off" onSubmit={handleSubmit}>
          <div className="rich-text-editor-link-modal-top">
            <div className="rich-text-editor-link-modal-top-close">
              <IconButton data-testid="close-link-modal" iconProps={{ iconName: 'ChromeClose' }} onClick={handleDismiss} />
            </div>
            <div className="rich-text-editor-link-modal-top-save">
              <Button type="submit">Save</Button>
            </div>
          </div>
          <div className="rich-text-editor-link-modal-container">
            <TextField
              label="Text"
              name="text"
              value={values.text}
              placeholder="Google"
              styles={{ root: { marginBottom: 10 } }}
              errorMessage={errors.text}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            <TextField
              label="Link"
              name="link"
              value={values.link}
              placeholder="https://google.com/"
              styles={{ root: { marginBottom: 10 } }}
              errorMessage={errors.link}
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </div>
        </form>
      </Callout>
    )
  }

  return null
}
