import './Container.css'

import React, { FC } from 'react'

interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  user?: boolean
}

export const Container: FC<IContainerProps> = ({ children, user, className, ...otherProps }) => (
  <div className={`container ${user ? 'user-container' : ''} ${className}`} {...otherProps}>
    {children}
  </div>
)
