import { Callout, CommandBarButton, DirectionalHint, IconButton, mergeStyleSets, Persona } from '@fluentui/react'
import React, { useContext } from 'react'

import { AuthContext } from '../../contexts/authContext'
import { NavigateOptions } from './index'

const styles = mergeStyleSets({
  item: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    selectors: {
      '&>span': {
        fontWeight: 400
      },
      ':hover > span': {
        color: 'var(--themePrimary)'
      },
      ':hover i': {
        color: 'var(--themePrimaryAlt)'
      }
    }
  },
  commit: { marginLeft: 10, marginTop: 10, fontSize: 12, selectors: { '&>span': { fontWeight: 100 } } }
})

const root = {
  fontSize: 16,
  lineHeight: 1,
  background: 'var(--bodyStandoutBackground)',
  color: 'var(--themePrimary)',
  backgroundImage: 'none',
  boxShadow: 'none',
  border: 'none',
  outline: 'none',
  borderRadius: 5,
  marginBottom: '3px',
  marginRight: '5px',
  selectors: {
    '::after': {
      top: '0 !important',
      right: '0 !important',
      bottom: '0 !important',
      left: '0 !important'
    }
  }
}

const rootHovered = {
  background: 'var(--bodyStandoutBackground)',
  color: 'var(--almostBlack)',
  backgroundImage: 'none',
  boxShadow: 'none',
  border: 'none',
  outline: 'none'
}

const rootPressed = {
  background: 'var(--bodyStandoutBackground)',
  color: 'var(--almostBlack)',
  backgroundImage: 'none',
  boxShadow: 'none',
  border: 'none',
  outline: 'none'
}

const iconButtonStyles = {
  root: root,
  rootHovered: rootHovered,
  rootPressed: rootPressed
}

export interface IAvatarDropdownProps {
  headerNavigate: (option: NavigateOptions) => void
}

export const AvatarDropdown = (props: IAvatarDropdownProps) => {
  const { headerNavigate } = props
  const { userInfo } = useContext(AuthContext)
  const buttonRef = React.useRef<any>(null)
  const [showProfile, setShowProfile] = React.useState<boolean>(false)
  var socialAvatar = userInfo?.photoURL

  const getInitials = () => {
    if (!userInfo?.displayName) return userInfo?.email?.charAt(0).toUpperCase()
    let initials = ''
    userInfo.displayName.split(' ').forEach((i: string) => (initials += i.charAt(0)))
    if (initials.length > 2) initials = initials.charAt(0) + initials.charAt(1)
    console.log(initials)
    return initials.toUpperCase()
  }

  // const onSurveysClick = (
  //   event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement | BaseButton | Button | HTMLSpanElement, MouseEvent>
  // ) => {
  //   headerNavigate(NavigateOptions.surveys)
  // }

  // const onUpgradeClick = (
  //   event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement | BaseButton | Button | HTMLSpanElement, MouseEvent>
  // ) => {
  //   headerNavigate(NavigateOptions.upgrade)
  // }

  // const onSettingsClick = (
  //   event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement | BaseButton | Button | HTMLSpanElement, MouseEvent>
  // ) => {
  //   headerNavigate(NavigateOptions.settings)
  // }

  const signUserOut = async () => {
    headerNavigate(NavigateOptions.signout)
  }

  return (
    <div>
      <div className="flex-row-centered" onClick={() => setShowProfile(!showProfile)} style={{ cursor: 'pointer' }}>
        {/* <div className="header-display-name">{userInfo?.displayName}</div> */}
        <CommandBarButton
          componentRef={buttonRef}
          text={userInfo?.photoURL ? '' : getInitials()}
          styles={{
            root: {
              background: 'transparent'
            },
            rootPressed: {
              width: 32,
              height: 32,
              background: 'transparent'
            },
            rootHovered: {
              width: 32,
              height: 32,
              background: 'transparent'
            },
            flexContainer: {
              width: 32,
              height: 32,
              background: 'var(--defaultUserIconBackground)',
              backgroundImage: `url(${socialAvatar})`,
              borderRadius: '50%',
              backgroundSize: '32px 32px'
            },
            label: {
              color: 'var(--themeDark)',
              fontWeight: 'bold'
            }
          }}
        />
      </div>

      {showProfile && (
        <Callout
          // styles={{ calloutMain: { height: process.env.NODE_ENV === 'production' ? 110 : 80, padding: 20, borderRadius: 7 } }}
          styles={{ calloutMain: { height: 120, padding: 20, borderRadius: 7 } }}
          finalHeight={400}
          calloutWidth={240}
          isBeakVisible={false}
          gapSpace={22}
          target={buttonRef.current._buttonElement.current}
          directionalHint={DirectionalHint.topRightEdge}
          calloutMaxHeight={600}
          onDismiss={() => setShowProfile(false)}>
          <Persona
            text={`${userInfo?.displayName || userInfo?.email}`}
            secondaryText={userInfo?.email || ''}
            coinProps={{ styles: { initials: { fontSize: 22 } } }}
            styles={{
              root: { marginBottom: 15 },
              primaryText: { fontWeight: 700, fontSize: 16 }
            }}
          />
          {/* <div className={styles.item} title="Surveys" onClick={onSurveysClick} data-name="surveys">
            <IconButton iconProps={{ iconName: 'PageHeaderEdit' }} styles={iconButtonStyles} onClick={onSurveysClick} data-name="surveys" />
            <span>Surveys</span>
          </div>

          <div className={styles.item} title="Upgrade to Pro" onClick={onUpgradeClick} id={'pro'}>
            <IconButton iconProps={{ iconName: 'FavoriteStar' }} styles={iconButtonStyles} onClick={onUpgradeClick} id={'pro'} />
            <span>Upgrade to Pro</span>
          </div>

          <div className={styles.item} title="Settings" onClick={onSettingsClick} id={'settings'}>
            <IconButton iconProps={{ iconName: 'Settings' }} styles={iconButtonStyles} onClick={onSettingsClick} id={'settings'} />
            <span>Settings</span>
          </div> */}

          <div className={styles.item} onClick={() => headerNavigate(NavigateOptions.settings)}>
            <IconButton
              iconProps={{ iconName: 'Settings' }}
              styles={iconButtonStyles}
              onClick={() => headerNavigate(NavigateOptions.settings)}
            />
            <span>Account Settings</span>
          </div>

          <div className={styles.item} title="Log out" onClick={signUserOut}>
            <IconButton iconProps={{ iconName: 'SignOut' }} styles={iconButtonStyles} onClick={signUserOut} />
            <span>Log out</span>
          </div>
          {process.env.NODE_ENV === 'production' ? <div className={styles.commit}>Commit: {process.env.REACT_APP_COMMIT_SHA}</div> : null}
        </Callout>
      )}
    </div>
  )
}

export default AvatarDropdown
