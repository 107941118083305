import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { LoginForm } from '../components/LoginForm'
import { AuthWrapper } from '../containers/AuthWrapper'
import { AuthContext, OnlineStatus } from '../contexts/authContext'

const Login = () => {
  const { online } = useContext(AuthContext)

  if (online === OnlineStatus.SignedIn) {
    return <Redirect to="/dashboard" />
  } else {
    return (
      <AuthWrapper>
        <LoginForm />
      </AuthWrapper>
    )
  }
}

export default Login
