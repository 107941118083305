import './App.css'

import { FirebaseErrorDisplay } from 'containers/FirebaseErrorDisplay'
import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AccountSettings } from 'routes/AccountSettings'
import { PublicSurveyRedirect } from 'routes/PublicSurveyRedirect'
import { useParam } from 'utils/reactUtils'

import { LoadingSpinner } from './components/LoadingSpinner'
import Header from './containers/Header'
import AllNotifications from './containers/Notification'
import { AuthContext, OnlineStatus } from './contexts/authContext'
import { DataCacheContext } from './contexts/dataCacheContext'
import { Landing, Login, Signup, Survey } from './routes'
import { Dashboard } from './routes/Dashboard'
import { Privacy } from './routes/privacy'
import { RespondentAnalyze } from './routes/RespondentAnalyze'
import { RespondentSurveys } from './routes/RespondentSurveys'
import { UserTypes } from './types'

export default function App() {
  const { online } = useContext(AuthContext)
  const { userType } = useContext(DataCacheContext)
  const redirect = useParam('redirect')

  const isLoading = online === OnlineStatus.Loading || (online === OnlineStatus.SignedIn && userType === UserTypes.initial) || !online
  const isSignedOut = online === OnlineStatus.SignedOut
  const isRespondent = online === OnlineStatus.SignedIn && userType === UserTypes.respondent
  const isOwner = online === OnlineStatus.SignedIn && (userType === UserTypes.owner || userType === UserTypes.admin)

  return (
    <div className="app">
      <div className="app-navbar">
        <Header />
      </div>

      <div className="app-body">
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Switch>
            {isSignedOut && <Route exact path="/signup" component={Signup} />}
            <Route exact path="/survey/:surveyId/:answerId" component={Survey} />
            <Route exact path="/analyze-survey/:surveyId/:answerId" component={RespondentAnalyze} />
            <Route exact path="/survey/:surveyId" component={PublicSurveyRedirect} />
            <Route exact path="/privacy" component={Privacy} />
            {!isSignedOut && <Route exact path="/account-settings" component={AccountSettings} />}

            {isSignedOut && <Route exact path="/" component={Landing} />}
            {isSignedOut && <Route exact path="/login" component={Login} />}

            {isRespondent && <Route exact path="/landing" component={Landing} />}
            {isRespondent && <Route exact path="/surveys" component={RespondentSurveys} />}

            {isOwner && <Route exact path="/landing" component={Landing} />}
            {isOwner && <Route path="/dashboard" component={Dashboard} />}

            {isSignedOut && <Redirect from="*" to="/" />}
            {isRespondent && (redirect ? <Redirect from="*" to={redirect} /> : <Redirect from="*" to="/surveys" />)}
            {isOwner && <Redirect from="*" to="/dashboard/owner" />}
          </Switch>
        )}

        <FirebaseErrorDisplay />
        <AllNotifications />
      </div>
    </div>
  )
}
