import './css/PublishSurvey.css'
import './css/FirebaseErrorDisplay.css'

import { Modal, TextField, Toggle, TooltipHost, ValidationState } from '@fluentui/react'
import { Button } from 'components/Button'
import { ContactsPicker } from 'components/ContactsPicker'
import { Container } from 'components/Container'
import { DashboardHeader } from 'components/DashboardHeader'
import { AlertType, AppStateContext } from 'contexts/appState'
import { AuthContext } from 'contexts/authContext'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { FC, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createNewAnswerSurvey, IPersonaPropsWithValidation, SurveyTypes } from 'types'
import { handleValidateInput, parseSheetFile, timeSinceEpoch, userChooseFile, writeToClipboard } from 'utils'

import { SurveyRespondentView } from './SurveyRespondentView'

const textFieldStyles = {
  root: {
    width: '100%'
  },
  field: {
    color: 'var(--bodyText)'
  }
}

export const PublishSurvey: FC = () => {
  const { userInfo } = useContext(AuthContext)
  const { addAlert, dashboardEvents } = useContext(AppStateContext)
  const { contacts, managedSurveys, saveManagedSurvey, sendSurvey } = useContext(DataCacheContext)
  const { id } = useParams<{ id: string }>()
  const survey = managedSurveys[id]

  const [hasInvalidContacts, setHasInvalidContacts] = useState(false)
  const [invalidContactsList, setInvalidContactsList] = useState<IPersonaPropsWithValidation[]>([])
  const [showPublicConfirm, setShowPublicConfirm] = useState(false)
  const [contactsList, setContactsList] = useState<IPersonaPropsWithValidation[]>([])
  const [form, setForm] = useState<{ subject: string; message: string; isPublic: boolean }>({
    subject: '',
    message: '',
    isPublic: survey.type === 'public'
  })
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [hasNoEmails, setHasNoEmails] = useState(false)
  const [hasSetDefault, setHasSetDefault] = useState(false)

  const { message, subject, isPublic } = form

  useEffect(() => {
    if (!hasSetDefault && userInfo && userInfo.displayName) {
      setHasSetDefault(true)
      setForm(form => ({ ...form, subject: 'Survey from ' + userInfo.displayName, message: 'Please fill out this survey.' }))
    }
  }, [userInfo, form, hasSetDefault])

  useEffect(() => {
    if (contactsList.length && hasNoEmails) {
      setHasNoEmails(false)
    }
  }, [contactsList])

  useEffect(() => {
    const handlePreview = () => {
      setIsPreviewing(true)
    }

    dashboardEvents.on('preview', handlePreview)

    return () => {
      dashboardEvents.off('preview', handlePreview)
    }
  }, [survey.id])

  const handleCopyLink = () => {
    writeToClipboard(`${window.location.protocol}//${window.location.host}/#/survey/${survey.id}`)
    addAlert('Link to survey was copied to clipboard.', AlertType.notification, 1000)
  }

  const fileFilter = (file: File) => {
    const validTypes = [
      '.csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats=officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]
    return validTypes.indexOf(file.type) !== -1
  }

  const handleClickChooseFile = useCallback(async () => {
    const filesList = await userChooseFile()

    if (!filesList) return

    const files = [...filesList]

    if (files) {
      files.forEach(async file => {
        if (!file) return

        if (fileFilter(file)) {
          try {
            const invitations = await parseSheetFile(file)
            const invitationsWithValidation = Object.entries(invitations).map(([key, value]) => ({
              text: key,
              secondaryText: value.name,
              validationState: handleValidateInput(value.name ? `${value.name} <${key}>` : key, contactsList)
            }))
            const invalidInvitations = invitationsWithValidation.filter(
              invitation => invitation.validationState === ValidationState.warning
            )

            if (invalidInvitations.length >= 1) {
              setInvalidContactsList(invalidInvitations)
              setHasInvalidContacts(true)

              return
            }

            setContactsList([...contactsList, ...invitationsWithValidation])
            addAlert('Successfully uploaded invitations', AlertType.notification)
          } catch (e) {
            addAlert(e, AlertType.error, 0)
          }
        }
      })
    }
  }, [])

  const handleChange = (property: string) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement

    setForm({ ...form, [property]: target.value })
  }

  const handleTogglePublic = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
    event.preventDefault()

    if (checked) {
      setShowPublicConfirm(true)
    }
  }

  const handleSend = () => {
    const invalidContacts = contactsList.filter(contact => contact.validationState === ValidationState.warning)

    setInvalidContactsList(invalidContacts)

    if (invalidContacts.length) {
      setHasInvalidContacts(true)
    } else if (contactsList.length === 0) {
      setHasNoEmails(true)
    } else {
      const surveyContacts = contactsList.map(contact => ({
        email: contact.primaryText || contact.text || '',
        name: contact.secondaryText
      }))

      sendSurvey(survey.id, surveyContacts, subject, message)
      addAlert('Survey was sent successfully', AlertType.notification)
      if (userInfo) {
        setForm(form => ({ ...form, subject: 'Survey from ' + userInfo.displayName, message: 'Please fill out this survey.' }))
      } else {
        setForm({ subject: '', message: '', isPublic: false })
      }
      setContactsList([])
    }
  }

  const handlePublish = () => {
    survey.type = SurveyTypes.public
    survey.dateSent = timeSinceEpoch()

    saveManagedSurvey(survey)

    setForm({ ...form, isPublic: true })
    setShowPublicConfirm(false)
  }

  return (
    <>
      <DashboardHeader
        title={survey.name || 'Untitled Survey'}
        description={survey.description || 'No description...'}
        stats={[{ key: 'Question', value: survey.questions.length }]}>
        <div className="dashboard-header-toggleable">
          {survey.type === SurveyTypes.private && (
            <>
              <div style={{ width: '6rem', cursor: 'pointer', marginRight: '6px' }} onClick={handleTogglePublic}>
                Public Survey
              </div>
              <Toggle
                disabled={!!survey.dateSent}
                checked={isPublic}
                onChange={handleTogglePublic}
                styles={{ root: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
              />
            </>
          )}
          {survey.type === SurveyTypes.public && <Button onClick={handleCopyLink} text="Copy Link" />}
        </div>
      </DashboardHeader>
      <div className="send-email">
        <Container>
          <div className="send-email-container flex-column">
            <div className="send-email-container-header">Send this survey via email</div>
            <div className="send-email-container-custom-input flex-row">
              <div className="send-email-container-upload">
                <TooltipHost content="Document must have email in first column, optional name in second column, and no header lines">
                  <Button lg primary onClick={handleClickChooseFile} text="Upload .csv or .xlsx" />
                </TooltipHost>
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <ContactsPicker
                hasError={hasNoEmails}
                suggestionData={contacts}
                contacts={contactsList}
                setContacts={setContactsList}
                label="To"
              />
              {hasNoEmails && (
                <div style={{ position: 'relative', width: '100%' }}>
                  <div style={{ position: 'absolute', right: 0, top: '4px', color: 'var(--errorMessage)' }}>
                    Please include at least one email address.
                  </div>
                </div>
              )}
            </div>

            <div className="flex-row">
              <TextField
                value={subject}
                styles={textFieldStyles}
                placeholder="Please fill out this survey, etc."
                label="Subject"
                onChange={handleChange('subject')}
              />
            </div>

            <div className="flex-row">
              <TextField
                multiline
                value={message}
                styles={textFieldStyles}
                rows={4}
                placeholder="Message..."
                label="Message"
                onChange={handleChange('message')}
              />
            </div>

            <div className="send-email-container-button flex-row">
              <Button
                link
                ghost
                href={survey.dateSent ? `/#/dashboard/owner/survey-sent-view/${survey.id}` : `/#/dashboard/owner/survey-view/${survey.id}`}
                text="Cancel"
                mr={10}
              />
              <Button onClick={handleSend} text="Send" />
            </div>
          </div>
        </Container>
      </div>

      <Modal
        isOpen={hasInvalidContacts}
        onDismiss={() => setHasInvalidContacts(false)}
        onDismissed={() => setInvalidContactsList([])}
        styles={{ main: { borderRadius: 10 } }}>
        <div className="firebase-error-display-container">
          <div className="firebase-error-message">Invalid Contacts</div>
          <div className="firebase-error-advice send-email-no-margin">
            <div>The following contacts are not formatted correctly.</div>
            <div>Please check and try again.</div>
          </div>
          <ul className="send-survey-invalid-contacts-list">
            {invalidContactsList.map((contact, index) => (
              <li key={`${contact.primaryText || contact.text}-${index}`}>{contact.primaryText || contact.text}</li>
            ))}
          </ul>
          <div className="firebase-error-buttons">
            <Button primary text="Ok" onClick={() => setHasInvalidContacts(false)} />
          </div>
        </div>
      </Modal>

      <Modal isOpen={showPublicConfirm} onDismiss={() => setShowPublicConfirm(false)} styles={{ main: { borderRadius: 10 } }}>
        <div className="firebase-error-display-container">
          <h1>Public Survey</h1>
          <div className="firebase-error-advice">Once a survey is made public, it can not be edited.</div>
          <div className="firebase-error-buttons">
            <Button ghost text="Cancel" onClick={() => setShowPublicConfirm(false)} />
            <Button primary text="Ok" onClick={handlePublish} />
          </div>
        </div>
      </Modal>

      <Modal
        onDismiss={() => setIsPreviewing(false)}
        isOpen={isPreviewing}
        styles={{
          main: {
            width: '80%',
            borderRadius: 10
          },
          root: {
            top: 0
          }
        }}>
        <SurveyRespondentView
          submitResponses={() => setIsPreviewing(false)}
          survey={createNewAnswerSurvey(survey)}
          customSubmitText="Done preview"
        />
      </Modal>
    </>
  )
}
