import './css/registration.css'

import { Checkbox, ITextFieldStyles, Link, TextField } from '@fluentui/react'
import { AnimatedSubmitButton } from 'components/AnimatedSubmitButton'
import { useFormik } from 'formik'
import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParam } from 'utils/reactUtils'

export interface IEmailSignupFormProps {
  nameValue: string
  emailValue: string
  passwordValue: string
  confirmPasswordValue: string
  error?: string
  emailFormClick: () => void
  onSignupFormChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => void
  onClickSignup: (email: string, name: string, password: string) => Promise<boolean>
}

const strings = {
  signup: 'Create Account',
  createAccountButtonText: 'Create Account'
}

const textFieldStyles = (): Partial<ITextFieldStyles> => ({
  root: {
    width: '100%',
    height: 55
  }
})

export const EmailSignupForm: React.FunctionComponent<IEmailSignupFormProps> = ({
  nameValue,
  emailValue,
  passwordValue,
  confirmPasswordValue,
  emailFormClick,
  onClickSignup
}) => {
  const redirect = useParam('redirect')
  const history = useHistory()
  const [hasError, setHasError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { handleSubmit, setErrors, values, touched, errors, handleChange, handleBlur, setFieldValue } = useFormik({
    initialValues: { email: emailValue, name: nameValue, password: passwordValue, passwordConfirm: confirmPasswordValue, agreed: false },
    onSubmit: async values => {
      try {
        await onClickSignup(values.email, values.name, values.password)

        setIsSubmitted(true)

        setTimeout(() => {
          if (redirect) {
            history.push(redirect)
          } else {
            history.push('/surveys')
          }
        })
      } catch (e) {
        setHasError(true)
        setIsSubmitted(true)
        setErrors({ email: e.message })
      }

      setTimeout(() => {
        setIsSubmitted(false)
      }, 1000)
    },
    validate: values => {
      const errors: { email?: string; password?: string; passwordConfirm?: string; agreed?: string } = {}

      if (!values.email.length) {
        errors.email = 'Email is a required field'
      }

      if (!values.password.length) {
        errors.password = 'Password is a required field'
      }

      if (values.email.length && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Invalid email format'
      }

      if (values.password.length && values.password.length < 7) {
        errors.password = 'Password must be at least 8 characters'
      }

      if (values.password.length && values.password.length > 128) {
        errors.password = 'Password must be no longer than 128 characters'
      }

      if (!values.passwordConfirm.length) {
        errors.passwordConfirm = 'Password confirm is a required field'
      }

      if (values.passwordConfirm !== values.password) {
        errors.passwordConfirm = 'Must match the password field'
      }

      if (!values.agreed) {
        errors.agreed = 'You must agree to the terms of service'
      }

      return errors
    }
  })

  return (
    <div className="registration-outer-container">
      <h3 className="registration-form-title">{strings.signup}</h3>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="registration-form-container">
          <div className="registration-form-textinput">
            <div className="form-right">
              Already have an account? <Link href="/#/login">Log in</Link>
            </div>
            <div className="form-right">
              Sign up with a <Link onClick={emailFormClick}>social account instead</Link>
            </div>
            <div className="form-center">
              <TextField
                id="name"
                name="name"
                placeholder="Name"
                value={values.name}
                errorMessage={touched.name && errors.name ? errors.name : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                styles={textFieldStyles}
              />
            </div>
            <div className="form-center">
              <TextField
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={values.email}
                errorMessage={touched.email && errors.email ? errors.email : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                styles={textFieldStyles}
              />
            </div>
            <div className="form-center">
              <TextField
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                value={values.password}
                errorMessage={touched.password && errors.password ? errors.password : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                styles={textFieldStyles}
              />
            </div>
            <div className="form-center">
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                placeholder="Confirm password"
                type="password"
                value={values.passwordConfirm}
                errorMessage={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                styles={textFieldStyles}
              />
            </div>
            <div className="form-right">
              <Checkbox
                label="I agree to PM's Terms of Service"
                checked={values.agreed}
                onChange={(_?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => setFieldValue('agreed', checked || false)}
                onBlur={handleBlur}
                styles={{ root: { marginBottom: touched.agreed && errors.agreed ? 0 : 20 } }}
              />
              <span style={{ height: 20, color: 'var(--red)' }}>{touched.agreed && errors.agreed && errors.agreed}</span>
            </div>
            <div className="form-button">
              <AnimatedSubmitButton
                renderContentUnsubmitted={() => <div className="submit-button-content">Submit</div>}
                renderContentSubmitted={() => <div className="submit-button-content">Successful</div>}
                renderContentError={() => <div className="submit-button-content">Submit failed</div>}
                submitted={isSubmitted}
                hasError={hasError}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
