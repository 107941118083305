import './css/AuthWrapper.css'

import { IconButton, Link, Modal } from '@fluentui/react'
import { Button } from 'components/Button'
import React, { FC, useState } from 'react'

interface IAuthWrapperProps {
  redirectTo?: string
}

const DataRights: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleDataRightsClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <section className="email-form-data-rights">
      <Link
        onClick={handleDataRightsClick}
        styles={{
          root: {
            color: 'var(--themeDark)',
            fontSize: '1em',
            selectors: {
              ':visited': {
                color: 'var(--themeDark)'
              },
              ':active': {
                color: 'var(--themeDark)'
              },
              ':focus': {
                color: 'var(--themeDark)'
              }
            }
          }
        }}>
        About Your Data Rights
      </Link>
      <Modal
        isOpen={isOpen}
        styles={{
          main: {
            width: '50%',
            borderRadius: 10,
            fontSize: 18,
            color: 'var(--themeDark)',
            selectors: {
              '.email-form-data-rights-close': {
                display: 'flex',
                justifyContent: 'flex-end'
              },
              '.email-form-data-rights-title': {
                textAlign: 'center'
              },
              '.email-form-data-rights-info': {
                padding: '10px 48px'
              },
              '.email-form-data-rights-control': {
                textAlign: 'center',
                marginBottom: 20
              },
              '@media only screen and (max-width: 1024px)': {
                width: '90%'
              }
            }
          }
        }}
        onDismiss={() => setIsOpen(false)}>
        <div className="email-form-data-rights-close">
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            styles={{
              root: {
                background: 'transparent',
                color: 'var(--themePrimary)',
                boxShadow: 'none',
                float: 'right',
                marginTop: '12px',
                marginRight: '12px'
              },
              rootHovered: {
                background: 'transparent',
                color: 'var(--themeSecondary)',
                boxShadow: 'none'
              },
              rootPressed: {
                background: 'transparent',
                color: 'var(--themeDark)',
                boxShadow: 'none'
              },
              icon: {
                fontSize: '20px',
                fontWeight: 'bold'
              }
            }}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <h3 className="email-form-data-rights-title">About Your Data Rights</h3>
        <p className="email-form-data-rights-info">
          You own your data. We do not share, sell, or give away your information or your contact lists to third parties. The only way we
          will contact people on your list is if you specifically send it out via a survey or admin request.
        </p>
        <div className="email-form-data-rights-control">
          <Button primary md text="Close" onClick={() => setIsOpen(false)} />
        </div>
      </Modal>
    </section>
  )
}

export const AuthWrapper: FC<IAuthWrapperProps> = ({ children }) => {
  return (
    <div className="email-form-outer">
      <div className="email-form">
        <div className="email-form-container">
          <header className="email-form-header">
            <h1 className="email-form-header-title">
              <img
                alt="Proxy Match Logo"
                className="email-form-header-title-logo"
                src="logo_w_wordmark@2x.png"
                width="100%"
                height="auto"
              />
            </h1>
            <p className="email-form-header-info">
              Proxy voting allows shareholders to have a say in the direction a company takes. Much of proxy voting power is pension plans,
              401(k)s, and other funds that belong to YOU - Proxy Match is your chance to make those votes count.
            </p>
          </header>
          <hr className="mobile-hide" />
          <section className="email-form-inputs">{children}</section>
          <DataRights />
        </div>
      </div>
    </div>
  )
}
