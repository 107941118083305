import './css/registration.css'

import { ITextFieldStyles, Link, TextField } from '@fluentui/react'
import { AnimatedSubmitButton } from 'components/AnimatedSubmitButton'
import { Button } from 'components/Button'
import { useFormik } from 'formik'
import React, { useState } from 'react'

export interface IEmailLoginFormProps {
  emailValue: string
  passwordValue: string
  loginError?: string
  passwordResetView: boolean
  emailFormClick: () => void
  onLoginFormChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => void
  onEmailLoginFormSubmit: (email: string, password: string) => void
  onPasswordResetClick: () => void
  onEmailResetFormSubmit: (email: string) => void
  emailResetSentSuccess: boolean
  passwordResetMessage: string
  passwordResetError?: string
}

const textFieldStyles = (): Partial<ITextFieldStyles> => ({
  root: {
    width: '100%',
    height: 55
  }
})

const strings = {
  emailLogin: 'Login',
  resetPassword: 'Enter email to reset password',
  enterNewPassword: 'Enter your new password',
  loginButtonText: 'Login',
  submitButtonText: 'Submit'
}

export const EmailLoginForm: React.FunctionComponent<IEmailLoginFormProps> = ({
  emailValue,
  passwordValue,
  loginError,
  emailFormClick,
  onLoginFormChange,
  onEmailLoginFormSubmit,
  onPasswordResetClick,
  onEmailResetFormSubmit,
  passwordResetView,
  emailResetSentSuccess,
  passwordResetMessage,
  passwordResetError
}) => {
  const [hasError, setHasError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { handleSubmit, values, touched, errors, handleChange, handleBlur } = useFormik({
    initialValues: { email: emailValue, password: passwordValue },
    onSubmit: values => {
      try {
        if (passwordResetView) {
          onEmailResetFormSubmit(values.email)
        } else {
          onEmailLoginFormSubmit(values.email, values.password)
        }
        setIsSubmitted(true)
      } catch (e) {
        setHasError(true)
      }
    },
    validate: values => {
      const errors: { email?: string; password?: string } = {}

      if (!values.email.length) {
        errors.email = 'Email is a required field'
      }

      if (!values.password.length) {
        errors.password = 'Password is a required field'
      }

      if (values.email.length && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Invalid email format'
      }

      if (values.password.length && values.password.length < 7) {
        errors.password = 'Password must be at least 8 characters'
      }

      if (values.password.length && values.password.length > 128) {
        errors.password = 'Password must be no longer than 128 characters'
      }

      return errors
    }
  })

  return (
    <div className="registration-outer-container">
      <h3 className="registration-form-title">{passwordResetView ? strings.resetPassword : strings.emailLogin}</h3>
      <form data-testid="login-form" onSubmit={handleSubmit} autoComplete="off">
        <div className="registration-form-container">
          <div className="registration-form-textinput">
            <div className="form-right">
              Not Registered? <Link href="/#/signup">Click here to create an account</Link>
            </div>
            <div className="form-right">
              Log in with a <Link onClick={emailFormClick}>social account instead</Link>
            </div>
            {emailResetSentSuccess && <div className="form-center">{passwordResetMessage}</div>}
            {passwordResetView ? (
              <div className="form-center">
                <TextField
                  id="email"
                  placeholder="Email"
                  type="email"
                  value={values.email}
                  errorMessage={passwordResetError ? passwordResetError : touched.email && errors.email ? errors.email : undefined}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  styles={textFieldStyles}
                />
              </div>
            ) : (
              <>
                <div className="form-center">
                  <TextField
                    id="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                    errorMessage={touched.email && errors.email ? errors.email : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    styles={textFieldStyles}
                  />
                </div>
                <div className="form-center">
                  <TextField
                    id="password"
                    placeholder="Password"
                    type="password"
                    value={values.password}
                    errorMessage={loginError ? 'Incorrect password' : touched.password && errors.password ? errors.password : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    styles={textFieldStyles}
                  />
                </div>
              </>
            )}
            {passwordResetView ? (
              <div className="form-right">
                <Link onClick={onPasswordResetClick}>Click here to to Log in</Link>
              </div>
            ) : (
              <div className="form-right">
                Forgot password? <Link onClick={onPasswordResetClick}>Click here to reset</Link>
              </div>
            )}
            <div className="form-button">
              {
                passwordResetView ? (
                  <Button type="submit" data-testid="reset-password-button" text={strings.submitButtonText} />
                ) : (
                  <AnimatedSubmitButton
                    data-testid="reset-password-button"
                    renderContentUnsubmitted={() => <div className="submit-button-content">Submit</div>}
                    renderContentSubmitted={() => <div className="submit-button-content">Submitted</div>}
                    renderContentError={() => <div className="submit-button-content">Submit failed</div>}
                    submitted={!hasError && !loginError && isSubmitted}
                    hasError={!!loginError}
                  />
                )
                // : window.innerWidth < 725 ? (
                //   <Button xl type="submit" data-testid="email-form-login-button" text={strings.loginButtonText} />
                // ) : (
                //   <Button type="submit" data-testid="email-form-login-button" text={strings.loginButtonText} />
                // )
              }
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
