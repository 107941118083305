import '../containers/css/landing.css'

import { Button } from 'components/Button'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext, OnlineStatus } from '../contexts/authContext'

export interface ILandingProps {}

const strings = {
  transparency: 'Creating transparency for\n shareholders, enhancing\n corporate governance',
  voice: 'Proxy Match: Giving actual shareholders a voice',
  participate: 'Participate'
}

const Landing = (props: ILandingProps) => {
  const { online } = useContext(AuthContext)
  const history = useHistory()

  const onClickParticipate = () => {
    if (online === OnlineStatus.SignedIn) {
      history.push('/dashboard')
    } else {
      history.push('/signup')
    }
  }

  // const onClickPrivacy = () => {
  //   history.push('/privacy')
  // }

  return (
    <>
      <div className="landing-wrapper">
        {/* <div className="landing-slide"></div> */}
        <div className="landing-main-logo">
          <img className="landing-main-logo-img" src="/PM_fullcolor@2x.png" alt="main logo"></img>
        </div>
        <div className="landing-overlay">
          <div className="landing-description">
            <div>
              <div>A new perspective on the proxy process.</div>
              <div>Creating more transparency for</div>
              <div>board engagement and shareholders.</div>
            </div>
          </div>
          <div className="landing-participate-button">
            <Button xl text={strings.participate} onClick={onClickParticipate} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing
