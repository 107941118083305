import './index.css'

import { IButtonStyles, IconButton } from '@fluentui/react'
import React, { useContext } from 'react'

import { DataCacheContext } from '../../contexts/dataCacheContext'
import { SurveyTypes, UserTypes } from '../../types'
import { timeToLocaleDate } from '../../utils'

const primaryIconStyles = (): IButtonStyles => ({
  root: {
    background: 'transparent',
    fontWeight: 700,
    fontSize: 24,
    color: 'var(--bodyText)'
  }
})

const iconStyle = {
  background: 'transparent',
  boxShadow: 'none',
  color: 'var(--bodyText)'
}

const iconButtonStyles = {
  root: iconStyle,
  rootHovered: iconStyle,
  rootPressed: iconStyle
}

export enum OwnerThumbType {
  createdSurvey,
  createNewSurvey
}

export type IOwnerThumbProps = {
  itemKey: string
  name: string
  thumbType: OwnerThumbType
  dateSent?: number | null
  createdBy?: string
  onSurveyClick: () => void
  onSurveyItemClick?: (itemKey: string, action: string) => void
  actions?: string[]
  numOfQuestions?: number
  responded?: number
  sent?: number
  type: SurveyTypes
}

export const OwnerThumb = (props: IOwnerThumbProps) => {
  const {
    itemKey,
    name,
    thumbType,
    dateSent,
    createdBy,
    onSurveyClick,
    onSurveyItemClick,
    actions,
    numOfQuestions,
    responded,
    sent,
    type
  } = props

  const { userType } = useContext(DataCacheContext)
  const onClickThumb = () => {
    onSurveyClick()
  }

  if (thumbType === OwnerThumbType.createNewSurvey) {
    return (
      <div onClick={onClickThumb} className="dashboard-thumb create-new-survey">
        <IconButton iconProps={{ iconName: 'Add' }} styles={iconButtonStyles} />
        <div>Create New Survey</div>
      </div>
    )
  } else if (thumbType === OwnerThumbType.createdSurvey) {
    return (
      <div onClick={onClickThumb} className="dashboard-thumb">
        <div className="dashboard-thumb-top">
          <div className="dashboard-thumb-top-type">{type === 'public' && 'Public'}</div>
          {onSurveyItemClick && actions && (
            <IconButton
              iconProps={{ iconName: 'More', styles: primaryIconStyles() }}
              styles={iconButtonStyles}
              onRenderMenuIcon={() => <></>}
              menuProps={{
                items: actions.map((action: string) => {
                  return {
                    key: action,
                    text: action,
                    onClick: () => onSurveyItemClick!(itemKey, action)
                  }
                })
              }}
            />
          )}
        </div>
        <div className="dashboard-thumb-center">
          <div className="dashboard-survey-title" style={{ fontStyle: !name ? 'italic' : undefined }}>
            {name.length > 140 ? name.substring(0, 140) : name || 'Untitled Survey'}
          </div>
        </div>
        <div className={userType === UserTypes.admin ? 'dashboard-thumb-bottom admin' : 'dashboard-thumb-bottom'}>
          {userType === UserTypes.admin ? (
            <div className="thumb-date">
              <div className="thumb-date-detail">Created By:</div>
              <div className="thumb-date-detail value">{createdBy}</div>
            </div>
          ) : null}
          <div className="thumb-date">
            <div className="thumb-date-detail">Status:</div>
            <div className="thumb-date-detail value">{dateSent ? 'Sent ' + timeToLocaleDate(dateSent) : 'Draft'}</div>
          </div>
          <div className="thumb-date">
            <div className="thumb-date-detail">Questions:</div>
            <div className="thumb-date-detail value">{numOfQuestions}</div>
          </div>
          <div className="thumb-date">
            <div className="thumb-date-detail">Responded/Sent:</div>
            <div className="thumb-date-detail value">{typeof responded === 'number' ? `${responded}/${sent}` : ''}</div>
          </div>
        </div>
      </div>
    )
  }

  return null
}
