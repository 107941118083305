import './index.css'
import '../CreateSurvey/index.css'

import { OwnerSurveyDashboardModals } from 'containers/OwnerSurveyDashboardModals'
import { Paginator } from 'containers/Paginator'
import { FirebaseContext } from 'contexts/firebaseContext'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { DashboardHeader } from '../../components/DashboardHeader'
import { IAnalytics, IQuestion, ISurvey, ISurveyInvitation } from '../../types'
import { timeToLocaleDate } from '../../utils'
import { AnalyzeBarChartTopics, AnalyzeChart } from './AnalyzeChart'

export type IAnalyzeSurveyProps = {
  survey: ISurvey
  submit: (to: ISurveyInvitation[], subject: string, message: string) => void
}

const sizeThumb = 500
const gridGap = 32
const amtThumbsHorizontal = (width: number) => Math.floor((width + gridGap) / (sizeThumb + gridGap))

export const AnalyzeSurvey: React.FunctionComponent<IAnalyzeSurveyProps> = props => {
  const { survey } = props
  const filteredQuestions = survey.questions.filter(q => !!q.value)

  const firebaseContext = useContext(FirebaseContext)
  const [analytics, setAnalytics] = useState<IAnalytics>()
  const [topics, setTopics] = useState<{ [topicName: string]: number }>({})
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [pageLimit, setPageLimit] = useState(0)
  const [page, setPage] = useState(0)
  const [isNonexistent, setIsNonexistent] = useState(false)

  const onResize = useCallback(
    _.throttle(() => {
      // calculate size based on empty container
      setPageLimit(0)

      setTimeout(() => {
        if (!containerRef.current?.clientWidth || !containerRef.current.clientHeight) return
        const width = containerRef.current.clientWidth
        setPageLimit(amtThumbsHorizontal(width))
        setPage(0)
      })
    }, 1000),
    []
  )

  useEffect(() => {
    if (survey.id) {
      firebaseContext.getSurveyAnalytics(survey.id).then(data => {
        if (!data) {
          setIsNonexistent(true)
        }
        setAnalytics(data)
      })
    }

    if (containerRef.current?.clientWidth) {
      const width = containerRef.current.clientWidth
      setPageLimit(amtThumbsHorizontal(width))
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [survey.id])

  useEffect(() => {
    if (analytics) {
      const newTopics: { [topicName: string]: number } = {}
      firebaseContext.getTopics().then(topics => {
        topics.forEach(topic => {
          newTopics[topic.name] = analytics.countTopics[topic.id] || 0
        })

        setTopics(newTopics)
      })
    }
  }, [analytics])

  const timeInSec = analytics && analytics.avgResponseTimeMS ? analytics.avgResponseTimeMS / 1000 : 0

  const totalMin = timeInSec / 60
  const numHours = Math.floor(totalMin / 60)
  const numMin = Math.round(totalMin - numHours * 60)

  const hoursStr = String(numHours).padStart(2, '0')
  const minsStr = String(numMin).padStart(2, '0')

  const onRenderHeader = (question: IQuestion, index: number) => {
    const questionNum = (page - 1) * pageLimit + index + 1
    return `${questionNum}. ${question.value}`
  }

  const onRenderHeaderTopics = () => {
    return 'Overall issues valued by our users'
  }

  return (
    <div className="create-survey-container" ref={containerRef}>
      <DashboardHeader
        title={survey.name}
        description={survey.description}
        stats={[
          { key: 'Questions', value: filteredQuestions.length },
          { key: 'Avg. Completion Time (hh:mm)', value: `${hoursStr}:${minsStr}` },
          { key: 'Total Responses', value: analytics ? Object.keys(analytics.submissionIds).length : 0 }
        ]}>
        Sent on {survey.dateSent && timeToLocaleDate(survey.dateSent)}
      </DashboardHeader>

      <div className="questions-and-topics">
        <div className="container">
          <div className="analyze-survey-thumbs-container">
            {isNonexistent && <div>No responses have been submitted</div>}
            {analytics && page === 0 && Object.keys(topics) && (
              <AnalyzeBarChartTopics countTopics={topics} header={onRenderHeaderTopics()} />
            )}
            {analytics &&
              page > 0 &&
              // filteredQuestions.slice(pageLimit * page, (page + 1) * pageLimit).map((question, idx) => (
              filteredQuestions
                .slice(pageLimit * (page - 1), page * pageLimit)
                .map((question, idx) => (
                  <AnalyzeChart
                    index={idx}
                    key={question.id}
                    header={onRenderHeader(question, idx)}
                    question={question}
                    countAnswered={analytics.countAnsweredByQuestion[question.id]}
                    countUnanswered={analytics.countUnansweredByQuestion[question.id]}
                    countImportanceByQuestion={analytics.countImportanceByQuestion}
                    countTruthyByChoice={analytics.countTruthyByChoice}
                  />
                ))}
          </div>
        </div>
      </div>

      {analytics && (
        <div className="paginator-contacts-container">
          <Paginator
            maxPaginatorDisplay={Math.ceil(filteredQuestions.length / pageLimit) + 1}
            countPages={Math.ceil(filteredQuestions.length / pageLimit) + 1}
            currentPage={page}
            onPageChange={setPage}
          />
        </div>
      )}

      <OwnerSurveyDashboardModals survey={survey} submit={props.submit} />
    </div>
  )
}
