import './css/Topics.css'

import { IconButton, TextField } from '@fluentui/react'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { DashboardHeader } from 'components/DashboardHeader'
import { DataCacheContext } from 'contexts/dataCacheContext'
import React, { useContext, useEffect } from 'react'

export const Topics = () => {
  const { getTopics, topics, addTopic, deleteTopic, updateTopic, saveTopic, isTopicsFetching } = useContext(DataCacheContext)

  const handleAddTopic = async () => await addTopic()
  const handleChange = (topicId: string, name?: string | undefined) => updateTopic(topicId, name!)
  const handleUpdateTopic = async (topicId: string, name: string) => await saveTopic(topicId, name)
  const handleSaveTopic = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, topicId: string, name: string) => {
    if (event.key === 'Enter' && event.keyCode === 13) {
      await handleUpdateTopic(topicId, name)
    }
  }
  const handleDeleteTopic = async (topicId: string) => await deleteTopic(topicId)

  useEffect(() => {
    const fetchTopics = async () => {
      await getTopics()
    }

    fetchTopics()
  }, [])

  return (
    <div className="topics-container">
      <DashboardHeader title="Topics" stats={[{ key: `Topic${topics.length !== 1 ? 's' : ''}`, value: topics.length }]} />
      <section className="topics-body">
        <Container>
          <Button
            lg
            data-testid="add-topic-button"
            icon={isTopicsFetching ? 'ProgressLoopInner' : 'add'}
            onClick={handleAddTopic}
            disabled={isTopicsFetching || topics.filter(topic => topic.name === '').length > 0}>
            {isTopicsFetching ? 'Loading...' : 'Add new topic'}
          </Button>
          <ul className="topics-input-list">
            {topics.map((topic, index) => (
              <li key={topic.id} className="topics-input">
                <TextField
                  autoFocus
                  name={topic.id}
                  placeholder="Enter a topic"
                  value={topic.name}
                  tabIndex={index + 1}
                  onChange={(_, newValue) => handleChange(topic.id, newValue)}
                  onBlur={() => setTimeout(() => handleUpdateTopic(topic.id, topic.name), 500)}
                  onKeyUp={event => handleSaveTopic(event, topic.id, topic.name)}
                />
                <IconButton
                  role="button"
                  styles={{ root: { marginLeft: 5, background: 'transparent', boxShadow: 'none' } }}
                  iconProps={{ iconName: 'delete' }}
                  onClick={() => handleDeleteTopic(topic.id)}
                  disabled={isTopicsFetching}
                />
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </div>
  )
}
