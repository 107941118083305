import React from 'react'

import {
  createEmptySurvey,
  IContacts,
  IInvitedSurvey,
  ISurvey,
  ISurveyEmailSubmissions,
  ISurveyInvitation,
  ISurveySubmission,
  ITopic,
  UserTypes
} from '../types'
import { Subtract } from '../utils'
import { IWithAuthContextProps, withAuthContext } from './authContext'
import { IWithFirebaseContextProps, withFirebaseContext } from './firebaseContext'

export type IViewMode = 'list' | 'thumb'

export interface IDataCache {
  isFetching: boolean
  currentSurvey: { surveyId: string; answerId: string } | null
  managedSurveys: { [id: string]: ISurvey }
  invitedSurveys: { [id: string]: IInvitedSurvey }
  topics: ITopic[]
  userType: UserTypes
  surveySubmissions: ISurveyEmailSubmissions
  isTopicsFetching: boolean
  contacts: IContacts

  saveManagedSurvey: (survey: ISurvey) => Promise<ISurvey>
  duplicateManagedSurvey: (survey: ISurvey) => Promise<ISurvey>
  deleteManagedSurvey: (surveyId: string) => Promise<void>
  getSurveySubmissions: (surveyId: string) => Promise<ISurveySubmission[]>
  sendSurvey: (surveyId: string, invitations: ISurveyInvitation[], subject: string, message: string) => Promise<void>
  getTopics: () => Promise<ITopic[]>
  addTopic: () => Promise<ITopic | null>
  deleteTopic: (topicId: string) => Promise<void>
  updateTopic: (topidId: string, name: string) => void
  saveTopic: (topidId: string, name: string) => Promise<void>

  refresh: () => Promise<void>
  onSurveySubmission: (surveyId: string, callback: (surveySubmissions: ISurveySubmission[]) => void) => Promise<() => void>
}

export const defaultDataCache: IDataCache = {
  isFetching: false,
  currentSurvey: null,
  managedSurveys: {},
  invitedSurveys: {},
  surveySubmissions: {},
  topics: [],
  userType: UserTypes.initial,
  isTopicsFetching: false,
  contacts: {},

  saveManagedSurvey: (survey: ISurvey) => Promise.resolve(createEmptySurvey()),
  duplicateManagedSurvey: (survey: ISurvey) => Promise.resolve(createEmptySurvey()),
  deleteManagedSurvey: (surveyId: string) => Promise.resolve(),
  getSurveySubmissions: (surveyId: string) => Promise.resolve([]),
  sendSurvey: () => Promise.resolve(),
  getTopics: () => Promise.resolve([]),
  addTopic: () => Promise.resolve({ id: '', name: '', owner: '' }),
  deleteTopic: () => Promise.resolve(),
  updateTopic: () => {},
  saveTopic: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  onSurveySubmission: () => Promise.resolve(() => {})
}

const DataCacheContext = React.createContext<IDataCache>(defaultDataCache)

export interface IDataCacheProviderProps extends IWithFirebaseContextProps, IWithAuthContextProps {
  children: React.ReactNode
}

function getSurveysFromArray(arr: ISurvey[]) {
  return arr.reduce((agg: { [key: string]: ISurvey }, val) => {
    agg[val.id] = val
    return agg
  }, {})
}

class _DataCacheProvider extends React.Component<IDataCacheProviderProps, IDataCache> {
  currentUserId?: string
  unsubscribeCallbacks: (() => void)[]

  constructor(props: IDataCacheProviderProps) {
    super(props)
    this.state = {
      ...defaultDataCache,
      saveManagedSurvey: this.saveManagedSurvey,
      duplicateManagedSurvey: this.duplicateManagedSurvey,
      deleteManagedSurvey: this.deleteManagedSurvey,
      getSurveySubmissions: this.getManagedSurveyAnswers,
      refresh: this.refresh,
      onSurveySubmission: this.onSurveySubmission,
      sendSurvey: this.sendSurvey,
      getTopics: this.getTopics,
      addTopic: this.addTopic,
      deleteTopic: this.deleteTopic,
      updateTopic: this.updateTopic,
      saveTopic: this.saveTopic,
      isTopicsFetching: false
    }

    this.currentUserId = this.props.auth.userInfo?.uid
    this.unsubscribeCallbacks = []
  }

  componentDidUpdate() {
    // fetch if user info changed
    if (this.props.auth.userInfo?.uid !== this.currentUserId) {
      this.currentUserId = this.props.auth.userInfo?.uid
      this.refresh()
    }
  }

  componentDidMount() {
    // fetch if user info is available from the start
    if (this.props.auth.userInfo?.uid !== this.currentUserId) {
      this.currentUserId = this.props.auth.userInfo?.uid
      this.refresh()
    }
  }

  // get the latest data by re-fetching all the data used
  refresh = async () => {
    if (this.state.isFetching) return

    // unsubscribe all listeners and clear
    this.unsubscribeCallbacks.forEach(f => f())
    this.unsubscribeCallbacks = []

    if (!this.currentUserId) return

    this.setState({
      isFetching: true
    })

    try {
      const userType = await this.props.firebaseFunctions.getUserType(this.currentUserId)
      const [managedSurveysArr, invitedSurveysArr] = await Promise.all([
        // managed surveys
        userType === UserTypes.owner || userType === UserTypes.admin ? this.props.firebaseFunctions.getSurveys(this.currentUserId) : [],
        // invited surveys
        this.props.firebaseFunctions.getInvitedSurveys()
      ])

      const managedSurveys = getSurveysFromArray(managedSurveysArr)
      const invitedSurveys = invitedSurveysArr.reduce((agg: { [key: string]: IInvitedSurvey }, val) => {
        agg[val.survey.id] = val
        return agg
      }, {})

      let surveySubmissions = {}
      let contacts = {}

      // unsubscribe all listeners and clear
      this.unsubscribeCallbacks.forEach(f => f())
      this.unsubscribeCallbacks = []

      // re-subscribe listeners if owner
      if (userType === UserTypes.owner || userType === UserTypes.admin) {
        let unsubscribe = await this.props.firebaseFunctions.onUpdateManagedSurveys(this.currentUserId!, surveys => {
          const managedSurveys = getSurveysFromArray(surveys)
          this.setState({ managedSurveys })
        })
        this.unsubscribeCallbacks.push(unsubscribe)

        surveySubmissions = await new Promise<ISurveyEmailSubmissions>(resolve => {
          const surveySubmissions: ISurveyEmailSubmissions = {}

          Promise.all(managedSurveysArr.map(survey => this.props.firebaseFunctions.getSurveySubmissions(survey.id))).then(submissions => {
            submissions.forEach((submission, index) => {
              submission.forEach(submission => {
                if (!surveySubmissions[submission.submissionUserId]) {
                  surveySubmissions[submission.submissionUserId] = {}
                }
                if (!surveySubmissions[submission.submissionUserId][submission.survey.id]) {
                  surveySubmissions[submission.submissionUserId][submission.survey.id] = submission
                }
              })
            })

            resolve(surveySubmissions)
          })
        })

        if (userType === UserTypes.admin) {
          contacts = await this.props.firebaseFunctions.getAllContacts()
        } else {
          contacts = await this.props.firebaseFunctions.getContacts()
        }
      } else {
        this.props.firebaseFunctions.checkPublicSurveys()
      }

      // const topics = await this.getTopics()

      let unsubscribe = this.props.firebaseFunctions.onUpdateInvitedSurveys(surveys => {
        const invitedSurveys = surveys.reduce((agg: { [key: string]: IInvitedSurvey }, val) => {
          agg[val.survey.id] = val
          return agg
        }, {})
        this.setState({ invitedSurveys })
      })
      this.unsubscribeCallbacks.push(unsubscribe)

      this.setState({
        userType,
        managedSurveys,
        invitedSurveys,
        surveySubmissions,
        contacts,
        topics: [],
        isFetching: false
      })
    } catch (err) {
      // TODO: ensure this works... can't replicate errors right now
      console.log(err)
      console.error('Error while refreshing cache for new data', err)
      await new Promise(resolve => {
        setTimeout(resolve, 1000)
      })
      await this.props.firebaseFunctions.reconnect()
      await this.refresh()
    }
  }

  onSurveySubmission = async (surveyId: string, callback: (submissions: ISurveySubmission[]) => void) => {
    const unsubscribe = await this.props.firebaseFunctions.onSurveySubmission(surveyId, callback)

    return unsubscribe
  }

  sendSurvey = async (surveyId: string, invitations: ISurveyInvitation[], subject: string, message: string) => {
    const survey = this.state.managedSurveys[surveyId]
    if (!survey) throw Error('Cannot send non-existent survey')

    const newSurvey = await this.props.firebaseFunctions.inviteEmails(invitations, subject, message, survey)
    this.setState({
      managedSurveys: {
        ...this.state.managedSurveys,
        [newSurvey.id]: newSurvey
      }
    })
  }

  saveManagedSurvey = async (survey: ISurvey) => {
    const s = await this.props.firebaseFunctions.saveSurvey(survey)

    this.setState({
      managedSurveys: {
        ...this.state.managedSurveys,
        [s.id]: s
      }
    })

    return s
  }

  duplicateManagedSurvey = async (survey: ISurvey) => {
    const dup = await this.props.firebaseFunctions.duplicateSurvey(survey, this.currentUserId!)
    console.log({ dup })

    this.setState({
      managedSurveys: {
        ...this.state.managedSurveys,
        [dup.id]: dup
      }
    })

    return dup
  }

  deleteManagedSurvey = async (surveyId: string) => {
    await this.props.firebaseFunctions.deleteSurvey(surveyId)
    const copy = Object.assign({}, this.state.managedSurveys)
    delete copy[surveyId]

    this.setState({
      managedSurveys: copy
    })
  }

  getManagedSurveyAnswers = async (surveyId: string) => {
    return await this.props.firebaseFunctions.getSurveySubmissions(surveyId)
  }

  getTopics = async () => {
    this.setState({ isTopicsFetching: this.state.topics.length > 0 ? false : true })
    const topics = await this.props.firebaseFunctions.getTopics()
    this.setState({ topics, isTopicsFetching: false })
    return topics
  }

  addTopic = async () => {
    this.setState({ isTopicsFetching: true })
    const topic = (await this.props.firebaseFunctions.addTopic()) as ITopic | null
    if (topic) {
      this.setState({ topics: [topic, ...this.state.topics] })
    }

    this.setState({ isTopicsFetching: false })
    return topic
  }

  deleteTopic = async (topicId: string) => {
    this.setState({ isTopicsFetching: true })
    const topics = this.state.topics.filter(topic => topic.id !== topicId)
    await this.props.firebaseFunctions.deleteTopic(topicId)
    this.setState({ topics, isTopicsFetching: false })
  }

  updateTopic = (topicId: string, name: string) => {
    const topic = this.state.topics.filter(topic => topic.id === topicId)[0]
    const index = this.state.topics.indexOf(topic)

    const topics = [
      ...this.state.topics.slice(0, index),
      { name, id: topicId, owner: topic.owner },
      ...this.state.topics.slice(index + 1, this.state.topics.length)
    ] as ITopic[]

    this.setState({ topics })
  }

  saveTopic = async (topicId: string, name: string) => {
    this.setState({ isTopicsFetching: true })

    if (!name || name === '') {
      await this.deleteTopic(topicId)
    } else {
      await this.props.firebaseFunctions.updateTopic(topicId, name)
    }

    this.setState({ isTopicsFetching: false })
  }

  render() {
    return <DataCacheContext.Provider value={this.state}>{this.props.children}</DataCacheContext.Provider>
  }
}

const DataCacheProvider = withAuthContext(withFirebaseContext(_DataCacheProvider))

export interface IWithDataCacheContextProps {
  dataCache: IDataCache
}

export function withDataCacheContext<P extends IWithDataCacheContextProps>(Child: React.ComponentType<P>) {
  return function WithAuth(props: Subtract<P, IWithDataCacheContextProps>) {
    return <DataCacheContext.Consumer>{dataCache => <Child {...(props as P)} dataCache={dataCache} />}</DataCacheContext.Consumer>
  }
}

export { DataCacheContext, DataCacheProvider }
