import './index.css'

import React from 'react'

import { chartSecondaryColorMap } from './ChartColors'
import { ChartLabel } from './ChartLabel'

export interface IScaleDataEntry {
  name: string
  value: number
  backgroundColor?: string
}

export interface IHorizontalStackedScaleProps {
  data: IScaleDataEntry[]
  showLegend?: boolean
  scaleLeft?: string
  scaleRight?: string
}

export const HorizontalStackedScale: React.FC<IHorizontalStackedScaleProps> = React.memo((props: IHorizontalStackedScaleProps) => {
  const { data, showLegend = true, scaleLeft, scaleRight } = props

  const total = data.reduce((sum, current) => {
    return sum + current.value
  }, 0)

  if (total <= 0) return null

  return (
    <>
      <div className={'horizontal-stacked-scale'}>
        {data
          .filter(entry => entry.value)
          .map(entry => (
            <div
              style={{
                backgroundColor: entry.backgroundColor || chartSecondaryColorMap[entry.name].backgroundColor,
                width: `${((entry.value / total) * 100).toFixed(1)}%`
              }}
              key={entry.name}
            />
          ))}
      </div>

      {showLegend && (
        <div className={'horizontal-scale-legends'}>
          {data.map(entry => {
            return (
              <ChartLabel
                key={entry.name}
                name={entry.name}
                backgroundColor={entry.backgroundColor || chartSecondaryColorMap[entry.name].backgroundColor}
              />
            )
          })}
        </div>
      )}

      {scaleLeft && scaleRight && (
        <div className={'horizontal-scale-label'}>
          <div>{scaleLeft}</div>
          <div>{scaleRight}</div>
        </div>
      )}
    </>
  )
})
