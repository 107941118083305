import './css/Paginator.css'

import { Icon } from '@fluentui/react'
import React from 'react'

// NOTE: all props are assuming 0-indexed page number, save for counts
interface IPaginatorProps {
  currentPage: number
  onPageChange: (value: number) => void
  countPages: number
  maxPaginatorDisplay?: number
}

const Paginator: React.FC<IPaginatorProps> = props => {
  const { currentPage, onPageChange, countPages, maxPaginatorDisplay = 7 } = props

  const shownPages = []
  const initStart = Math.max(currentPage - Math.floor(maxPaginatorDisplay / 2), 0)
  const end = Math.min(initStart + maxPaginatorDisplay, countPages) - 1
  const start = Math.max(end - maxPaginatorDisplay + 1, 0)
  for (let i = start; i <= end; i++) shownPages.push(i)

  return (
    <div className="paginator">
      <button className={'paginator-number-button'} onClick={() => onPageChange(0)} disabled={currentPage <= 0}>
        <Icon iconName={'DoubleChevronLeft'} />
      </button>
      <button
        data-testid="paginator-left"
        className={'paginator-number-button'}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 0}>
        <Icon iconName={'ChevronLeft'} />
      </button>

      {start > 0 && <div className="paginator-number-button">...</div>}
      {shownPages.map(p => (
        <div
          key={p}
          className={['paginator-number-button', currentPage === p ? 'paginator-number-selected' : null].join(' ')}
          onClick={() => onPageChange(p)}>
          {p + 1}
        </div>
      ))}
      {end < countPages - 1 && <div className="paginator-number-button">...</div>}

      <button
        data-testid="paginator-right"
        className={'paginator-number-button'}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= countPages - 1}>
        <Icon iconName={'ChevronRight'} />
      </button>
      <button
        className={'paginator-number-button'}
        onClick={() => onPageChange(maxPaginatorDisplay - 1)}
        disabled={currentPage >= countPages - 1}>
        <Icon iconName={'DoubleChevronRight'} />
      </button>
    </div>
  )
}

export { Paginator }
