import './index.css'

import { PublishSurvey } from 'containers/PublishSurvey'
import { SurveyComments } from 'containers/SurveyComments'
import { SurveyRespondentView } from 'containers/SurveyRespondentView'
import { AlertType, AppStateContext } from 'contexts/appState'
import React, { FC, useCallback, useContext } from 'react'
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom'
import { Route, useHistory } from 'react-router-dom'

import { AnalyzeSurvey } from '../../containers/AnalyzeSurvey'
import CreatedSurveysList from '../../containers/CreatedSurveysList'
import CreateSurvey from '../../containers/CreateSurvey'
import { SentSurvey } from '../../containers/SentSurvey'
import { SurveyContacts } from '../../containers/SurveyContacts'
import { DataCacheContext } from '../../contexts/dataCacheContext'
import { createNewAnswerSurvey, ISurvey, ISurveyInvitation } from '../../types'

interface ICreateSurveyViewProps extends RouteComponentProps<any> {}

const CreateSurveyView = (props: ICreateSurveyViewProps) => {
  const { match } = props
  const params = match.params

  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const history = useHistory()

  const survey = dataCache.managedSurveys[params.id]

  const saveManagedSurvey = useCallback((survey: ISurvey) => dataCache.saveManagedSurvey(survey), [dataCache.saveManagedSurvey])
  const sendSurvey = useCallback(
    (to: ISurveyInvitation[], subject: string, message: string, survey: ISurvey) => {
      dataCache.sendSurvey(survey.id, to, subject, message)
      appState.addAlert('Survey was sent successfully', AlertType.notification)
      history.push('/dashboard/owner')
    },
    [dataCache.sendSurvey]
  )

  if (!survey) return null
  return (
    <CreateSurvey
      key={params.id}
      initialSurvey={survey}
      onSave={saveManagedSurvey}
      submit={(to, subject, message) => sendSurvey(to, subject, message, survey)}
    />
  )
}

const SurveyPreview = (props: RouteComponentProps<any>) => {
  const { match } = props
  const params = match.params
  const history = useHistory()

  const dataCache = useContext(DataCacheContext)
  const survey = dataCache.managedSurveys[params.id]
  return (
    <div className="survey-preview-container">
      <div className="survey-preview-title">Preview - this is not a live survey</div>
      <SurveyRespondentView
        submitResponses={() => history.push('/dashboard/owner/survey-view/' + params.id)}
        survey={createNewAnswerSurvey(survey)}
        customSubmitText={'Done preview'}
      />
    </div>
  )
}

interface ISentSurveyProps extends RouteComponentProps<any> {}
const SentSurveyView = (props: ISentSurveyProps) => {
  const { match } = props
  const params = match.params

  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const survey = dataCache.managedSurveys[params.id]

  if (!survey) return null
  return (
    <SentSurvey
      survey={survey}
      submit={(to, subject, message) => {
        dataCache.sendSurvey(survey.id, to, subject, message)
        appState.addAlert('Survey was sent successfully', AlertType.notification)
      }}
    />
  )
}

interface ISurveyContactsViewProps extends RouteComponentProps<any> {}
const SurveyContactsView = ({ match }: ISurveyContactsViewProps) => {
  const { params } = match

  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const survey = dataCache.managedSurveys[params.id]

  if (!survey) return null

  return (
    <SurveyContacts
      survey={survey}
      submit={(to, subject, message) => {
        dataCache.sendSurvey(survey.id, to, subject, message)
        appState.addAlert('Survey was sent successfully', AlertType.notification)
      }}
    />
  )
}

interface IAnalyzeSurveyViewProps extends RouteComponentProps<any> {}
const AnalyzeSurveyView = (props: IAnalyzeSurveyViewProps) => {
  const { match } = props
  const params = match.params

  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const survey = dataCache.managedSurveys[params.id]

  if (!survey) return null

  return (
    <AnalyzeSurvey
      survey={survey}
      submit={(to, subject, message) => {
        dataCache.sendSurvey(survey.id, to, subject, message)
        appState.addAlert('Survey was sent successfully', AlertType.notification)
      }}
    />
  )
}

interface ICSurveyommentsViewProps extends RouteComponentProps<any> {}
const SurveyCommentsView = (props: ICSurveyommentsViewProps) => {
  const { match } = props
  const params = match.params

  const dataCache = useContext(DataCacheContext)
  const appState = useContext(AppStateContext)
  const survey = dataCache.managedSurveys[params.id]

  if (!survey) return null
  return (
    <SurveyComments
      survey={survey}
      submit={(to, subject, message) => {
        dataCache.sendSurvey(survey.id, to, subject, message)
        appState.addAlert('Survey was sent successfully', AlertType.notification)
      }}
    />
  )
}

interface OwnerDashboardProps extends RouteComponentProps<any> {}
const OwnerDashboard: FC<OwnerDashboardProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/survey-view/:id`} component={CreateSurveyView} />
      <Route path={`${match.path}/survey-preview/:id`} component={SurveyPreview} />
      <Route path={`${match.path}/survey-sent-view/:id`} component={SentSurveyView} />
      <Route path={`${match.path}/contacts/:id`} component={SurveyContactsView} />
      <Route path={`${match.path}/analyze-survey/:id`} component={AnalyzeSurveyView} />
      <Route path={`${match.path}/survey-comments/:id`} component={SurveyCommentsView} />
      <Route path={`${match.path}/publish/:id`} component={PublishSurvey} />
      <Route exact path={match.path} component={CreatedSurveysList} />
      <Redirect from="*" to="/dashboard/owner" />
    </Switch>
  )
}

export default OwnerDashboard
