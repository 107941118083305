import './index.css'

import { Checkbox, Modal, Panel, TextField, Toggle } from '@fluentui/react'
import { Button } from 'components/Button'
import { DashboardHeader } from 'components/DashboardHeader'
import { OwnerEdit } from 'components/OwnerEdit'
import PreciseTimePicker from 'components/PreciseTimePicker'
import { AppStateContext, IWithAppStateContextProps, withAppStateContext } from 'contexts/appState'
import { IWithFirebaseContextProps, withFirebaseContext } from 'contexts/firebaseContext'
import React, { FormEvent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import shortId from 'shortid'
import { createEmptyQuestion, IQuestion, ISurvey, ISurveyInvitation, QuestionTypes, SurveyTypes } from 'types'
import { scrollToBottom, shallowCompare } from 'utils'
import { createAutosaver, IAutosaver } from 'utils/useAutosave'

import { IHighlightedFieldsQuestion, QuestionCreation } from './QuestionCreation'

// insert a question while making sure it stays within order, assuming questions is sorted based on topics!!!
function insertQuestionToSortedQuestions(newQuestion: IQuestion, questions: IQuestion[], topics: string[], insertToLast: boolean = true) {
  questions.push(newQuestion)
}

interface IHighlightedFields {
  [questionId: string]: IHighlightedFieldsQuestion
}

export interface ICreateSurveyProps extends RouteComponentProps<any>, IWithAppStateContextProps, IWithFirebaseContextProps {
  initialSurvey: ISurvey
  onSave: (s: ISurvey) => Promise<ISurvey>
  submit: (to: ISurveyInvitation[], subject: string, message: string) => void
}

export interface ICreateSurveyState {
  private: boolean
  isModalOpen: boolean
  isPreviewing: boolean
  isSending: boolean
  isSettingsOpen: boolean
  newTopicText: string // TODO: - use controlled component for this
  isTopicBeingCreated: boolean
  questionsCollapsed: { [key: string]: boolean } // TODO - use controlled component for this
  questionsViewingInfo: { [key: string]: boolean } // TODO - use controlled component for this
  questionBeingDragged?: IQuestion
  topicBeingDragged?: string
  activeTopic: string
  emptyQuestion: IQuestion
  forceUpdateState: boolean

  endDateInput: Date
  maxNumDaysInput: number

  highlightedFields: IHighlightedFields
}

class _CreateSurvey extends React.Component<ICreateSurveyProps, ICreateSurveyState> {
  survey: ISurvey
  autosaver: IAutosaver<void>
  questionsById: { [key: string]: IQuestion } = {}
  questionIndices: { [key: string]: number } = {}
  topicIndices: { [key: string]: number } = {}
  nonEmptyQuestions: { [key: string]: IQuestion } = {}

  constructor(props: ICreateSurveyProps) {
    super(props)

    this.survey = props.initialSurvey
    this.state = {
      private: this.survey.type === SurveyTypes.private,
      isModalOpen: false,
      isPreviewing: false,
      isSending: false,
      isSettingsOpen: false,
      newTopicText: '',
      isTopicBeingCreated: false,
      questionsCollapsed: {},
      questionsViewingInfo: {},
      questionBeingDragged: undefined,
      topicBeingDragged: undefined,
      activeTopic: 'General',
      emptyQuestion: createEmptyQuestion(),
      forceUpdateState: false,

      endDateInput: this.survey.endDate ? new Date(this.survey.endDate) : new Date(),
      maxNumDaysInput: this.survey.maxNumDays || 1,

      highlightedFields: {}
    }

    this.autosaver = createAutosaver(this.saveFunc)
    this.updateQuestionsCache()
  }

  get isDisabled() {
    return !!this.survey.dateSent
  }

  shouldComponentUpdate(nextProps: ICreateSurveyProps, nextState: ICreateSurveyState) {
    const isStateSame = shallowCompare(this.state, nextState) // should update if not the same
    const isPropsSame =
      this.props.initialSurvey.id === nextProps.initialSurvey.id &&
      this.props.onSave === nextProps.onSave &&
      this.props.appState === nextProps.appState &&
      this.props.firebaseFunctions === nextProps.firebaseFunctions

    return !isStateSame || !isPropsSame
  }

  // NOTE: this needs to be called when survey.topics or survey.questions has any changes
  updateQuestionsCache = () => {
    this.questionsById = {}
    this.questionIndices = {}
    this.topicIndices = {}
    this.nonEmptyQuestions = {}

    this.survey.questions.forEach((q, idx) => {
      this.questionsById[q.id] = q
      this.questionIndices[q.id] = idx
      if (q.value) {
        this.nonEmptyQuestions[q.id] = q
      }
    })
  }

  handlePrivateToggle = (_: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
    this.setState({ private: checked === undefined ? true : checked })

    this.survey.type = checked ? SurveyTypes.private : SurveyTypes.public
    this.save()
  }

  saveFunc = () => {
    // save the survey... but remember not to update based on initial survey!!!
    // return this.props.dataCache.saveManagedSurvey(this.survey)

    this.nonEmptyQuestions = {}
    this.survey.questions.forEach(q => {
      if (q.value) {
        this.nonEmptyQuestions[q.id] = q
      }
    })

    this.forceUpdate()
    return this.props.onSave(this.survey)
  }

  onPreview = () => {
    this.setState({
      isPreviewing: true
    })
  }

  onSend = () => {
    this.props.history.push(`/dashboard/owner/publish/${this.survey.id}`)
  }

  onSettings = () => {
    this.setState({
      isSettingsOpen: true
    })
  }

  onDone = () => {
    this.props.history.push('/dashboard/owner')
  }

  forceUpdate = () => {
    this.setState({
      forceUpdateState: !this.state.forceUpdateState
    })
  }

  onAddQuestion = (question?: IQuestion) => {
    const newQuestion = question || createEmptyQuestion()
    insertQuestionToSortedQuestions(newQuestion, this.survey.questions, this.survey.topics)
    this.updateQuestionsCache()
    this.save()
    this.forceUpdate()
    setTimeout(scrollToBottom, 1)
  }

  onDuplicateQuestion = (questionId: string) => {
    const copy = Object.assign({}, this.questionsById[questionId])
    copy.id = shortId.generate()
    this.onAddQuestion(copy)
  }

  onDeleteQuestion = (questionId: string) => {
    const questionIdx = this.questionIndices[questionId]

    this.survey.questions.splice(questionIdx, 1)
    this.updateQuestionsCache()
    this.save()
    this.forceUpdate()
  }

  onChangeName = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    this.survey.name = value || ''
    this.save()
  }

  onChangeDescription = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    this.survey.description = value || ''
    this.save()
  }

  onMoveQuestion = (dragId: string, hoverId: string) => {
    const dragQuestion = this.questionsById[dragId]
    const dragIndex = this.questionIndices[dragId]
    const hoverQuestion = this.questionsById[hoverId]
    const hoverIndex = this.questionIndices[hoverId]
    if (!dragQuestion || !hoverQuestion) return

    // dragQuestion.topic = hoverQuestion.topic
    this.survey.questions.splice(dragIndex, 1)
    this.survey.questions.splice(hoverIndex, 0, dragQuestion)

    this.updateQuestionsCache()
    this.save()
    this.forceUpdate()
  }

  // TODO: move this into the tag
  onCreateTopicChange = (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target as HTMLInputElement
    this.setState({
      newTopicText: value
    })
  }

  // maybe TODO: move to question creation
  setCollapsed = (id: string, isCollapsed: boolean) => {
    if (this.state.questionsCollapsed[id] !== isCollapsed) {
      const newQuestionsCollapsed = Object.assign({}, this.state.questionsCollapsed)
      newQuestionsCollapsed[id] = isCollapsed
      this.setState({
        questionsCollapsed: newQuestionsCollapsed
      })
    }
  }

  setIsQuestionDragged = (id: string, isDragged: boolean) => {
    if (isDragged && this.state.questionBeingDragged?.id !== id) {
      this.setState({ questionBeingDragged: this.questionsById[id] })
    }

    if (!isDragged) {
      this.setState({ questionBeingDragged: undefined })
    }
  }

  setIsTopicDragged = (topic: string, isDragged: boolean) => {
    if (isDragged && this.state.topicBeingDragged !== topic) {
      this.setState({
        topicBeingDragged: topic
      })
    }
    if (!isDragged) {
      this.setState({
        topicBeingDragged: undefined
      })
    }
  }

  collapseAll = () => {
    const questionsCollapsed: { [key: string]: boolean } = {}
    this.survey.questions.forEach(q => {
      questionsCollapsed[q.id] = true
    })
    this.setState({
      questionsCollapsed
    })
  }

  viewAll = () => {
    const questionsCollapsed: { [key: string]: boolean } = {}
    this.survey.questions.forEach(q => {
      questionsCollapsed[q.id] = false
    })
    this.setState({
      questionsCollapsed
    })
  }

  isTopicCollapsed = (topic: string) => {
    const questions = this.survey.questions
    if (!questions) throw Error('Checking non existing topic!')

    let isCollapsed = true
    for (const q of questions) {
      if (!this.state.questionsCollapsed[q.id]) {
        isCollapsed = false
        break
      }
    }
    if (questions.length === 0) isCollapsed = false
    return isCollapsed
  }

  onChangeLogo = async (image: File | null) => {
    if (!image) {
      this.survey.logoUrl = null
    } else {
      this.survey.logoUrl = await this.props.firebaseFunctions.uploadSurveyLogo(image)
    }
    this.save()
  }

  onCheckHideUserStats = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    this.survey.hideUserStats = !!checked
    this.save()
    this.forceUpdate()
  }

  onCheckEndDate = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    if (checked) {
      this.survey.endDate = this.state.endDateInput.getTime()
    } else {
      this.survey.endDate = null
    }

    this.forceUpdate()
    this.save()
  }

  onChangeEndDate = (date: Date) => {
    if (this.survey.endDate) {
      this.survey.endDate = date.getTime()
      this.setState({ endDateInput: date })
      this.save()
    }
  }

  // TODO: move to settings
  onCheckMaxNumDays = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    if (checked) {
      this.survey.maxNumDays = this.state.maxNumDaysInput
    } else {
      this.survey.maxNumDays = null
    }
    this.forceUpdate()
    this.save()
  }

  // TODO: move to settings
  onChangeMaxDays = (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>, newValue?: string | undefined) => {
    const num = Number(newValue)
    if (!isNaN(num) && this.survey.maxNumDays !== null) {
      this.survey.maxNumDays = num
      this.setState({
        maxNumDaysInput: num
      })
      this.save()
    }
  }

  dismissPreview = () => {
    this.setState({ isPreviewing: false })
  }

  dismissSending = () => {
    this.setState({
      isSending: false
    })
  }

  dismissSettings = () => {
    this.setState({
      isSettingsOpen: false
    })
  }

  onBlur = (questionId: string, answerId?: string) => {
    const question = this.questionsById[questionId]
    let highlightedFields: IHighlightedFields = {}
    if (!answerId && !question.value) {
      highlightedFields = {
        [questionId]: { isTitle: true }
      }
    }
    const hasEmptyResponses = question.choices.length === 2 && (!question.choices[0].value || !question.choices[1].value)
    if (answerId && (question.choices.length < 2 || hasEmptyResponses)) {
      const choice = question.choices.find(choice => choice.id === answerId)

      if (choice && !choice?.value) {
        if (!highlightedFields[questionId]) {
          highlightedFields[questionId] = {}
        }
        if (!highlightedFields[questionId].choices) {
          highlightedFields[questionId].choices = {}
        }
        //@ts-ignore
        highlightedFields[questionId].choices[choice.id] = true
      } else if (!choice) {
        if (!highlightedFields[questionId]) {
          highlightedFields[questionId] = {}
        }
        highlightedFields[questionId].isEmptyChoice = true
      }
    }

    this.setState({ highlightedFields })
  }

  save = () => {
    this.autosaver.autosave()
  }

  componentDidMount() {
    this.props.appState.dashboardEvents.on('preview', this.onPreview)
    this.props.appState.dashboardEvents.on('publish', this.onPublish)
    this.props.appState.dashboardEvents.on('settings', this.onSettings)
    this.props.appState.dashboardEvents.on('addQuestion', this.onAddQuestion)
    this.props.appState.dashboardEvents.on('done', this.onDone)

    if (!this.survey.questions.length) {
      this.onAddQuestion()
    }
  }

  componentWillUnmount() {
    this.props.appState.dashboardEvents.off('preview', this.onPreview)
    this.props.appState.dashboardEvents.off('publish', this.onPublish)
    this.props.appState.dashboardEvents.off('settings', this.onSettings)
    this.props.appState.dashboardEvents.off('addQuestion', this.onAddQuestion)
    this.props.appState.dashboardEvents.off('done', this.onDone)
  }

  onChangeQuestionValue = (questionId: string, value?: string) => {
    const highlighted = this.state.highlightedFields[questionId]
    if (value && highlighted && highlighted.isTitle) {
      this.setState({
        highlightedFields: {
          ...this.state.highlightedFields,
          [questionId]: { isTitle: false }
        }
      })
    }
  }

  onChangeQuestionResponse = (questionId: string, responseId: string, value?: string) => {
    const highlighted = this.state.highlightedFields[questionId]
    if (value && highlighted && highlighted.choices && highlighted.choices[responseId]) {
      this.setState({
        highlightedFields: {
          ...this.state.highlightedFields,
          [questionId]: {
            ...highlighted,
            choices: {
              ...highlighted.choices,
              [responseId]: false
            }
          }
        }
      })
    }
  }

  onPublish = () => {
    const newHighlightedFields = this.checkValidSurvey()

    if (newHighlightedFields) {
      this.setState({ highlightedFields: newHighlightedFields, isModalOpen: true })
    } else {
      this.props.history.push(`/dashboard/owner/publish/${this.survey.id}`)
    }
  }

  checkValidSurvey = (): IHighlightedFields | null => {
    const missingValueQuestions: { [questionId: string]: boolean } = {}
    const emptyResponseQuestions: { [questionId: string]: boolean } = {}
    const missingChoices: {
      [questionId: string]: {
        [responseId: string]: boolean
      }
    } = {}

    this.survey.questions.forEach(question => {
      if (!question.value) {
        missingValueQuestions[question.id] = true
      }
      if (question.type === QuestionTypes.checkboxes || question.type === QuestionTypes.multipleChoice) {
        const emptyChoices: { [choiceId: string]: boolean } = {}
        question.choices.forEach(c => {
          if (!c.value) {
            emptyChoices[c.id] = true
          }
        })

        const numEmptyChoices = Object.keys(emptyChoices).length
        const numChoices = question.choices.length

        if (question.choices.length < 2 || numChoices - numEmptyChoices < 2) {
          missingChoices[question.id] = emptyChoices
          if (question.choices.length === 1) {
            emptyResponseQuestions[question.id] = true
          }
        }
      }
    })

    const missingValueQuestionsKeys = Object.keys(missingValueQuestions)
    const missingChoicesKeys = Object.keys(missingChoices)
    const emptyResponseQuestionsKeys = Object.keys(emptyResponseQuestions)

    if (missingValueQuestionsKeys.length || missingChoicesKeys.length || emptyResponseQuestionsKeys.length) {
      const newHighlightedFields: IHighlightedFields = {}

      missingValueQuestionsKeys.forEach(questionKey => {
        newHighlightedFields[questionKey] = { isTitle: true }
      })

      emptyResponseQuestionsKeys.forEach(questionKey => {
        newHighlightedFields[questionKey] = { ...newHighlightedFields[questionKey], isEmptyChoice: true }
      })

      Object.entries(missingChoices).forEach(([questionId, missingQuestionChoices]) => {
        if (!newHighlightedFields[questionId]) {
          newHighlightedFields[questionId] = {}
        }
        newHighlightedFields[questionId] = { ...newHighlightedFields[questionId], choices: missingQuestionChoices }
      })

      return newHighlightedFields
    } else {
      return null
    }
  }

  render() {
    const numCollapsed = Object.values(this.state.questionsCollapsed).reduce((count, val) => count + (val ? 1 : 0), 0)
    const isPublishDisabled = !Object.keys(this.nonEmptyQuestions).length

    return (
      <AppStateContext.Consumer>
        {value => (
          <div className="create-survey-container">
            <DashboardHeader
              editable
              toggleable
              titleMaxLength={140}
              title={this.survey.name}
              description={this.survey.description}
              stats={[{ key: 'Questions', value: this.survey.questions.length }]}
              onChangeTitle={this.onChangeName}
              onChangeDescription={this.onChangeDescription}
              toggleLabel="Private"
              toggleValue={this.state.private}
              onToggle={this.handlePrivateToggle}>
              <Button disabled={isPublishDisabled} sm secondary onClick={this.onPublish}>
                Publish
              </Button>
              <OwnerEdit survey={this.survey} />
              <div className="create-survey-banner-collapse">
                <div
                  style={{ width: '5.5rem', cursor: 'pointer', marginRight: '6px' }}
                  onClick={numCollapsed > 0 ? this.viewAll : this.collapseAll}>
                  {numCollapsed === this.survey.questions.length ? 'Expand All' : 'Collapse All'}
                </div>
                <Toggle
                  checked={!(numCollapsed === this.survey.questions.length)}
                  onChange={numCollapsed > 0 ? this.viewAll : this.collapseAll}
                  styles={{ root: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
                />
              </div>
            </DashboardHeader>
            <div className="questions-and-topics">
              <div className="container">
                <div style={{ marginTop: 32, marginBottom: 32 }}></div>
                {this.survey.questions.map((question, index) => {
                  return (
                    <QuestionCreation
                      key={question.id}
                      index={index}
                      isDisabled={this.isDisabled}
                      question={question}
                      onMoveQuestion={this.onMoveQuestion}
                      isCollapsed={!!this.state.questionsCollapsed[question.id]}
                      displayIsDragged={this.state.questionBeingDragged === question}
                      setIsQuestionDragged={this.setIsQuestionDragged}
                      setCollapsed={this.setCollapsed}
                      saveFunc={this.save}
                      onDeleteQuestion={this.onDeleteQuestion}
                      onDuplicateQuestion={this.onDuplicateQuestion}
                      onBlur={this.onBlur}
                      highlightedFields={this.state.highlightedFields[question.id]}
                      onChangeValue={this.onChangeQuestionValue}
                      onChangeQuestionResponse={this.onChangeQuestionResponse}
                    />
                  )
                })}
                <Button lg primary text="Add Question" icon="CalculatorAddition" onClick={() => this.onAddQuestion()} />
              </div>
            </div>
            <Modal
              onDismiss={() => this.setState({ isModalOpen: false })}
              isOpen={this.state.isModalOpen}
              styles={{
                main: {
                  width: '80%',
                  borderRadius: 10
                },
                scrollableContent: {
                  height: 150,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                root: {
                  top: 0
                }
              }}>
              <div style={{ fontSize: '2em', marginBottom: 10 }}>Please fill in mandatory fields</div>
              <Button onClick={() => this.setState({ isModalOpen: false })}>Close</Button>
            </Modal>
            <Panel isOpen={this.state.isSettingsOpen} isBlocking={false} hasCloseButton onDismiss={this.dismissSettings}>
              <div className="survey-settings-container">
                <h1>Settings</h1>
                <div className="survey-settings-item">
                  <Checkbox
                    label={'Hide results from recipients'}
                    checked={this.survey.hideUserStats}
                    onChange={this.onCheckHideUserStats}
                  />
                </div>

                <hr className="survey-settings-item" />
                <h3 className="survey-settings-item">Stop survey based on below conditions</h3>
                <div className="survey-settings-item">
                  <Checkbox label="End Date" checked={!!this.survey.endDate} onChange={this.onCheckEndDate} />
                  <PreciseTimePicker value={this.state.endDateInput} isDisabled={!this.survey.endDate} onChange={this.onChangeEndDate} />
                </div>

                <div className="survey-settings-item">
                  <Checkbox label={'Number of Days'} checked={this.survey.maxNumDays !== null} onChange={this.onCheckMaxNumDays} />
                  <TextField
                    placeholder="Enter number of days"
                    disabled={this.survey.maxNumDays === null}
                    value={String(this.state.maxNumDaysInput)}
                    onChange={this.onChangeMaxDays}
                  />
                </div>
              </div>
            </Panel>
          </div>
        )}
      </AppStateContext.Consumer>
    )
  }
}

const CreateSurvey = withAppStateContext(withFirebaseContext(withRouter(_CreateSurvey)))
export { CreateSurvey }
export default CreateSurvey
