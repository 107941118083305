import firebase from 'firebase'
import LogRocket from 'logrocket'
import React, { useContext, useEffect, useState } from 'react'

import { Subtract } from '../utils'

export enum OnlineStatus {
  Loading = 1,
  SignedIn,
  SignedOut
}

export interface IAuth {
  userInfo?: firebase.User
  online?: OnlineStatus
  setOnline: (online: OnlineStatus) => void
}

const defaultState: IAuth = { setOnline: () => {} }

export const AuthContext = React.createContext(defaultState)

export interface IAuthProviderProps {}

export const signOut = async () => {
  await firebase.auth().signOut()
}

export const signInWithEmail = async (email: string, password: string) => {
  return await new Promise(async (resolve, reject) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async result => {
        // if (result.user) {
        // if (!result.user.emailVerified) {
        //   await signOut()
        //   reject({ code: 'auth/user-not-verified' })
        // }
        // } else resolve(result)
        resolve(result.user)
      })
      .catch(error => {
        reject(error)
      })
  })
}
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${window.location.origin}/#/login`,
  // This must be true.
  handleCodeInApp: true
}

export const userPasswordReset = async (email: string) => {
  return await new Promise(async (resolve, reject) => {
    await firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const createUserWithEmail = (email: string, password: string, name: string) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async newUser => {
        if (newUser.user) {
          // if (!newUser.user.emailVerified) {
          //   newUser.user.sendEmailVerification(actionCodeSettings)
          // }
          await newUser.user.updateProfile({
            displayName: name
          })

          LogRocket.identify(newUser.user.uid, {
            name: `${name}-${window.location.origin}`,
            email
          })

          // await signOut()
          resolve(newUser)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const googleSignIn = () => {
  let provider = new firebase.auth.GoogleAuthProvider()

  firebase
    .auth()
    .signInWithPopup(provider)
    .catch(function (error) {
      console.log(error)
      // Handle Errors here.
      // var errorCode = error.code
      // var errorMessage = error.message
      // // The email of the user's account used.
      // var email = error.email
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential
      // ...
    })
}

export const facebookSignIn = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    let provider = new firebase.auth.FacebookAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => resolve())
      .catch(function (error) {
        reject(error.message)
        // Handle Errors here.
        // var errorCode = error.code
        // var errorMessage = error.message
        // // The email of the user's account used.
        // var email = error.email
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential
        // ...
      })
  })
}

export const microsoftSignIn = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    let provider = new firebase.auth.OAuthProvider('microsoft.com')

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => resolve())
      .catch(function (error) {
        console.log(error)
        reject(error.message)
        // Handle Errors here.
        // var errorCode = error.code
        // var errorMessage = error.message
        // // The email of the user's account used.
        // var email = error.email
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential
        // ...
      })
  })
}

export const AuthIsSignedIn: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)
  return <>{online === OnlineStatus.SignedIn ? props.children : null}</>
}

export const AuthIsSignedOut: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)
  return <>{online === OnlineStatus.SignedOut ? props.children : null}</>
}

export const AuthIsLoading: React.FunctionComponent = props => {
  const { online }: IAuth = useContext(AuthContext)

  return <div>{online === OnlineStatus.Loading ? props.children : null}</div>
}

const AuthProvider: React.FunctionComponent<IAuthProviderProps> = props => {
  const [online, setOnline] = useState(OnlineStatus.Loading)
  const [userInfo, setUserInfo] = useState<firebase.User>()

  useEffect(() => {
    setOnline(OnlineStatus.Loading)
    firebase.auth().onAuthStateChanged(function (user) {
      if (
        user /*user.emailVerified ||*/ &&
        (user.email ||
          user.providerData.find(data => data?.providerId === 'facebook.com') ||
          user.providerData.find(data => data?.providerId === 'microsoft.com'))
      ) {
        setOnline(OnlineStatus.SignedIn)
        setUserInfo(user)
        if (user.uid && user.email) {
          LogRocket.identify(user.uid, {
            name: `${user.displayName}-${window.location.origin}`,
            email: user.email
          })
        } else {
          LogRocket.identify('Unknown', {
            name: `${window.location.origin}`
          })
          // console.log(`could not find user info ${JSON.stringify(user)}`)
        }
      } else {
        setOnline(OnlineStatus.SignedOut)
        setUserInfo(undefined)
      }
      console.log('logging in!')
    })
  }, [])

  const state: IAuth = {
    userInfo,
    online,
    setOnline
  }

  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
}

export interface IWithAuthContextProps {
  auth: IAuth
}

export function withAuthContext<P extends IWithAuthContextProps>(Child: React.ComponentType<P>) {
  return function WithAuth(props: Subtract<P, IWithAuthContextProps>) {
    return <AuthContext.Consumer>{auth => <Child {...(props as P)} auth={auth} />}</AuthContext.Consumer>
  }
}

export default AuthProvider
