import { ContentState } from 'draft-js'
import React, { FC } from 'react'

interface ILinkProps {
  contentState: ContentState
  entityKey: string
}

export const Link: FC<ILinkProps> = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <a data-testid="rich-text-editor-link" href={url} className="rich-text-editor-link" target="_blank noopen noreferrer">
      {children}
    </a>
  )
}
