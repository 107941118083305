import { ContentBlock, DraftEditorCommand, DraftHandleValue, Editor as DraftEditor, EditorState } from 'draft-js'
import React, { FC } from 'react'

interface IEditorProps {
  id: string
  editorState: EditorState
  placeholder?: string
  handleBlockStyle: (block: ContentBlock) => string
  handleChange: (editorState: EditorState) => void
  handleKeyCommand: (command: DraftEditorCommand) => DraftHandleValue
  handleBeforeInput: (chars: string, editorState: EditorState, eventTimeStamp: number) => DraftHandleValue
  handlePastedText: (text: string, html: string | undefined, editorState: EditorState) => DraftHandleValue
  readOnly?: boolean
}

export const Editor: FC<IEditorProps> = ({
  children,
  id,
  editorState,
  placeholder,
  handleBlockStyle,
  handleChange,
  handleKeyCommand,
  handleBeforeInput,
  handlePastedText,
  readOnly
}) => {
  return (
    <div id={id} className="rich-text-editor">
      <DraftEditor
        spellCheck
        editorState={editorState}
        placeholder={placeholder}
        blockStyleFn={handleBlockStyle}
        onChange={handleChange}
        handleKeyCommand={handleKeyCommand}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        readOnly={readOnly}
      />
      {children}
    </div>
  )
}
