import '../containers/css/RespondentSurveys.css'

import { SearchInput } from 'components/SearchInput'
import { RespondentThumb } from 'containers/Thumb/RespondentThumb'
import { FirebaseContext } from 'contexts/firebaseContext'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../contexts/authContext'
import { DataCacheContext } from '../contexts/dataCacheContext'
import { IProfile, isSurveyExpired, ISurveySubmission } from '../types'

export const RespondentSurveys = () => {
  const { userInfo } = useContext(AuthContext)
  const { invitedSurveys } = useContext(DataCacheContext)
  const { getProfile, getAnswer } = useContext(FirebaseContext)
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const [filterByTopicIds, setFilterByTopicIds] = useState<string[]>([])

  const handleSearchChange = (_: React.ChangeEvent<HTMLInputElement | undefined> | undefined, value?: string) => {
    setSearchValue(value || '')
  }

  const openSurvey = (surveyId: string) => {
    if (userInfo) {
      if (isSurveyExpired(invitedSurveys[surveyId].survey)) return
      const answerId = invitedSurveys[surveyId].answerId
      history.push(`/survey/${surveyId}/${answerId}`)
    }
  }

  const handleAnalyzeClick = (surveyId: string) => {
    const answerId = invitedSurveys[surveyId].answerId
    history.push(`/analyze-survey/${surveyId}/${answerId}`)
  }

  const getProfileFromSubmissions = async () => {
    const surveys = Object.values(invitedSurveys)
    if (surveys.length) {
      let answer: ISurveySubmission | null
      let profile: IProfile

      for (let i = 0; i < surveys.length; i++) {
        const survey = surveys[i]
        answer = await getAnswer(survey.survey.id, survey.answerId)
        if (answer?.profileId) {
          profile = await getProfile(answer.profileId)
          if (profile && profile.topics) {
            setFilterByTopicIds(profile.topics.map(topic => topic.id))
            break
          }
        }
      }
    }
  }

  useEffect(() => {
    if (!filterByTopicIds.length) {
      getProfileFromSubmissions()
    }
  }, [invitedSurveys, userInfo])

  const invitedSurveysValues = Object.values(invitedSurveys)
  const filteredSurveys = invitedSurveysValues.filter(invitedSurvey =>
    invitedSurvey.survey.name.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <div className="container user-container">
      <div className="respondent-surveys-container">
        <header className="respondent-surveys-header">
          <SearchInput value={searchValue} title="Your Surveys" mt={31} mb={46} onChange={handleSearchChange} />
        </header>
        {invitedSurveysValues.length > 0 ? (
          <div className="respondent-surveys-body" style={filteredSurveys.length ? {} : { justifyContent: 'flex-start' }}>
            {filteredSurveys.length ? (
              filteredSurveys.map(inv => {
                return (
                  <RespondentThumb
                    key={inv.survey.id}
                    itemKey={inv.survey.id}
                    survey={inv.survey}
                    answerId={inv.answerId}
                    onSurveyClick={() => openSurvey(inv.survey.id)}
                    onAnalyzeClick={() => handleAnalyzeClick(inv.survey.id)}
                    filterByTopicIds={filterByTopicIds}
                  />
                )
              })
            ) : (
              <div className="no-results-found">No Results Found</div>
            )}
          </div>
        ) : (
          <p className="respondent-surveys-empty">
            Thank you for your interest and your willingness to participate. That's all we have for you today. Check back later for more
            survey material.
          </p>
        )}
      </div>
    </div>
  )
}
